import React, { useContext, useEffect, useState } from "react";
import "./bookdiscount.css";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import book from "../../../../Asserts/Images/Book copy 2.png";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import axios from "axios";
import { useSessionErrorHandler } from "../../Managesession";

function BookDiscount() {
  const [code, setCode] = useState("");
  const [showCode,setShowCode] = useState(false)
  const {id} = useParams();
  const {token,user} = useContext(UserContext)
  const [book, setBook] = useState({});
  const [loading, setLoading] = useState(false);
  const [discountRate, setDiscountRate] = useState(0);
  const [discoutPrice, setDiscoutPrice] = useState(0);
  const navigate = useNavigate()
  const [fadeOut, setFadeOut] = useState(false);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
  });
  const handleSessionError = useSessionErrorHandler();
  const getBookById = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/book/view/${id}?userID=${user?._id}`,
        { withCredentials: true }
      )
      .then((res) => {
        console.log("onebok", res.data);
        setBook(res.data.book);

        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

const submitcode = async () => {
  await axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/discounts/getDiscountByDiscountCode/${code}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      console.log("discount", res.data);
      setDiscountRate(res.data.data.percentage);
      setShowCode(true)
      setDiscoutPrice(Math.floor(book.bookPrice - (book.bookPrice * res.data.data.percentage) / 100));
      })
    .catch((err) => {
      console.log(err);
      setCode("");
      setShowCode(false)
      if (err.response.status === 404) {
        alert("Invalid Discount Code");
      }
      if(err.response.status === 400){
        alert("Discount code has expired or not active");
      }
      if (err.response.status === 401) {
        alert("You are not authorized to use this discount code");
      }


    });
};


  useEffect(()=>{
    getBookById()
  },[])
  const gotopayment = async () => {
    // console.log("book", user);

    if (user) {
      let newFormData = new FormData();
      newFormData.append("title", book.bookTitle);
      newFormData.append(
        "service_details",
        "For Buying a book" + book.bookTitle
      );
      newFormData.append("order_description", book.book_description);
      newFormData.append("payer_email", user.email);
      newFormData.append("payer_id", user._id);
      newFormData.append("payer_first_name", user.first_name);
      newFormData.append("payer_last_name", user.last_name);
      newFormData.append("payer_phone", user.phone_number);
      newFormData.append("bookId", id);
      newFormData.append('code',code)

      try {
        setLoading(true);

        const response = await axiosInstance.post(
          `${process.env.REACT_APP_SERVER_URL}/edfapay/getbookpaymentdetails`,
          newFormData,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("edfa", response);
        if(response.data.status === 500){
          alert("An error occurred while initiating the payment. Please try again later.");
          setFadeOut(false);
          return;
        }
        if (response.status == 200) {
          // console.log("insdie if");
          //   setSuccess("address success");
          newFormData = null;
          // Trigger fade-out effect

          setFadeOut(true);
          setTimeout(() => {
            setLoading(false);

            window.location.href = response.data.redirect_url;
          }, 500); // Adjust delay to match CSS transition
        }
      } catch (error) {
        if(error.response.status===400){
          alert(error.response.data.message);
          setFadeOut(false);
          setLoading(false);
          return
        }
        if(error.response.status === 500){
          alert(error.response.data.message);
          setFadeOut(false);
          setLoading(false);
          return
        }
        handleSessionError(error);
        // console.log(error);
        newFormData = null;
        setLoading(false);

        // navigate("/error");
      }
    } else {
      navigate("/login");
    }
  };
  function Submit() {

  }

  return (
    <div>
      {/* Navbar  */}
      <div>
        <Navbar />
      </div>

      {/* Body  */}
      <div className={`book-discount-container ${fadeOut ? "fade-out" : "fade-in"}`}>
  <div className="book-discount-inner">
    <div className="book-discount-header">
      <div className="book-discount-header-icon">
        <Icon icon="weui:back-filled" />
      </div>
      <div className="book-discount-header-text">إتمام عملية الشراء</div>
    </div>
    <div className="book-discount-lower">
      <div className="book-discount-lower-book">
        <div className="book-discount-lower-book-details">
          <div className="book-discount-lower-book-details-img">
            <img src={book.bookCoverPhoto} alt="Book" />
          </div>
          <div className="book-discount-lower-book-details-text">
            <div className="book-discount-lower-book-details-text-title">
              {book?.bookTitle}
            </div>
            <div className="book-discount-lower-book-details-text-author">
              <div className="book-discount-lower-book-details-text-author-name">
                {book?.author}
              </div>
              <div>:من تأليف</div>
            </div>
          </div>
        </div>
        <div className="book-discount-lower-book-line"></div>
        <div className="book-discount-lower-book-price">
          <div>{book?.bookPrice}</div>
          <div>:المبلغ كامل</div>
        </div>
      </div>
      <div className="book-discount-lower-code">
        <div className="book-discount-lower-input-container">
          <div className="book-discount-lower-input">
            <input
              type="text"
              value={code}
              placeholder="اكتب كود الخصم"
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div className="book-discount-lower-button">
            <button onClick={() => submitcode()}>تفعيل كود الخصم</button>
          </div>
        </div>
        {showCode && (
          <div className="book-discount-lower-discount">
            <div className="book-discount-lower-discount-text">
              <div>{discountRate}</div>
              <div>:خصم بمناسبة اليوم الوطني</div>
            </div>
            <div className="book-discount-lower-discount-text">
              <div>{discoutPrice}</div>
              <div>:المبلغ بعد الخصم</div>
            </div>
          </div>
        )}
      </div>
      <div className="book-discount-lower-next">
        <button onClick={gotopayment}>التالي</button>
      </div>
    </div>
  </div>
</div>


      {/* Footer  */}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default BookDiscount;
