import React, { useContext, useEffect, useState } from "react";
import "./Bloodimageanlysis.css";
import { Icon } from "@iconify/react";
import io from "socket.io-client";
import { UserContext } from "../../../context/UserContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { socket } from "./globalsocket";
// const socket = io(process.env.REACT_APP_SERVER_SOCKET);
import { useSessionErrorHandler } from "../Managesession";
import { getCurrentDate, getCurrentTime, getCurrentTimeISO } from "../../../HelperFunctions";
function Chatbox({
  patientRequest,
  status,
  requested_By,
  setStatus,
  setPatientRequest,
}) {
  const handleSessionError = useSessionErrorHandler();
  const { user, token } = useContext(UserContext);
  const { requestId } = useParams();
  const { _id, first_name } = user;
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false); // State to check if the message is from admin

  // console.log("patientRequestinchat", patientRequest);
  useEffect(() => {
    socket.emit("joinRoom", {
      room: requestId,
    });
    socket.on("receiveMessage", (data) => {
      // console.log("inside receive message");
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    fetchMessages();

    return () => {
      socket.emit("leaveRoom", requestId);
      socket.off();
    };
  }, [requestId, socket]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/msg/getall/${requestId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("this is message", response);
      setMessages(response.data.chats.chats);
    } catch (error) {
      handleSendMessage(error);
      // console.error("Error fetching messages:", error);
    }
  };
  const closeTheLabwork  = async()=>{
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/patientrequests/updatestatus/${requestId}?adminId=${user._id}`,
        { request_status: "مغلقة" },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        setStatus(res.data.response.request_status);
        setPatientRequest(res.data.response);
        alert("تم اغلاق الطلب");
        return;
      })
      .catch((err) => {
        handleSessionError(err);
        // console.log(err);
        return;
      });
  }
  const updateTheStatus = async () => {
    // console.log("status", status);
    const socketData = {
      userID: patientRequest?.requested_by?._id,
      Message: `قام الدكتور مازن بالرد على طلبك`,
      Link: `/patientreqdetails/${requestId}`,
      Deliver_To: "user",
      time: getCurrentTime(),
      date: getCurrentDate(),
      created_at:getCurrentTimeISO()
    };


    if (status !== "قيد المعالجة") {
      await axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/patientrequests/updatestatus/${requestId}?adminId=${user._id}`,
          { request_status: "قيد المعالجة" },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res);

          setStatus(res.data.response.request_status);
          socket.emit("notification", socketData);
          return;
        })
        .catch((err) => {
          handleSessionError(err);
          // console.log(err);
          return;
        });
    } else {
      return;
    }
  };
  const saveMessageToDB = async (message, sender_id) => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/msg/savemessage/${requestId}`,
        { message: message, sender_id: sender_id },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        updateTheStatus();
      })
      .catch((err) => {
        handleSessionError(err);
        // console.log(err);
      });
  };
  const handleSendMessage = async () => {
    try {
      if (newMessage.trim() === "") return;
      const data = {
        message: newMessage,
        roomId: requestId,
        sender_id: _id,
        first_name: first_name,
        timestamp: Date.now(),
      };
      socket.emit("sendMessage", data);
      // socket.emit('notification',data)
      saveMessageToDB(newMessage, _id);
      setMessages((prevMessages) => [...prevMessages, data]);
      setNewMessage("");
      return;
    } catch (err) {
      // console.log(err);
      return;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <>
      <div className="chatboxmaindiv">
        <div className="messagecontainer">
          {messages &&
            messages.length > 0 &&
            messages.map((message, index) => (
              <>
                <div
                  key={index}
                  className={`userchatmain ${
                    message.sender_id === _id ? "user" : "admin"
                  }`}
                >
                  <div className="usernamediv">
                    :{message.first_name || message.sender.first_name}
                  </div>
                  <div className="usermessagediv">{message.message}</div>
                  <div></div>
                </div>
                <div className="date-time-chat">
                  <div>
                    {new Date(message.timestamp).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </div>
                  <div>
                    {new Date(message.timestamp).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "short",
                    })}
                  </div>
                </div>
                <div className="message-underline"></div>
              </>
            ))}
        </div>

        <div className="bottominputandbut">
        {(user?.role==="user"&&status==='مغلقة')?"":
        <>
          <input
            type="text"
            placeholder="اكتب رسالتك"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />

          <button onClick={handleSendMessage}>ارسال</button>
          </>
}
          {(user?.role === 'admin' || user?.role === 'super-admin') && <button onClick={closeTheLabwork}>طلب وثيق</button>}
        </div>

      </div>

    </>
  );
}

export default Chatbox;
