import React, { useState } from 'react'
import Postponed from './MeetingsComponents/Postponed'
import OnComing from './MeetingsComponents/OnComing'

function ManagerInterviews({activityMeetings}) {
    const [subactivity,setSubactivity] = useState('postpone')
  return (
    <div>
      <div className='managerdetails-body-buttons-container1'>
            <div className={subactivity === 'postpone' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('postpone')}>المقابلات المؤجلة</button></div>
            {/* <div className={subactivity === 'oncoming' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('oncoming')}>التعليقات المحذوفة</button></div> */}
            </div>
            <div>
                {
                    subactivity === 'postpone' && <Postponed activityMeetings={activityMeetings} />
                }
                {
                    // subactivity === 'oncoming' && <OnComing/>
                }
            </div>
    </div>
  )
}

export default ManagerInterviews
