import React, { useState } from 'react'
import BooksEdited from './BooksComponents/BooksEdited'
import BooksUploaded from './BooksComponents/BooksUploaded'
import BooksDeleted from './BooksComponents/BooksDeleted'
import BookCommentsDeleted from './BooksComponents/BookCommentsDeleted'
 
function ManagerBooks({activityBooks}) {
  const [subactivity,setSubactivity] = useState('added')
  return (
    <div>
      <div>
      <div className='managerdetails-body-buttons-container1'>
<div className={subactivity === 'added' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('added')}>الفيديوهات المضافة</button></div>
            <div className={subactivity === 'edited' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('edited')}>الفيديوهات المعدلة</button></div>
            <div className={subactivity === 'deleted' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('deleted')}>الفيديوهات المحذوفة</button></div>
            <div className={subactivity === 'deleted-comments' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('deleted-comments')}>التعليقات المحذوفة</button></div>
            </div>
      </div>
      <div>
        {
          subactivity === 'edited' && <BooksEdited activityBooks={activityBooks} />
        }
        {
          subactivity === 'added' && <BooksUploaded activityBooks={activityBooks} />
        }
        {
          subactivity === 'deleted' && <BooksDeleted activityBooks={activityBooks} />
        }
        {
          subactivity === 'deleted-comments' && <BookCommentsDeleted activityBooks={activityBooks} />
        }
      </div>
    </div>
  )
}

export default ManagerBooks
