import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import dummy from '../../.../../../../../../../Asserts/Images/Video.png'
import { Icon } from '@iconify/react/dist/iconify.js';
import bookCoverPhoto from '../../../../../../../Asserts/Images/book.svg'


function BooksEdited({activityBooks}) {
    const navigate = useNavigate()
    const [videoLoading, setVideoLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [activePage,setActivePage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);

    const paginationIncrement = () => {
        if (pagination < totalPages) {
          if((pagination)%2===0){
            setActivePage(activePage+2)
          }
          setPagination((prevPagination) => prevPagination + 1);

        }
      };

      const paginationDecrement = () => {
        if (pagination > 1) {
          if((pagination-1)%2===0){
            setActivePage(activePage-2)
          }
          setPagination((prevPagination) => prevPagination - 1);
        }
      };
  return (
    <div className='whole-added'>
        <div className='added-video-elements-container'>

            {/* map here */}

  {activityBooks.updatedBooks.length > 0 ? (
    activityBooks.updatedBooks.map((updatedBook, index) => (
      <div className='added-video-elements' key={index}>
        <div className='added-details'>
          <div className='a-d-details'>
            <div style={{ direction: 'rtl' }}>
              {/* {'التعليقات'} - {'الفيديو'} - {'الوصف'} :تم تعديل */}
            </div>
            <div>{updatedBook.updatingDate || 'Unknown Date'} :تاريخ التعديل</div>
          </div>
          <div className='a-d-video'>
            <div className="mainbookdiv1">
              <img src={updatedBook.bookCoverPhoto || dummy} alt="" className='book-img' />
              <div className="paddingleftbotm">
                <h2>{updatedBook.bookTitle || 'Unknown Title'}</h2>
                <p>من تأليف <span>{updatedBook.author || 'Unknown Author'}</span></p>
                <p>{updatedBook.book_description?.substring(0, 150) || 'No Description'}</p>
                <h5>{updatedBook.bookPrice || 'Unknown Price'} SR</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div
    style={{padding:'70px',fontSize:'20px',backgroundColor:'white',borderRadius:'14px'}}
    >لم يتم تحديث أي كتب حتى الآن</div>
  )}
      </div>
      <div className="pagination-container">
                  {
                     totalPages===1 || <div
                    className={
                      pagination === 1
                        ? "pagination-disable"
                        : "pagination-enable"
                    }
                    onClick={paginationDecrement}
                  >
                    {"<"}
                  </div> }
                  <div>
                    <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"} onClick={paginationDecrement}>{activePage}</span>
                  </div>
                  {
                    totalPages===1 || <div>
                    <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"} onClick={paginationIncrement}>{activePage + 1}</span>
                  </div>}
                  {
                    totalPages===1 || <div
                    className={
                      pagination === totalPages
                        ? "pagination-disable"

                        : "pagination-enable"
                    }
                    onClick={paginationIncrement}
                  >
                    {">"}
                  </div>}
                </div>
    </div>
  )
}

export default BooksEdited
