import React, { useContext, useState } from "react";
import Navbar from "../../../Navbar/Navbar";
import Footer from "../../../Footer/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import "./discountcode.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../../../context/UserContext";
import moment from 'moment';

function DiscountCode() {
  const [occasion, setOccasion] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const {token} = useContext(UserContext)

  const navigate = useNavigate();

  async function GenerateCode () {
    const formattedStartDate = moment(startDate).format('DD/MM/YYYY');
const formattedEndDate = moment(endDate).format('DD/MM/YYYY');
    // success
  await axios.post(`${process.env.REACT_APP_SERVER_URL}/discounts/createDiscount`,
{
    reason: occasion,
    code: discountCode,
    percentage: discountRate,
    startDate: formattedStartDate,
    endDate:formattedEndDate
  },{
    headers: {
      'Authorization': `Bearer ${token}`
    }

  })
  .then((res) => {
    console.log(res.data);
    alert("تم انشاء الكود بنجاح");
    navigate(`/codevisit/${res.data.data._id}`);
  })
  .catch((err) => {
    console.log(err);
  });

  }

  function Back() {
    navigate("/");
  }

  return (
    <div>
      {/* Navbar */}
      <div>
        <Navbar />
      </div>

      {/* Body */}

      <div className="discount-body">
        <div className="discount-body-inner">
          <div className="discount-header">
            {/* Back button  */}
            <div className="discount-header-icon" onClick={Back}>
              <Icon icon="weui:back-filled" />
            </div>
            <div className="discount-header-title">انشاء أكواد الخصم</div>
          </div>
          <div className="discount-form-div">
            <div className="discount-section">
              {/* Heading  */}

              <div className="discount-heading">
                <h1>الخصم على جميع الخدمات</h1>
              </div>
              <div className="discount-container">
                {/* Occassion input  */}
                <div className="discount-input">
                  <label htmlFor="discount-name">المناسبة</label>
                  <input
                    type="text"
                    id="discount-name"
                    value={occasion}
                    onChange={(e) => setOccasion(e.target.value)}
                  />
                </div>
                {/* Discount Code  */}
                <div className="discount-input">
                  <label htmlFor="discount-amount">كود الخصم</label>
                  <input
                    type="text"
                    id="discount-amount"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                </div>

                {/* Discount Rate  */}
                <div className="discount-input">
                  <label htmlFor="discount-expiration">نسبة الخصم</label>
                  <input
                    type="text"
                    id="discount-expiration"
                    value={discountRate}
                    onChange={(e) => setDiscountRate(e.target.value)}
                  />
                </div>

                {/* Expiration Date  */}
                <div className="discount-input">
                      <label htmlFor="discount-expiration">تاريخ صلاحية الكود</label>
                      <div className='discount-expiration-date-container'>
                        <div>
                            <div>
                                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                            <div>من</div>
                        </div>
                        <div>
                            <div>
                                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div>الى</div>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
            {/* Submit Button  */}
            <div className="discount-button">
              <button onClick={GenerateCode}>انشاء كود الخصم</button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer  */}
      <div>
        <Footer />
      </div>
    </div>
  );
}
/******  50396263-b90b-4ac0-98af-4fe539a19ccb  *******/

export default DiscountCode;
