import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import dummy from '../../.../../../../../../../Asserts/Images/Video.png'
import dummyProfile from '../../.../../../../../../../Asserts/Images/DummyProfile.png'
import { Icon } from '@iconify/react/dist/iconify.js'




function DeletedComments({activityVideos}) {
    const navigate = useNavigate()
    const [videoLoading, setVideoLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [activePage,setActivePage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const comments = activityVideos.videosDeletedComments
    .flatMap((video) => video.comments.filter((comment) => comment.disabled === true));

    const paginationIncrement = () => {
        if (pagination < totalPages) {
          if((pagination)%2===0){
            setActivePage(activePage+2)
          }
          setPagination((prevPagination) => prevPagination + 1);

        }
      };
      

      // console.log("comments",comments)
      const paginationDecrement = () => {
        if (pagination > 1) {
          if((pagination-1)%2===0){
            setActivePage(activePage-2)
          }
          setPagination((prevPagination) => prevPagination - 1);
        }
      };

  return (
    <div>
      <div className='whole-added'>
        <div className='added-video-elements-container'>

            {/* map here */}

            {comments.length > 0 ? (
                comments.map((comment, index) => (
                    <div className='added-video-elements' key={index}>
                        <div className='added-details'>
                            <div className='a-d-details1'>
                                <div style={{ direction: 'rtl' }}>
                                    {comment.disableReason || 'Unknown Reason'} :سبب الحذف
                                </div>
                                <div>{comment.disableDate || 'Unknown Date'} :تاريخ التعديل</div>
                            </div>
                            <div className='d-c-deleted-comment'>
                                <div className='d-c-d-c-profile'>
                                    <div className='d-c-d-c-p-name'>{comment.commentedBy.first_name || 'Unknown Name'}</div>
                                    <div className='d-c-d-c-p-img'>
                                        <img src={comment.commentedBy.photo} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className='d-c-d-c-p-comment'>
                                        {comment.comment || 'No Comment'}
                                    </div>
                                </div>
                                <div className='d-c-d-c-p-likes-container'>
                                    <div>{comment.likes.length || '0'}</div>
                                    <div><Icon icon="solar:like-bold-duotone" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div>NO COMMENTS DELETED</div>
            )}
      </div>
      <div className="pagination-container">
                  {
                     totalPages===1 || <div
                    className={
                      pagination === 1
                        ? "pagination-disable"
                        : "pagination-enable"
                    }
                    onClick={paginationDecrement}
                  >
                    {"<"}
                  </div> }
                  <div>
                    <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"} onClick={paginationDecrement}>{activePage}</span>
                  </div>
                  {
                    totalPages===1 || <div>
                    <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"} onClick={paginationIncrement}>{activePage + 1}</span>
                  </div>}
                  {
                    totalPages===1 || <div
                    className={
                      pagination === totalPages
                        ? "pagination-disable"

                        : "pagination-enable"
                    }
                    onClick={paginationIncrement}
                  >
                    {">"}
                  </div>}
                </div>
    </div>
    </div>
  )
}

export default DeletedComments
