import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import './managerspecialization.css';
import Navbar from '../../../../User/Navbar/Navbar';
import Footer from '../../../../User/Footer/Footer';
function ManagerSpecialization() {
  const { id } = useParams(); // Manager ID from URL
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const [managerDetails, setManagerDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [successPopup,setSuccessPopup] = useState(false)
  const [failPopup,setFailPopup] = useState(false)

  // Fetch manager details
  const getManagerDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/user/userdetails/${id}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      setManagerDetails(response.data.data);
      setLoading(false);
    } catch (err) {
      // console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getManagerDetails();
  }, [id]);

  // Handle checkbox toggle
  const handleCheckboxChange = (field) => {
    setManagerDetails((prevDetails) => ({
      ...prevDetails,
      [field]: !prevDetails[field],
    }));
  };

  // Handle form submission to update manager details
  const updateManagerPermissions = async () => {
    try {
      setLoading(true);
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/admin/updateAdminActions/${id}`,
        {
          ableToUploadBannerAndVideos: managerDetails.ableToUploadBannerAndVideos,
          ableToAttendLabworks: managerDetails.ableToAttendLabworks,
          ableToAttendMeetings: managerDetails.ableToAttendMeetings,
        },
        { headers: { authorization: `Bearer ${token}` } }
      );
      setLoading(false);
      setSuccessPopup(true)
      // alert('Permissions updated successfully!');
      // navigate(`/manageractivity/${id}`);
    } catch (err) {
      // console.error(err);
      setLoading(false);
      setFailPopup(true)
      // alert('Error updating permissions.');
    }
  };

  function SuccessFunc () {
    setSuccessPopup(false)
    navigate(`/manageractivity/${id}`)
  }


  return (
    <div>
      <Navbar />
      {
        failPopup &&
        <><div className="m-d-c-p-container">
        <div
          className="m-d-bg-black-shadow"
          onClick={() => setFailPopup(false)}
        ></div>
        <div className="m-d-cancel-popup">
          <div className="m-d-c-p-ttext">
          لم تتم التغييرات بنجاح
          </div>
          <div className="m-d-c-p-btn">
            <button onClick={() => setFailPopup(false)}>نعم</button>
          </div>
        </div>
      </div></>
      }
      {
        successPopup &&
        <><div className="m-d-c-p-container">
        <div
          className="m-d-bg-black-shadow"
          onClick={SuccessFunc}
        ></div>
        <div className="m-d-cancel-popup">
          <div className="m-d-c-p-ttext">
          تمت التغييرات بنجاح
          </div>
          <div className="m-d-c-p-btn1">
            <button onClick={SuccessFunc}>نعم</button>
          </div>
        </div>
      </div></>
      }
      <div className="manager-specialization-body">
        <div className="m-s-headway">
          <div onClick={() => navigate(`/manageractivity/${id}`)}>
            <Icon icon="weui:back-filled" />
          </div>
          <div className="m-s-h-way">
            <div className="m-s-h-divs1">الاختصاص</div>
            <div>{'<'}</div>
            <div className="m-s-h-divs" onClick={() => navigate(`/manageractivity/${id}`)}>
              {managerDetails.first_name}
            </div>
            <div>{'<'}</div>
            <div className="m-s-h-divs" onClick={() => navigate('/allmanagers')}>
              المديرون
            </div>
          </div>
        </div>

        <div className="m-s-body">
          <div className="m-s-bdy">
            <div className="m-s-b-head">الاختصاص</div>
            <div className="m-s-b-body">
              <div className="m-s-b-b-row">
                <div>الفيديوهات والاعلانات الدعائية</div>
                <div>
                  <input
                    type="checkbox"
                    checked={managerDetails.ableToUploadBannerAndVideos || false}
                    onChange={() => handleCheckboxChange('ableToUploadBannerAndVideos')}
                  />
                </div>
              </div>
              <div className="m-s-b-b-row">
                <div>استلام التحاليل والرد عليها</div>
                <div>
                  <input
                    type="checkbox"
                    checked={managerDetails.ableToAttendLabworks || false}
                    onChange={() => handleCheckboxChange('ableToAttendLabworks')}
                  />
                </div>
              </div>
              <div className="m-s-b-b-row">
                <div>تنظيم المقابلات مع الدكتور</div>
                <div>
                  <input
                    type="checkbox"
                    checked={managerDetails.ableToAttendMeetings || false}
                    onChange={() => handleCheckboxChange('ableToAttendMeetings')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="m-s-endbtn">
            <button onClick={updateManagerPermissions} disabled={loading}>
              {loading ? 'جارٍ الحفظ...' : 'تم'}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ManagerSpecialization;
