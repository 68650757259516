import io from "socket.io-client";
 const user = localStorage.getItem("user");
export let socket = io(process.env.REACT_APP_SERVER_SOCKET, {
    query: {
      userId: user ? JSON.parse(user)._id : null,
      userName: user ? JSON.parse(user).first_name : null,
      role: user ? JSON.parse(user).role : null,
    },
  });

export const initializeSocket = () => {
  const user = localStorage.getItem("user");
  // console.log("user", user);
  socket = io(process.env.REACT_APP_SERVER_SOCKET, {
    query: {
      userId: user ? JSON.parse(user)._id : null,
      userName: user ? JSON.parse(user).first_name : null,
      role: user ? JSON.parse(user).role : null,
    },
  });
};
