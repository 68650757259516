import React, { useState } from 'react'
import './subscribedsection.css'
import BranchLoading from '../../../../User/branchLoading/BranchLoading'

function Subscribedsection() {
  const [loading,setLoading] = useState(false)
  return (
    loading ? <BranchLoading/> :
    <div className='subscribe-container'>
      {/* map here */}
      <div className='subcribed-info'>
      التغذية
      </div>
      <div className='subcribed-info'>
      المناعة
      </div>
      <div className='subcribed-info'>
      المعدة
      </div>
    </div>
  )
}

export default Subscribedsection
