import React, { useContext, useEffect, useState } from 'react'
import './Webinar.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext'

export default function Webinar() {
    const navigate = useNavigate()
    const {user} = useContext(UserContext)
    const [formData, setFormData] = useState({
         name: '',
        phoneNumber: '',
        nationality: '',
        age: '',
        email:'',
        gender: '',
        injured: "",
        message: ''
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value // Update the field that triggered the change
        });
      };
      const [noUser,setNoUser] = useState(false)
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    navigate('/login')
  }

  return (
    <div className='webinar-main'>
        <div style={{width:'100%'}}><Navbar/></div>
        {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
        <HeadingComponent/>
      {/* <DateComponent/> */}
        <div className='w-middle-container'>
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:الوصف</h3>
            <h3 className='w-middle-text'>في هذه الندوة الممتدة لستين دقيقة، نستكشف العلاقة الحيوية بين التغذية والصحة النفسية، مع تسليط الضوء على كيف يمكن للأطعمة والمغذيات أن تؤثر بشكل مباشر على توازن النواقل العصبية والوظيفة العقلية. سنبدأ بشرح تأثير النظام الغذائي على إنتاج الناقلات العصبية مثل السيروتونين والدوبامين، ثم نستعرض الأطعمة الأساسية التي تساهم في تعزيز الصحة النفسية، بما في ذلك الأغذية الغنية بالأوميغا-3، المغنيسيوم، ومضادات الأكسدة. كما سنناقش الآثار السلبية للأطعمة المصنعة والسكريات المضافة على العقل والجهاز العصبي، وكيف يمكن استراتيجيات التغذية الصحية أن تساهم في الوقاية من الاضطرابات النفسية مثل القلق والاكتئاب. سنختتم الندوة بنصائح عملية حول كيفية بناء عادات غذائية يومية تدعم الصحة النفسية، متبوعة بجلسة تفاعلية للإجابة على أسئلة الحضور وتبادل الخبرات. هذه الندوة ستقدم أحدث المعلومات العلمية بشكل عملي وملهم، موجهة لكل من يبحث عن توازن نفسي وغذائي أفضل.</h3>
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:المتحدث</h3>
            <h3 className='w-middle-text'>د. مازن عوني السقا</h3>
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:الشهادة</h3>
            <h3 className='w-middle-text'>في حال حضورك لأكثر من 45 دقيقة فسوف تُمنح شهادة حضور للندوة من الأكاديمية</h3>
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:ملاحظة</h3>
            <h3 className='w-middle-text'>إذا لم يُناسبك الوقت سجل وسوف نُرسل لك رابط الندوة خلال أسبوع </h3>
            </div>
        </div>
        <LowerComponent/>
        {/* <InputsComponent handleChange={handleChange}  formData={formData} /> */}

      <div style={{width:'100%'}}><Footer/></div>
    </div>
  )
}

export function DateComponent () {
    return (
        <div className='date-component'>
        <div className='w-head-container'>
        <div>
            <h1 className='w-h-c-head'>احجز مكانك في ملتقى سرطان  الثدي مع الدكتور مازن السقا</h1>
        </div>
        </div>
        <div className='w-middle-container'>
            <div className='w-middle-flex'>
            <h3 className='w-upper-head'>:اليوم  </h3>
            <h3 className='w-upper-text'>{`${'2024'}  أكتوبر  ${'30'}`}</h3>
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-upper-head'>:الوقت </h3>
            <h3 className='w-upper-text'> 8 مساءً بتوقيت مكة المُكرمة </h3>
            </div>
        </div>
        </div>
    )
}
export function HeadingComponent() {
    return(
        <div className="date-component">
         <div className='w-head-container'>
        <div>
            <h1 className='w-h-c-head'>احجز مكانك في ملتقى سرطان  الثدي مع الدكتور مازن السقا</h1>
        </div>
        <div>
            <h3 className='w-h-c-text'>
             {` أكتوبر ${'2024'}| ${'8-9'} مساءً بتوقيت مكة المُكرمة ${'30'}  ` }
            </h3>
        </div>
      </div>
        </div>
    )
}
export function LowerComponent() {
    return(
        <div className="date-component">
            <div className="w-middle-container">
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:السعر   </h3>
            <h3 className='w-middle-text'>{`${'29'} دولار `} </h3>
            </div>

            <div className='w-middle-flex'>
            <h3 className='w-upper-head'>:رابط الندوة  </h3>
            <div>
            <p className='w-lower-text'>Zoom Meeting</p>
            <p className='w-lower-text'>https://D7adsAuncoDAbixQ09tc.1</p>
            <p className='w-lower-text'>Meeting ID:  {`84 6703`}</p>
            <p className='w-lower-text'>Passcode: {`79917`}</p>
            </div>
            </div>
            </div>
            <div className='input-heading-green'>
            <h1>تم التسجيل بنجاح   </h1>
            </div>
        </div>
    )
} 
export function InputsComponent({handleChange, formData}) {
    return(
        <div className="date-component">
            <div className='inputs-heading'>
            <h1>للتسجيل في الندوة املأ النموذج التالي</h1>
        </div>
      <div className='w-body-container'>
        <div className='w-b-c-body'>
            <div className='w-b-c-flex'>
            <div className='w-b-c-b-part'>
                <label>الاسم</label>
                <input name='name' type='text' onChange={handleChange} />
            </div>
            <div className='w-b-c-b-part'>
                <label>اسم العائلة </label>
                <input name='name' type='text' onChange={handleChange} />
            </div>

            </div>
            <div className='w-b-c-b-part'>
                <label>رقم الجوال</label>
                <input name='phoneNumber' type='text' onChange={handleChange} />
            </div>
            <div className='w-b-c-b-part'>
                <label>الجنسية</label>
                <input name='nationality' type='text' onChange={handleChange} />
            </div>
            <div className='w-b-c-b-part'>
                <label>العمر</label>
                <input name='age' type='text' onChange={handleChange} />
            </div>
            <div className='w-b-c-b-part'>
                <label>البريد الإلكتروني</label>
                <input name='email' type='text' onChange={handleChange} />
            </div>
        </div>
        <div className='w-b-c-body'>
            <div className='w-b-c-b-partt'>
                <div className='w-b-c-b-p-body-title'>الجنس</div>
                <div className='w-b-c-b-p-option'>
                    <div className='w-b-c-b-p-o-further'>
                        <label>انثى</label>
                        <input name="gender"
                        onChange={handleChange}
                        checked={formData.gender === 'female'}
            value="female" type='radio' />
                    </div>
                    <div className='w-b-c-b-p-o-further'>
                        <label>ذكر</label>
                        <input
                        name="gender"
                        checked={formData.gender === 'male'}
                        onChange={handleChange}
                        value="male" type='radio' />
                    </div>
                </div>
            </div>
            <div className='w-b-c-b-partt'>
                <div className='w-b-c-b-p-body-title'>هل انتي مصابة </div>
                <div className='w-b-c-b-p-option'>
                    <div className='w-b-c-b-p-o-further'>
                        <label>لا</label>
                        <input name='injured' checked={formData.injured === 'no'} value={'no'} onChange={handleChange} type='radio' />
                    </div>
                    <div className='w-b-c-b-p-o-further'>
                        <label>نعم</label>
                        <input name='injured' checked={formData.injured === 'yes'} value={'yes'} onChange={handleChange} type='radio' />
                    </div>
                </div>
            </div>
        </div>
        <div className='last-text-area'>
            <label>شاركنا اسئلتك</label>
            <textarea type='text' onChange={handleChange} name='message' />
        </div>
      </div>
      <div className='last-ending-btn'>
        <div>سعر التذكرة 99 دولار</div>
        <div>دولار 20</div>
        <div>
            <button className='l-e-b-btn'>التالي</button>
        </div>
      </div>
        </div>
    )
}