import React, { useContext, useEffect, useState } from 'react'
import DiscountCode from '../All Services/CodePage/DiscountCode'
import CodeVisit from '../All Services/CodeView/CodeVisit'
import axios from 'axios'
import { UserContext } from '../../../../context/UserContext'

function Discount() {

  return (
    <div>
      {/* {
        code.length > 0 ? <CodeVisit discounts={code} /> : <DiscountCode />
      } */}
      <DiscountCode />
    </div>
  )
}

export default Discount
