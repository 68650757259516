import React, { useContext, useEffect, useState } from 'react'
import './Userinterview.css'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../../../../context/UserContext'
import axios from 'axios'
import BranchLoading from '../../../../User/branchLoading/BranchLoading'
import Empty from '../../../../../Asserts/Images/norequest.png'


function UserInterview() {
  const {token} = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const {userId} = useParams()
const navigate  = useNavigate()
  const [userMeetingData,setUserMeetingData] = useState([])
  const getAllUserInterviews = async () => {
    setLoading(true)
    try {
      // Fetch user interviews
      await axios.get(`${process.env.REACT_APP_SERVER_URL}/usermanagement/getAllMeetings?userID=${userId}`, {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }
      ).then((response) => {
        // console.log("All Meetings",response)
        setUserMeetingData(response.data.response)
      })
      .catch((error) => {
        setUserMeetingData({})
        // console.error('Error fetching user profile data:', error)
      })
    } catch (error) {
      // console.error('Error fetching user profile data:', error)
    }
    setLoading(false)
  }


  useEffect(()=>{
    getAllUserInterviews()

  },[])
  return (
    loading ? <BranchLoading/> :
    <div className='ui-interviews-container'>
        {/* Sorry for not adding map set map her and put the ui-interviews div inside that and set the fields. */}
    {userMeetingData.length > 0 ? (
      userMeetingData.map((meeting, index) => (
        <div key={index} onClick={()=>navigate(`/Interviewdetail/${meeting?._id}`)} className='ui-interviews'>
          <div className='ui-i-info'>
            <div className='ui-i-i-date'>{meeting.Meeting_Day}</div>
            <div className='ui-i-i-title'>{meeting.Meeting_description}</div>
            <div>{meeting.Meeting_Date}<span className='ui-i-i-bold'>:التاريخ</span></div>
            <div>{meeting.Meeting_Hour}<span className='ui-i-i-bold'>:الساعة</span></div>
          </div>
        </div>
      ))
    ) : (
      <div className="norequestmaindiv">
          <img src={Empty} alt="" />
          لا توجد تحليلات
          </div>
    )}
    </div>
  )
}


export default UserInterview
