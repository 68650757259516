import React, { useContext, useState } from "react";
import Footer from "../../../Footer/Footer";
import Navbar from "../../../Navbar/Navbar";
import { Icon } from "@iconify/react/dist/iconify.js";
import book from "../../../../../Asserts/Images/Characters.png";
import Loading from "../../../Loading/Loading";
import axios from 'axios'
import { UserContext } from "../../../../../context/UserContext";
function MeetingCheck({setMeetingCheck,code,day,year,date,setCode,gotopay,loading}) {
  const [showCode,setShowCode] = useState(false)
  const {token} = useContext(UserContext)
  const [discountRate, setDiscountRate] = useState(0);
  const [discoutPrice, setDiscoutPrice] = useState(0);

  function back () {
    setMeetingCheck(false)
  } const Submit = () => {
    gotopay()
  }

    const submitcode = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/discounts/getDiscountByDiscountCode/${code}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("discount", res.data);
          setDiscountRate(res.data.data.percentage);
          setShowCode(true)
          setDiscoutPrice(Math.floor(2000 - (2000 * res.data.data.percentage) / 100));
          })
        .catch((err) => {
          console.log(err);
          setCode("");
          setShowCode(false)
          if (err.response.status === 404) {
            alert("Invalid Discount Code");
          }
          if(err.response.status === 400){
            alert("Discount code has expired or not active");
          }
          if (err.response.status === 401) {
            alert("You are not authorized to use this discount code");
          }


        });
    };

  return (
    loading ? <Loading/> :
    <div>
      {/* Navbar  */}
      <div>
        <Navbar />
      </div>

      {/* Body  */}
      <div className="book-discount-container">
        <div className="book-discount-inner">
          <div className="book-discount-header">
            <div className="book-discount-header-icon" onClick={back}>
              <Icon icon="weui:back-filled" />
            </div>
            <div className="book-discount-header-text">إتمام عملية الشراء</div>
          </div>
          <div className="book-discount-lower">
            <div className="book-discount-lower-book">
              <div className="book-discount-lower-book-details">
                <div className="book-discount-lower-book-details-img">
                  <img src={book} alt="Book" />
                </div>
                <div className="book-discount-lower-book-details-text">
                  <div className="book-discount-lower-book-details-text-title">
                    {"جرثومة المعدة"}
                  </div>
                  <div className="book-discount-lower-book-details-text-author">
                    <div className="">{day}</div>
                    <div>:اليوم</div>
                  </div>
                  <div className="book-discount-lower-book-details-text-author">
                    <div className="">{date}</div>
                    <div>:الوقت</div>
                  </div>
                  <div className="book-discount-lower-book-details-text-author">
                    <div className="">{"Zoom Online"}</div>
                    <div>:المنصة</div>
                  </div>
                </div>
              </div>
              <div className="book-discount-lower-book-line"></div>
              <div className="book-discount-lower-book-price">
                <div>{"2000 saudi riyal"}</div>
                <div>:المبلغ كامل</div>
              </div>
            </div>
            <div className="book-discount-lower-code">
              <div className="book-discount-lower-input-container">
                <div className="book-discount-lower-input">
                  <input
                    type="text"
                    placeholder="اكتب كود الخصم"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="book-discount-lower-button">
                  <button onClick={() => submitcode()}>تفعيل كود الخصم</button>
                </div>
              </div>
              {
                showCode && (
                    <div className="book-discount-lower-discount">
                    <div className="book-discount-lower-discount-text">
                      <div> {discountRate}</div>
                      <div>:خصم بمناسبة اليوم الوطني</div>
                    </div>
                    <div className="book-discount-lower-discount-text">
                      <div> {discoutPrice}</div>
                      <div>:المبلغ بعد الخصم</div>
                    </div>
                  </div>
                )
              }

            </div>
            <div className="book-discount-lower-next">
              <button onClick={Submit}>التالي</button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer  */}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default MeetingCheck;
