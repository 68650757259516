import React, { useState } from 'react'
import Navbar from '../../User/Navbar/Navbar'
import Footer from '../../User/Footer/Footer'
import './userinfo.css'
import UserProfile from './UserInfoComponents/UserProfile/UserProfile'
import UserInterview from './UserInfoComponents/UserInterviews/UserInterview'
import UserAnalytics from './UserInfoComponents/UserAnalytics/UserAnalytics'
import UserPurchases from './UserInfoComponents/UserPurchases/UserPurchases'
import Subscribedsection from './UserInfoComponents/SubscribedSection/Subscribedsection'

function UserInfo() {
  const [active,setActive] = useState('المقابلات')
  return (
    <div className='userinfo-container'>
      <div>
        <Navbar/>
      </div>
      <div>
        <div>
            <UserProfile/>
        </div>
      </div>
      <div className='btns-contnr'>
        <div className={active === 'المقابلات' ? 'ui-btn-active' : 'ui-btn'}><button onClick={()=>setActive('المقابلات')}>المقابلات</button></div>
        <div className={active === 'التحليلات' ? 'ui-btn-active' : 'ui-btn'}><button onClick={()=>setActive('التحليلات')}>التحليلات</button></div>
        <div className={active === 'المشتريات' ? 'ui-btn-active' : 'ui-btn'}><button onClick={()=>setActive('المشتريات')}>المشتريات</button></div>
        {/* <div className={active === 'الأقسام المشترك بها' ? 'ui-btn-active' : 'ui-btn'}><button onClick={()=>setActive('الأقسام المشترك بها')}>الأقسام المشترك بها</button></div> */}
      </div>
      <div className='ui-branches-container'>
        {
          active === 'المقابلات' ?
          <UserInterview/> : <></>
        }
        {
          active === 'التحليلات' ? 
          <UserAnalytics/> : <></>
        }
        {
          active === 'المشتريات' ? 
          <UserPurchases/> : <></>
        }
        {
          active === 'الأقسام المشترك بها' ? 
          <Subscribedsection/> : <></>
        }
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}

export default UserInfo
