import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../User/Navbar/Navbar'
import Footer from '../../User/Footer/Footer'
import banner1 from '../../../Asserts/Images/Frame 313406.png'
import banner2 from '../../../Asserts/Images/Frame 313412.png'
import './allbanners.css'
import { useNavigate } from 'react-router-dom'
import { useSessionErrorHandler } from '../../User/Managesession'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'

function AllBanners() {

  const {token} = useContext(UserContext)
    const [banners,setBanners] = useState([banner1,banner2])

    const navigate = useNavigate()
    const handleSessionError = useSessionErrorHandler();

    function toAddBanner () {
        navigate('/uploadbanner')
    }

    const [bannerloading,setBannerLoading] = useState(false)

    const getAllBanners = async () => {
      try {
        setBannerLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/banner/getall`,{
            headers: { authorization: `Bearer ${token}` },
        }
        );
        console.log(response.data);
        setBanners(response.data.response);
        setBannerLoading(false);
      } catch (err) {
        // console.log(err);
        handleSessionError(err)
        setBannerLoading(false);
      }
    };

    useEffect(()=>{
      getAllBanners()
    },[])

    const gotouplaodvideo = (e, id) => {
      e.stopPropagation(); // Prevents the event from bubbling up
      navigate(`/updatedbanner/${id}`); // Interpolating the id into the URL
    };

  return (
    <div className='allbanner-container'>
      <div>
        <Navbar/>
      </div>
      <div className='a-b-c-body'>
        <div className='a-b-c-b-heading'>الإعلانات</div>
        <div className='a-b-c-b-banners-container'>
            {
                banners.length === 0 ? <></> : (
                    banners.map((item)=>{
                        return(
                            <>
                            <div className='a-b-c-b-b-c-img' onClick={(e) => gotouplaodvideo(e, item._id)}>
                                <img src={item.bannerPic} alt="nothing" />
                            </div>
                            </>
                        )
                    })
                )
            }
        </div>
        <div className='a-b-c-add-banner-btn'>
            <button onClick={toAddBanner}> اضافة اعلان </button>
        </div>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}

export default AllBanners
