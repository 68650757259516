import React, { useContext, useEffect, useState } from 'react'
import dummy from '../../.../../../../../../../Asserts/Images/Video.png'
import { useNavigate } from 'react-router-dom'
import './videocomponentsstyle.css'
import { UserContext } from '../../../../../../../context/UserContext'
import novideo from '../../../../../../../Asserts/Images/novideos.png'
import { Icon } from '@iconify/react/dist/iconify.js'
import axios from 'axios'

function Added({activityVideos}) {
    const navigate = useNavigate()
    const [videoLoading, setVideoLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [activePage,setActivePage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);

    const paginationIncrement = () => {
        if (pagination < totalPages) {
          if((pagination)%2===0){
            setActivePage(activePage+2)
          }
          setPagination((prevPagination) => prevPagination + 1);

        }
      };

      const paginationDecrement = () => {
        if (pagination > 1) {
          if((pagination-1)%2===0){
            setActivePage(activePage-2)
          }
          setPagination((prevPagination) => prevPagination - 1);
        }
      };


      const getEmbedUrl = (url) => {
        if (!url) return ""; // Early return if URL is null or undefined

        const videoIdMatch = url.match(
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
        );

        if (videoIdMatch && videoIdMatch[1].length === 11) {
          // Return the URL for the default YouTube video thumbnail
          return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`; // High-resolution thumbnail
        } else {
          return ""; // Return empty string if the URL is not a YouTube video
        }
      };


  return (
    <div className='whole-added'>
        <div className='added-video-elements-container'>

            {/* map here */}

    {activityVideos?.uploadedVideos?.length > 0 ? (
      activityVideos.uploadedVideos.map((uploadedvideos, index) => (
        <div className='added-video-elements' key={index}>
          <div className='added-details'>
            <div className='a-d-details'>
              {/* <div style={{ direction: 'rtl' }}>
                {'التعليقات'} - {'الفيديو'} - {'الوصف'} :{uploadedvideos.description}              </div> */}
              <div>{uploadedvideos.uploadingTime || 'Unknown Date'} :تاريخ التعديل</div>
            </div>
            <div className='a-d-video'>
              <div className='a-d-v-img'>
                <img src={uploadedvideos.thumbnail || dummy} alt="" />
              </div>
              <div className='a-d-v-details'>
                <div className='a-d-v-d-title'>{uploadedvideos.title || 'Unknown Title'}</div>
                {/* <div>{uploadedvideos.duration || 'Unknown Duration'}</div> */}
                <div className='a-d-v-d-description'>{uploadedvideos.description || 'No Description'}</div>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div
      style={{padding:'70px',fontSize:'20px',backgroundColor:'white',borderRadius:'14px'}}
      >لم يتم تحميل أي مقاطع فيديو حتى الآن</div>
    )}
      </div>
      <div className="pagination-container">
                  {
                     totalPages===1 || <div
                    className={
                      pagination === 1
                        ? "pagination-disable"
                        : "pagination-enable"
                    }
                    onClick={paginationDecrement}
                  >
                    {"<"}
                  </div> }
                  <div>
                    <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"} onClick={paginationDecrement}>{activePage}</span>
                  </div>
                  {
                    totalPages===1 || <div>
                    <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"} onClick={paginationIncrement}>{activePage + 1}</span>
                  </div>}
                  {
                    totalPages===1 || <div
                    className={
                      pagination === totalPages
                        ? "pagination-disable"

                        : "pagination-enable"
                    }
                    onClick={paginationIncrement}
                  >
                    {">"}
                  </div>}
                </div>
    </div>
  )
}

export default Added
