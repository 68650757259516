import React, { useContext, useState } from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import './WebinarDetail.css'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'

const WebinarDetails = () => {
  const {id} = useParams();
  const [webinar, setWebinar] = useState({});
  const navigate = useNavigate()
    const {user} = useContext(UserContext)
  const [noUser,setNoUser] = useState(false)
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    navigate('/login')
  }

  const getWebinarDetails = async()=>{
    try{
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinar/${id}`);
      setWebinar(response.data.data);
    }catch(error){
      console.error('Error fetching webinar:', error);
    }
  }
  useEffect(() => {
    getWebinarDetails();
  }, [id]);
  return (
    <div className='webinar-main'>
        <div style={{width:'100%'}}><Navbar/></div>
        {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
      <div className='w-head-container'>
        <div>
            <h1 className='w-h-c-head'>{webinar?.title}</h1>
        </div>
      </div>
        <div className='w-middle-container'>
            <div className='w-middle-flex'>
            <h3 className='w-upper-head'>:اليوم  </h3>
            <h3 className='w-upper-text'>{webinar?.day}</h3>
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-upper-head'>:الوقت </h3>
            <h3 className='w-upper-text'>{webinar.date} </h3>
            </div>
        </div>

        <div className='w-middle-container'>
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:الوصف</h3>
            <h3 className='w-middle-text'>{webinar.description}</h3>
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:المتحدث</h3>
            <h3 className='w-middle-text'>{webinar.presenter}</h3>
            </div>
            <div className='w-middle-flex'>
            {/* <h3 className='w-middle-head'>:الشهادة</h3>
            <h3 className='w-middle-text'>{webinar.certificate_descriptions}</h3> */}
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:ملاحظة</h3>
            <h3 className='w-middle-text'>{webinar.instructions} </h3>
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-middle-head'>:السعر   </h3>
            {/* <h3 className='w-middle-text'>{`${webinar.price} SAR`} </h3> */}
            </div>
            <div className='w-middle-flex'>
            <h3 className='w-upper-head'>:رابط الندوة  </h3>
            <div>
            <p className='w-lower-text'>Zoom Meeting</p>
            <p className='w-lower-text'>{webinar.Meeting_link}</p>
            <p className='w-lower-text'>Meeting ID:  {webinar.ZoomMeeting_id}</p>
            <p className='w-lower-text'>Passcode: {webinar.ZoomMeetingPasscode}</p>
            </div>
            </div>

        </div>
        <div className='input-heading'>
            <h1>تم التسجيل بنجاح   </h1>
        </div>
      <div style={{width:'100%'}}><Footer/></div>
    </div>
  )
}

export default WebinarDetails
