import React, { useState } from 'react';
import './Uploodbook.css';
import Footer from '../../User/Footer/Footer';
import Navbar from '../../User/Navbar/Navbar';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import Loading from '../../User/Loading/Loading';
import { useSessionErrorHandler } from '../../User/Managesession';


function Uplaodnewbook() {
  const handleSessionError = useSessionErrorHandler();
  const { setSuccessAction, setLoading,loading,setSuccess,token ,user} = useContext(UserContext);
  const navigate = useNavigate();
  const [bookFile, setBookFile] = useState(null);
  const [bookFileName, setBookFileName] = useState('');
  const [bookImage, setBookImage] = useState(null);
  const [bookImageUrl, setBookImageUrl] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState();
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [authorInfo, setAuthorInfo] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
const {section} = useParams();

// console.log(section);


function descriptionChange (e) {
  if(e.target.value.length > 999){
    alert('لقد تجاوزت الحد الأقصى للوصف وهو 1000 حرف')
    return
  }
  setDescription(e.target.value)
}

  const handleBookFileChange = (e) => {
    const file = e.target.files[0];
    // console.log(file.name);
    if (file) {
      setBookFile(file);

      setBookFileName(file.name);
      // console.log(bookFileName);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBookImage(file);
      setBookImageUrl(URL.createObjectURL(file)); // Create a URL for the selected image
    }
  };

  const goback = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    setFormSubmitted(true);
    if (title && price && author && description && authorInfo && bookFile && bookImage) {

      const formData = new FormData();
      formData.append('title', title);
      formData.append('price', price);
      formData.append('author', author);
      formData.append('description', description);
      formData.append('authorInfo', authorInfo);
      formData.append('bookPDF', bookFile);
      formData.append('bookCoverPhoto', bookImage);
      formData.append('section', section);


      try {
        setLoading(true)
        const response = await axios.post( `${process.env.REACT_APP_SERVER_URL}/book/upload?adminId=${user._id}`, formData,{headers : {authorization : `Bearer ${token}`}} )
        if (response.data) {
          // console.log(response.data);
          // Handle successful response
          setLoading(false)
          navigate('/success');
          setSuccess("تم تعديل البيانات بنجاح");
          setSuccessAction(`/buybook/${response.data.data._id}`);

        } else {
          setLoading(false)
          // Handle error response
          // console.error('Upload failed:', response.statusText);
        }
      } catch (error) {
        handleSessionError(error);
        setLoading(false)
        // console.error('Upload error:', error);
      }
    }
    else{
      alert('يرجى ملء جميع الحقول')
    }
  };

  return (
    loading ?<Loading/>:
    <>
      <div className="uploadmaindiv">
        <div className="uplaodbooknavbar">
          <Navbar />
        </div>

        <div className="uploadbookcontainer">
          <div className="topupernavbar">
            <div><Icon onClick={goback} icon="ep:arrow-left-bold" width="1.2em" height="1.2em" /></div>
            <div>تعديل بيانات الكتاب</div>
          </div>

          <div className="bookcontainernew ">
            {bookImageUrl ? (
              <img src={bookImageUrl} alt="Book Cover" className="uploadedBookImage" />
            ) : (
              <></>
            )}
          </div>

          <button className="uploadButton">
            <label htmlFor="bookImageUpload" className="customUploadButton">
              ارفع صورة الكتاب
            </label>
            <input
              type="file"
              id="bookImageUpload"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </button>

          <div className="inputmaindivtitle">
            <label htmlFor="">عنوان الكتاب</label>
            <input
              type="text"
              placeholder='جرثومة المعدة'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={formSubmitted && !title ? 'input-error' : ''}
            />
          </div>

          <div className="twoinputsmaindiv">
            <div className="leftshortdiv">
              <label htmlFor="">السعر</label>
              <input
                type="number"
                placeholder='0'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className={formSubmitted && !price ? 'input-error' : ''}
              />
            </div>

            <div className="rightlonginput">
              <label htmlFor="">اسم الكاتب</label>
              <input
                type="text"
                placeholder='دزمازن السقا'
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                className={formSubmitted && !author ? 'input-error' : ''}
              />
            </div>
          </div>

          <div className="descraptiondivmain">
            <label htmlFor="">الوصف</label>
            <textarea
              value={description}
              onChange={descriptionChange}
              placeholder='الحد 1000 حرف'
              className={formSubmitted && !description ? 'input-error' : ''}
            ></textarea>
          </div>

          <div className="descraptiondivmain">
            <label htmlFor="">معلومات عن المؤلف</label>
            <textarea
              value={authorInfo}
              onChange={(e) => setAuthorInfo(e.target.value)}
              className={formSubmitted && !authorInfo ? 'input-error' : ''}
            ></textarea>
          </div>

          <div className="booknamedivbookicon">
            {bookFileName ? bookFileName : "No Book Uploaded"}
            <Icon icon="material-symbols-light:book-4-rounded" width="1.2em" height="1.2em" />
          </div>

          <button className="uploadButton">
            <label htmlFor="bookUpload" className="customUploadButton">
              ارفع ملف الكتاب
            </label>
            <input
              type="file"
              id="bookUpload"
              accept=".pdf, .doc, .docx, .epub"
              onChange={handleBookFileChange}
              style={{ display: 'none' }}
            />
          </button>

          <button onClick={handleSubmit}>حفظ</button>
        </div>

        <div className="uplaodbookfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Uplaodnewbook;
