import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import dummy from '../../.../../../../../../../Asserts/Images/Video.png'


function OngoingAnalysis({activityAnalysis}) {
    const navigate = useNavigate()
    const [videoLoading, setVideoLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [activePage,setActivePage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);

    const paginationIncrement = () => {
        if (pagination < totalPages) {
          if((pagination)%2===0){
            setActivePage(activePage+2)
          }
          setPagination((prevPagination) => prevPagination + 1);

        }
      };
      const paginationDecrement = () => {
        if (pagination > 1) {
          if((pagination-1)%2===0){
            setActivePage(activePage-2)
          }
          setPagination((prevPagination) => prevPagination - 1);
        }
      };

  return (
    <div className='whole-added'>
        <div className='added-video-elements-container'>

            {/* map here */}

            {activityAnalysis.acceptedAnalysis.length > 0 ? (
  activityAnalysis.acceptedAnalysis.map((acceptedAnalysis, index) => (
    <div key={index} className='added-video-elements'>
      <div className='added-details'>
        <div className='a-d-details2'>
          <div style={{ direction: 'rtl' }}>
            {/* {'التعليقات'} - {'الفيديو'} - {'الوصف'} :تم تعديل */}
          </div>
          <div>{acceptedAnalysis.accepting_date} :تاريخ التعديل</div>
        </div>
        <div className='a-d-video1'>
          <div className="notificationcardsmain">
            <div className="notificationcardleft">
              {acceptedAnalysis.request_status}
            </div>
            <div className="notificatoncardright">
              <div className="noticardtopdiv">
                <div className="notiardtopleft">
                  { acceptedAnalysis.requested_by.phone_number}
                </div>
                <div className="noticardtopright">
                  <div>{acceptedAnalysis.requested_by.first_name}</div>
                  <div>{acceptedAnalysis.request_title}</div>
                  <div className='givepurplecolor'>{acceptedAnalysis.requestDay} {acceptedAnalysis.requestDate}</div>
                </div>
              </div>
              <div className="noticardbottomdiv">
                {acceptedAnalysis.request_description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
) : (
  <div
  style={{padding:'70px',fontSize:'20px',backgroundColor:'white',borderRadius:'14px'}}
  >لم يتم العثور على أي تحليل</div>
)}
      </div>
      <div className="pagination-container">
                  {
                     totalPages===1 || <div
                    className={
                      pagination === 1
                        ? "pagination-disable"
                        : "pagination-enable"
                    }
                    onClick={paginationDecrement}
                  >
                    {"<"}
                  </div> }
                  <div>
                    <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"} onClick={paginationDecrement}>{activePage}</span>
                  </div>
                  {
                    totalPages===1 || <div>
                    <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"} onClick={paginationIncrement}>{activePage + 1}</span>
                  </div>}
                  {
                    totalPages===1 || <div
                    className={
                      pagination === totalPages
                        ? "pagination-disable"

                        : "pagination-enable"
                    }
                    onClick={paginationIncrement}
                  >
                    {">"}
                  </div>}
                </div>
    </div>
  )
}

export default OngoingAnalysis
