import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import './restrictions.css'

const RestrictionsRules = ({ children }) => {
  const { user,verification } = useContext(UserContext);

  const navigate = useNavigate()
  // Check if the user is logged in
  function gotodashboard () {
    navigate('/')
  }
  // if (!user) {
  //   // If not logged in, redirect to the login page
  //   // alert('Please Login First')
  //   // return <Navigate to="/" />
  //   return(
  //     <div>
  //       <div className="deletemaindiv">
  //               <div className="login-res-cont">
  //                 <div className="deletetitle">
  //                   <div className='del-text'>الرجاء تسجيل الدخول</div>

  //                 </div>
  //                 <div className='login-res-line'></div>


  //                 <button onClick={gotodashboard} className='l-r-l-btn'>

  //                 التسجيل الان
  //                 </button>
  //               </div>
  //             </div>
  //     </div>
  //   )
  // }


  return children
};


export default RestrictionsRules;
