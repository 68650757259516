import React, { useContext, useEffect, useState } from "react";
import "./alldiscounts.css";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import Logo from "../../../../Asserts/Images/LogoMazen.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../../context/UserContext";

function AllDiscounts() {
  const [toggle, setToggle] = useState(false);
  const [popup, setPopup] = useState(false);
  const {token} = useContext(UserContext)
  const [delPopup, setDelpopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState([

  ]);

  const [codeCopied,setCodeCopied] = useState(null)
  function copiedCode (index) {
    setCodeCopied(true)
    setTimeout(() => {
      setCodeCopied(null)
    }, 2000);
  }
  function copyCode (code,index) {
    navigator.clipboard.writeText(code);
    copiedCode(index)
  }

  const acitvateAndDeactivateCode = async (index) => {
    const id = data[index]._id;
    const currentStatus = data[index].isActive;
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/discounts/activateAndDeactivateDiscount/${id}`,
        { isActive: !currentStatus },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getAllDiscounts();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllDiscounts = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/discounts/getAllDiscounts`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("discounts", res.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

 useEffect(() => {
  getAllDiscounts();
  }, []);



  function gotodashboard () {
    setDelpopup(false)
  }

 async function deltPopup () {
    setDelpopup(false)
    await axios.delete(`${process.env.REACT_APP_SERVER_URL}/discounts/deleteDiscount/${deleteId}`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((res) => {
      console.log(res.data);
      getAllDiscounts();
    })
    .catch((err) => {
      console.log(err);
    });
  }

  async function deleteIt (e,id) {
    e.stopPropagation()
    setDelpopup(true)
    setDeleteId(id)
  }

  function gotoChanges (id) {
    navigate(`/codeedit/${id}`)
  }

  return (
    <div>
      {/* Navbar  */}
      <div>
        <Navbar />
      </div>

      {/* main popup  */}
      {
        delPopup &&
        <div className="deletemaindiv" onClick={deltPopup} style={{zIndex: '7'}}>
                <div className="login-res-cont" style={{backgroundColor: "white"}}>
                  <div className="deletetitle">
                    <div className='del-text'>هل أنت متأكد انك تريد حذف كود الخصم</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={gotodashboard} className='l-r-l-btn'
                  style={{backgroundColor: "red"}}>

                  نعم
                  </button>
                </div>
              </div>
      }


      {/* Body  */}
      <div className="codes-container">

        {/* Back one  */}
        <div className="codes-header">
          <div className="codes-header-icon">
            <Icon icon="weui:back-filled" />
          </div>
          <div>أكواد الخصومات</div>
        </div>

        {/* Data  */}
        <div className="codes-data-container">
        {
            codeCopied ? <div className="code-copied">تم نسخ الكود بنجاح</div> : null
        }
          {/* Map for codes  */}

          {
            data.map((item,index) => {
                return(
                    <div className="code-data">

            <div className="code-data-upper">
              <div className="code-data-upper-code">{item?.code}</div>
              <div className="code-data-upper-logo">
                <img src={Logo} alt="Mazen" />
              </div>
              <div className="code-data-upper-right-ball"></div>
              <div className="code-data-upper-left-ball"></div>
            </div>
            <div className="code-data-lower">
              <div className="code-data-lower-occassion">
                <div className="code-data-lower-occassion-date">
                  {item.createdAt}
                </div>
                <div>
                  <div>{item.reason}</div>
                  <div
                    className="code-data-lower-occassion-dots"
                    onClick={() => setPopup(index)}
                  >
                    <Icon icon="pepicons-pop:dots-y" />
                  </div>
                </div>

                {/* Popup  */}
                {popup === index && (
                  <>
                    <div className="code-data-lower-occassion-popup">
                      <div className="code-data-lower-occassion-popup-edit" onClick={() => gotoChanges(item._id)}>
                        تعديل
                      </div>
                      <div className="code-data-lower-occassion-popup-line"></div>
                      <div className="code-data-lower-occassion-popup-delete" onClick={(e)=>deleteIt(e,item._id)}>
                        حذف
                      </div>
                    </div>
                    {/* Popup hunter */}
                    <div
                      className="code-data-lower-occassion-popup-hunter"
                      onClick={() => setPopup(null)}
                    ></div>
                  </>
                )}
              </div>
              <div className="code-data-lower-discount-rate">
                <span>خصم</span> <span>{item.percentage}</span>
              </div>
              <div className="code-data-lower-dates">
                <div className="code-data-lower-dates-date">
                  <div className="code-data-lower-dates-date-date">
                    {item.endDate}
                  </div>
                  <div>:الى</div>
                </div>
                <div className="code-data-lower-dates-date">
                  <div className="code-data-lower-dates-date-date">
                    {item.startDate}
                  </div>
                  <div>:من</div>
                </div>
              </div>
              <div
                className="code-data-lower-status"
              >
                {!item.isActive ? (
                  <div className="code-data-lower-status-gher">غير مفعل</div>
                ) : (
                  <div>مفعل</div>
                )}
                {!item.isActive ? (
                  <div className="code-data-lower-status-toggle-inactive" onClick={() => acitvateAndDeactivateCode(index)}>
                    <div className="code-data-lower-status-toggle-circle-inactive"></div>
                  </div>
                ) : (
                  <div className="code-data-lower-status-toggle" onClick={() => acitvateAndDeactivateCode(index)}>
                    <div className="code-data-lower-status-toggle-circle"></div>
                  </div>
                )}
              </div>
              <div className="code-data-lower-button">
                <button onClick={() => copyCode(item.code,item.index)}>نسخ الكود</button>
              </div>
            </div>
          </div>
                )
            })
          }
        </div>
      </div>

      {/* Footer  */}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default AllDiscounts;
