// import React, { useContext, useState } from "react";
// import './Account.css';
// import SimpleNavbar from "../SimpleNavbar/SimpleNavbar";
// import Footer from "../Footer/Footer";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { UserContext } from "../../../context/UserContext";
// import Loading from "../Loading/Loading";
// import Navbar from "../Navbar/Navbar";
// import { useSessionErrorHandler } from "../Managesession";

// import heic2any from "heic2any";
// export default function Account() {




//   const handleSessionError = useSessionErrorHandler();
//   const {user, setLoading, loading,token, setSuccessAction} = useContext(UserContext);
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [date, setDate] = useState("");
//   const [email, setEmail] = useState("");
//   const [image, setImage] = useState(null);
//   const [imageFile, setImageFile] = useState(null);
//   const [error, setError] = useState("");
//   const [submitAttempt, setSubmitAttempt] = useState(false);


//   const navigate = useNavigate();

//   useEffect(()=>{
//     const getuser = async()=>{
//         try{
//             await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/userDetails/${user._id}`,{
//                 headers:{
//                     authorization:`Bearer ${token}`
//                 }
//             }).then((response)=>{
//                 console.log("user data" , response);
//                 setUserdata(response.data.data)
//             }
//             ).catch((error)=>{
//                 handleSessionError(error)
//                 console.log(error);

//             })
//         }
//         catch(err){
//             handleSessionError(err)
//         }
//     }
//     getuser();
//    },[])


//   const handleImageUpload = async (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       try {
//         let processedFile = file;

//         // Convert HEIC to JPG if the file is in HEIC format
//         if (file.type === "image/heic" || file.type === "image/heif") {
//           const convertedBlob = await heic2any({
//             blob: file,
//             toType: "image/jpeg",
//           });
//           processedFile = new File([convertedBlob], `${file.name}.jpg`, {
//             type: "image/jpeg",
//           });
//         }

//         setImageFile(processedFile);

//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setImage(reader.result);
//         };
//         reader.readAsDataURL(processedFile);
//       } catch (error) {
//         console.error("Error converting HEIC image:", error);
//         setError("Failed to process image.");
//         setTimeout(() => setError(""), 30000);
//       }
//     }
//   };

//   const handleButtonClick = () => {
//     document.getElementById("image-upload").click();
//   };

//   const handleSubmit = async () => {
//     setSubmitAttempt(true);

//     // Check if all fields are empty
//     const formFields = [firstName, lastName, date, email, imageFile];
//     const allFieldsEmpty = formFields.every(field => !field);

//     if (allFieldsEmpty) {
//       // return alert('Please fill in at least one field.');
//     }

//     const formData = new FormData();
//     if (firstName) formData.append("first_name", firstName);
//     if (lastName) formData.append("last_name", lastName);
//     if (date) formData.append("Date_Of_Birth", date);
//     if (email) formData.append("email", email);
//     if (imageFile) formData.append("photo", imageFile);

//     try {
//       console.log('formData:', formData);
//       setLoading(true);
//       await axios.patch(`${process.env.REACT_APP_SERVER_URL}/user/update/${user._id}`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           authorization: `Bearer ${token}`,
//         },
//         withCredentials: true
//       })
//       .then((res) => {
//         console.log(res);
//         if (res.status === 200) {
//           setSuccessAction('/');
//           setLoading(false);
//           navigate("/success");
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         handleSessionError(err);

//         setLoading(false);
//       });
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   return (
//     loading ? <Loading /> :
//     <div className="topmaindivclass">
//       <Navbar />
//       <div className="maindivofprofiles">
//         {error && (
//           <div className="classnamediv">{error}</div>
//         )}
//         <div className="profiledatadiv">
//           <h1>إعدادات الحساب</h1>

//           <div className="mainflexdivphotoandcontent">
//             <div className="inputprofilemaindiv">
//               <div>
//                 <label>الاسم الأول</label>
//               </div>
//               <input
//                 type="text"
//                 placeholder="بالرجاء ادخال الاسم الأول"
//                 value={firstName}
//                 onChange={(e) => setFirstName(e.target.value)}
//                 className={submitAttempt && !firstName ? 'input-error' : ''}
//               />

//               <div>
//                 <label>الاسم الأخير</label>
//               </div>
//               <input
//                 type="text"
//                 placeholder="بالرجاء ادخال الاسم الأخير"
//                 value={lastName}
//                 onChange={(e) => setLastName(e.target.value)}
//                 className={submitAttempt && !lastName ? 'input-error' : ''}
//               />

//               <div>
//                 <label>تاريخ الميلاد</label>
//               </div>
//               <div className="datemaindiv">
//                 <input
//                   type="date"
//                   value={date}
//                   onChange={(e) => setDate(e.target.value)}
//                   min="1900-01-01"
//                   max="2100-12-31"
//                   placeholder="اختر التاريخ"
//                   className={submitAttempt && !date ? 'input-error' : ''}
//                 />
//               </div>

//               <div>
//                 <label>البريد الالكتروني</label>
//               </div>
//               <input
//                 type="text"
//                 placeholder="بالرجاء ادخال البريد الالكتروني"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 className={submitAttempt && !email ? 'input-error' : ''}
//               />

//               <div className="profilebtndiv">
//                 <button onClick={handleSubmit}> حفظ </button>
//               </div>
//             </div>

//             <div
//               className="rightprofile"
//               style={{ textAlign: "center", marginTop: "50px" }}
//             >
//               <input
//                 type="file"
//                 accept="image/*"
//                 style={{ display: "none" }}
//                 id="image-upload"
//                 onChange={handleImageUpload}
//               />
//               <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
//                 {image ? (
//                   <img
//                     src={image}
//                     alt="Uploaded"
//                     style={{
//                       width: "200px",
//                       height: "200px",
//                       objectFit: "cover",
//                       borderRadius: "50%",
//                       border: "2px solid #ccc",
//                     }}
//                   />
//                 ) : (
//                   <div
//                     style={{
//                       width: "200px",
//                       height: "200px",
//                       borderRadius: "50%",
//                       background: "#29292C",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       border: "2px solid #ccc",
//                     }}
//                   >
//                     <span className="iconuser">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="1.2em"
//                         height="1.2em"
//                         viewBox="0 0 24 24"
//                       >
//                         <circle
//                           cx={12}
//                           cy={6}
//                           r={4}
//                           fill="currentColor"
//                         ></circle>
//                         <path
//                           fill="currentColor"
//                           d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5"
//                         ></path>
//                       </svg>
//                     </span>
//                   </div>
//                 )}
//               </label>
//               <div className="imageclass" style={{background:submitAttempt && !image && "red"}} onClick={handleButtonClick}>
//                 أضف صورة
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="profilefootermaindiv">
//         <Footer />
//       </div>
//     </div>
//   );
// }


import React, { useContext, useState, useEffect } from "react";
import './Account.css';
import SimpleNavbar from "../SimpleNavbar/SimpleNavbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import Loading from "../Loading/Loading";
import Navbar from "../Navbar/Navbar";
import { useSessionErrorHandler } from "../Managesession";
import heic2any from "heic2any";

export default function Account() {
  const handleSessionError = useSessionErrorHandler();
  const { user,setUser, setLoading, loading, token, setSuccessAction } = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState("");
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [previousimg, setPreviousimg] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/userDetails/${user._id}`, {
          headers: {
            authorization: `Bearer ${token}`
          }
        });
        // console.log("User data:", response.data);
        const userData = response.data.data;
        const date = new Date(userData.Date_Of_Birth);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;

        setFirstName(userData.first_name );
        setLastName(userData.last_name || "");
        setDate(formattedDate);
        setEmail(userData.email || "");
        if (userData.photo) {
          setPreviousimg(userData.photo);
        }
      } catch (error) {
        handleSessionError(error);
        // console.error("Error fetching user data:", error);
      }
    };
    getUserData();
  }, []);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        let processedFile = file;
        if (file.type === "image/heic" || file.type === "image/heif") {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
          });
          processedFile = new File([convertedBlob], `${file.name}.jpg`, {
            type: "image/jpeg",
          });
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(processedFile);

        setImageFile(processedFile);
        setPreviousimg(null); // Hide previous image when new image is uploaded

      } catch (error) {
        // console.error("Error converting HEIC image:", error);
        setError("Failed to process image.");
        setTimeout(() => setError(""), 3000);
      }
    }
  };

  const handleButtonClick = () => {
    document.getElementById("image-upload").click();
  };

  const [noUser,setNoUser] = useState(false)
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    navigate('/login')
  }

  const handleSubmit = async () => {
    setSubmitAttempt(true);

    const formFields = [firstName, lastName, date, email, imageFile];
    const allFieldsEmpty = formFields.every(field => !field);

    if (allFieldsEmpty) {
      return; // Handle the case where all fields are empty
    }

    const formData = new FormData();
    if (firstName) formData.append("first_name", firstName);
    if (lastName) formData.append("last_name", lastName);
    if (date) formData.append("Date_Of_Birth", date);
    if (email) formData.append("email", email);
    if (imageFile) formData.append("photo", imageFile);

    

    try {
      setLoading(true);
      await axios.patch(`${process.env.REACT_APP_SERVER_URL}/user/update/${user._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
        withCredentials: true
      })
      .then((res) => {
        // console.log(res,'updated')
        if (res.status === 200) {
          setSuccessAction('/accountsetting');
          setUser(res.data.data)
          localStorage.setItem('user', JSON.stringify(res.data.data));
          setLoading(false);
          navigate("/success");
        }
      })
      .catch((err) => {
        // console.log(err);
        handleSessionError(err);
        setLoading(false);
      });
    } catch (error) {
      // console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    loading ? <Loading /> :
    <div className="topmaindivclass">
      <Navbar />
      {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
      <div className="maindivofprofiles">
        {error && (
          <div className="classnamediv">{error}</div>
        )}
        <div className="profiledatadiv">
          <h1>إعدادات الحساب</h1>

          <div className="mainflexdivphotoandcontent">
            <div className="inputprofilemaindiv">
              <form>
              <div>
                <label>الاسم الأول</label>
              </div>
              <input
                type="text"
                placeholder="بالرجاء ادخال الاسم الأول"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={submitAttempt && !firstName ? 'input-error' : ''}
              />

              <div>
                <label>الاسم الأخير</label>
              </div>
              <input
                type="text"
                placeholder="بالرجاء ادخال الاسم الأخير"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={submitAttempt && !lastName ? 'input-error' : ''}
              />

              <div>
                <label>تاريخ الميلاد</label>
              </div>
              <div className="datemaindiv">
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  min="1900-01-01"
                  max="2100-12-31"
                  placeholder="اختر التاريخ"
                  className={submitAttempt && !date ? 'input-error' : ''}
                />
              </div>

              <div>
                <label>البريد الالكتروني</label>
              </div>
              <input
                type="text"
                placeholder="بالرجاء ادخال البريد الالكتروني"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={submitAttempt && !email ? 'input-error' : ''}
              />

              <div className="profilebtndiv">
                <button onClick={handleSubmit}> حفظ </button>
              </div>
              </form>
            </div>

            <div
              className="rightprofile"
              style={{ textAlign: "center", marginTop: "50px" }}
            >
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="image-upload"
                onChange={handleImageUpload}
              />
              <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
                {image ? (
                  <img
                    src={image}
                    alt="Uploaded"
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "2px solid #ccc",
                    }}
                  />
                ) : previousimg ? (
                  <img
                    src={previousimg}
                    alt="Previous"
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "2px solid #ccc",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      background: "#29292C",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px solid #ccc",
                    }}
                  >
                    <span className="iconuser">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.2em"
                        height="1.2em"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx={12}
                          cy={6}
                          r={4}
                          fill="currentColor"
                        ></circle>
                        <path
                          fill="currentColor"
                          d="M16 12H8a4 4 0 0 0-4 4v2h16v-2a4 4 0 0 0-4-4Z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                )}
              </label>

              <div className="imageclass" style={{background:submitAttempt && !image && "red"}} onClick={handleButtonClick}>
                أضف صورة
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleNavbar />
      <Footer />
    </div>
  );
}
