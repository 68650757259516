import React, { useState, useContext } from 'react';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import './addingadmin.css';
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSessionErrorHandler } from '../../User/Managesession';
import PhoneInput from 'react-phone-input-2';
import { UserContext } from '../../../context/UserContext';

function AddingAdmin() {
  const { token, setSuccessAction, setSuccess } = useContext(UserContext);
  const navigate = useNavigate();
  const handleSessionError = useSessionErrorHandler();
  const [nmbr, setNmbr] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [ableToUploadBannerAndVideos, setAbleToUploadBannerAndVideos] = useState(false);
  const [ableToAttendLabworks, setAbleToAttendLabworks] = useState(false);
  const [ableToAttendMeetings, setAbleToAttendMeetings] = useState(false);

  const addAdmin = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/admin/updateUserToAdmin?phone_number=${nmbr}`,
        {
          phone_number: nmbr,
          jobTitle: jobTitle,
          ableToUploadBannerAndVideos: ableToUploadBannerAndVideos,
          ableToAttendLabworks: ableToAttendLabworks,
          ableToAttendMeetings: ableToAttendMeetings,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessAction('/addingmanager');
      setSuccess('تم اضافة المدير بنجاح');
      navigate('/success');
      // console.log(response);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        window.alert('الرقم غير موجود');
      }
      // console.log(err);
      handleSessionError(err);
    }
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="addingadmin-body">
        <div className="addingadmin-body-heading">إضافة مدير</div>
        <div className="addingadmin-body-number">
          <label>رقم جوال المدير</label>
          <div className="inputphonedivlogin">
            <PhoneInput
              country="sa" // Static country code set to Saudi Arabia by default
              value={nmbr}
              onChange={(e) => setNmbr(e)}
              enableSearch={true}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addAdmin();
                }
              }}
              placeholder="الرجاء ادخال رقم جوال المدير"
            />
          </div>
        </div>
        <div className="addingadmin-body-number1">
          <label>المسمى الوظيفي</label>
          <div className="inputphonedivlogin">
            <input
              type="text"
              placeholder="الرجاء ادخال المسمى الوظيفي"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="m-s-bdy">
          <div className="m-s-b-head">الاختصاص</div>
          <div className="m-s-b-body">
            <div className="m-s-b-b-row">
              <div>الفيديوهات والاعلانات الدعائية</div>
              <div>
                <input
                  type="checkbox"
                  checked={ableToUploadBannerAndVideos}
                  onChange={(e) => setAbleToUploadBannerAndVideos(e.target.checked)}
                />
              </div>
            </div>
            <div className="m-s-b-b-row">
              <div>استلام التحاليل والرد عليها</div>
              <div>
                <input
                  type="checkbox"
                  checked={ableToAttendLabworks}
                  onChange={(e) => setAbleToAttendLabworks(e.target.checked)}
                />
              </div>
            </div>
            <div className="m-s-b-b-row">
              <div>تنظيم المقابلات مع الدكتور</div>
              <div>
                <input
                  type="checkbox"
                  checked={ableToAttendMeetings}
                  onChange={(e) => setAbleToAttendMeetings(e.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="addingadmin-body-button">
          <button onClick={addAdmin}>إضافة</button>
        </div>
      </div>
      <div className="whatappicon">
        <Icon icon="ic:round-whatsapp" width="1.2em" height="1.2em" />
      </div>
      <Footer />
    </div>
  );
}

export default AddingAdmin;
