import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { Icon } from "@iconify/react/dist/iconify.js";
import WADropdownData from "../registeredForWebinar/WADropdownData";
import WebinarDetails from "../Webinar/WebinarDetails";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import { UserContext } from "../../../context/UserContext";
import Footer from "../Footer/Footer";

function WebinarSubmittedData () {
    const { id } = useParams();
    const [dropdown, setDropdown] = useState(true);
  const [loading,setLoading] = useState(false)
  const [webinar, setWebinar] = useState({});
  const [noUser,setNoUser] = useState(false)
  const navigate = useNavigate()
  const { user, token } = useContext(UserContext);
  const [register, setRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [formData, setFormData] = useState({

    gender: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    state: '',
    city: '',
    age: 0,
    profession: '',
    questions: '',
    webinarId: id,
    submitted_by: user?._id,
    service_details: 'Pay for Webinar',
  });

  const handleChange = (e) => {
    // const { name, value } = e.target;
    // setFormData({
    //   ...formData,
    //   [name]: value // Update the field that triggered the change
    // });
  };
  const getWebinarDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinar/${id}?userId=${user?._id}`);
      console.log(response);
      setWebinar(response.data.data);
      setRegister(response.data.isAvailable);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching webinar:', error);
    }
    setLoading(false)
  };
  useEffect(()=>{
    getWebinarDetails()
  },[])
function submitWebinarForm () {}
  function toDashBoard () {
    navigate('/login')
  }

  async function fetchFormData (id) {
    try{
        await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinarform/getwebinarformbyformid/${id}`).then((response)=>{
            console.log(response.data.response)
            setFormData(response.data.response)
        })
    }catch(err){

    }
  }

  useEffect(()=>{
    if(!user){
      setNoUser(true)
      return
    }
    fetchFormData(id)
  },[])

  

    return (
        loading?<Loading/>:
        <div className={`webinar-form-container ${false ? 'fade-out' : ''}`}> {/* Add fade-out class conditionally */}
        {
            noUser && <>
            <div className="deletemaindiv" style={{background:'white'}}>
                    <div className="login-res-cont">
                      <div className="deletetitle">
                        <div className='del-text'>الرجاء تسجيل الدخول</div>
    
                      </div>
                      <div className='login-res-line'></div>
    
    
                      <button onClick={toDashBoard} className='l-r-l-btn'>
    
                      التسجيل الان
                      </button>
                    </div>
                  </div>
            </>
          }
          {register ? <WebinarDetails /> : (
            <>
              <Navbar />
              <div>
                <div className="webinar-attendant-heading">
                  <div className="w-a-h-icon" onClick={() => { }}>
                    <Icon icon="weui:back-filled" />
                  </div>
                  <div className="w-a-heading-text">
                    <div className="w-a-h-t-about-webinar">
                      <div className="w-a-h-t-a-w-downup" onClick={() => setDropdown(!dropdown)}>
                        {/* {dropdown ? <Icon icon="icon-park:up" /> : <Icon icon="icon-park:down" />} */}
                      </div>
                      <div>{webinar.title}</div>
                    </div>
                    <div className="w-a-h-t-downer">
                      {webinar?.date} - {webinar.time}
                    </div>
                  </div>
                  {/* {dropdown && <WADropdownData webinar={webinar} />} */}
                </div>
                <div className="w-f-form-container">
                  <form className="w-f-form">
                    <div className="w-f-upper">
                      <div className="w-f-lab-in">
                        <label>اسم العائلة</label>
                        <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} />
                      </div>
                      <div className="w-f-lab-in">
                        <label>الاسم الأول</label>
                        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="w-f-lab-in">
                      <label>رقم الجوال</label>
                      <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                    </div>
                    <div className="w-f-lab-in">
                      <label>البريد الإلكتروني</label>
                      <input type="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="w-f-lab-in">
                      <label>الدولة</label>
                      <input type="text" name="state" value={formData.state} onChange={handleChange} />
                    </div>
                    <div className="w-f-lab-in">
                      <label>المدينة</label>
                      <input type="text" name="city" value={formData.city} onChange={handleChange} />
                    </div>
                    <div className="w-f-lab-in">
                      <label>الجنس</label>
                      <div className="w-b-c-b-p-option">
                        <div className="w-b-c-b-p-o-further">
                          <label>انثى</label>
                          <input name="gender" onChange={handleChange} checked={formData.sex === "female"} value="female" type="radio" />
                        </div>
                        <div className="w-b-c-b-p-o-further">
                          <label>ذكر</label>
                          <input name="gender" checked={formData.sex === "male"} onChange={handleChange} value="male" type="radio" />
                        </div>
                      </div>
                    </div>
                    <div className="w-f-lab-in">
                      <label>العمر</label>
                      <input type="number" name="age" value={formData.age} onChange={handleChange} />
                    </div>
                    <div className="w-f-lab-in">
                      <label>المهنة</label>
                      <input type="text" name="profession" value={formData.profession} onChange={handleChange} />
                    </div>
                    <div className="w-f-lab-in">
                      <label>الأسئلة</label>
                      <textarea rows={6} name="questions" value={formData.questions} onChange={handleChange}></textarea>
                    </div>
                  </form>
                </div>
                {/* <div className="w-f-ending-text">{'SAR'} {webinar.price}</div> */}
                <div className="w-f-ending-text" style={{color:'#48B865'}}>
                تم التسجيل بنجاح
                </div>
              </div>
              <Footer />
            </>
          )}
        </div>
      );
}

export default WebinarSubmittedData