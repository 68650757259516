import React, { useState, useRef } from 'react';
import './Verification.css';
import SimpleNavbar from '../SimpleNavbar/SimpleNavbar';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useSessionErrorHandler } from '../Managesession';

export default function PassVerification() {

  const handleSessionError = useSessionErrorHandler();
const {token} = useContext(UserContext);

  const navigate = useNavigate();
  const [otp, setOTP] = useState(['', '', '', '', '']);
  const [error, setError] = useState('');
  const axiosInstance = axios.create({
    withCredentials: true,
  });



  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const gotologin = () => {
    navigate("/login");
  }
  const submitphone = async ()=>{
    await axiosInstance.post(`${process.env.REACT_APP_SERVER_URL}/otp/sendotp`, {phone_number: localStorage.getItem('phone_number')},{
      headers: { authorization: `Bearer ${token}` },
  }).then((response) => {
        // console.log('this is response',response.data)
        if(response.data){


        }
    }).catch((err) => {
        // console.log(err)

    });


}

  const handleChange = (e, index) => {
    const value = e.target.value;

    // Ensure that only numeric characters are entered and only one character per input
    if (/^\d$/.test(value) || value === '') {
      setOTP((prevOTP) => {
        const newOTP = [...prevOTP];
        newOTP[index] = value;
        return newOTP;
      });

      // Focus on the next input box if available
      if (index < 4 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleBackspace = (e, index) => {
    // Move to the previous input box on backspace if the current box is empty
    // console.log(e)
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs[index - 1].current.focus();
    }else if (e.key !== 'Backspace' && !e.key.isNaN){
      const value = e.key;

    // Ensure that only numeric characters are entered and only one character per input
    if (/^\d$/.test(value) || value === '') {
      setOTP((prevOTP) => {
        const newOTP = [...prevOTP];
        newOTP[index] = value;
        return newOTP;
      });

      // Focus on the next input box if available
      if (index < 4 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
    }
    }
    else if(e.key === 'Backspace'){
      setOTP((prevOTP) => {
        const newOTP = [...prevOTP];
        newOTP[index] = '';
        return newOTP;
      })
    }
  };

  const handleSubmit = async () => {

    const code = otp.join('');
    if (code.length === 5) {
     await  axiosInstance.post(`${process.env.REACT_APP_SERVER_URL}/otp/verifyotp`, {
        phone_number: localStorage.getItem('phone_number'),
        otp: code,
      },{
        headers: { authorization: `Bearer ${token}` },
    }).then((response) => {
        // console.log('OTP verified', response.data);
        setError('');
        navigate('/resetchangepassward');
      }).catch((error) => {
handleSessionError(error);
        setError('خطأ في رمز التحقق');
        setOTP(["","","","",""])
        // console.error('Failed to verify OTP', error);

      });



      // Proceed with verification logic here
      // console.log('Verification code:', code);
      // Clear any previous errors

    } else {
      setError('بالرجاء ادخال رمز التحقق');
    }
  };

  return (
    <div>
      <SimpleNavbar />
      <div className="mainverificationdiv">
        <div className="mainverification">
          {error && <div className="error-message">{error}</div>}

          <h1>أدخل رمز التحقق</h1>
          <p>
            ستصلك رسالة نصية تحتوي على رمز التحقق المكون من ٥ ارقام الرجاء
            إدخال رمز التحقق الذي تلقيته
          </p>

          <div className="verificationcode">
            <h2>رمز التحقق</h2>

            <div className="verification-inputs">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`input-${index}`}
                  type="text"
                  maxLength="1"
                  value={digit}
                  ref={inputRefs[index]}
                  // onChange={(e) => handleChange(e, index)}
                  onKeyUp={(e) => handleBackspace(e, index)}
                  inputMode="numeric"
                />
              ))}
            </div>

            <div className="verifybutton">
              <button onClick={handleSubmit}>تحقق</button>
            </div>
          </div>

          <div className="verificationlastpdiv">
            <h3 onClick={submitphone} >إعادة ارسال رمز التحقق</h3>
            <h4>
              لديك حساب على الأكاديمية ؟ <span onClick={gotologin}>تسجيل دخول</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="veriffooter">
        <Footer />
      </div>
    </div>
  );
}
