import React, { useState } from 'react';
import './Splach_screen.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import Loading from '../Loading/Loading';
import { useSessionErrorHandler } from '../Managesession';

export default function Changepassword() {
  const handleSessionError = useSessionErrorHandler();
  const { setSuccess, setError,user, token,loading,setLoading,setSuccessAction } = useContext(UserContext);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [submitAttempt, setSubmitAttempt] = useState(false); // State for tracking form submission
  const navigate = useNavigate();

  const handleSave = async() => {
    // window.alert("handlealreert")
    setSubmitAttempt(true); // Mark form as submitted

    // Simple validation
    if (currentPassword && newPassword && confirmNewPassword) {
      if (newPassword === confirmNewPassword) {
        // Navigate to success screen
        setLoading(true)
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}/user/updateLogedUserPass/${user._id}`,{currentPassword:currentPassword,newPassword:newPassword,passwordConfirm:confirmNewPassword},{withCredentials:true ,
          headers: {
            authorization: `Bearer ${token}`,
          },
        }).then((res)=>{
          // console.log(res)
          if(res.status==200){
              setSuccessAction('/');
              setSuccess("reset password success");
              setLoading(false)
          navigate('/success');
            return

          }

        }).catch((err)=>{
          handleSessionError(err);
          // console.log(err)
          setLoading(false)
          setError("reset password error");
        navigate('/error');
          return

        })

      } else {
        // Navigate to error screen
        setError("reset password error");
        navigate('/error');

        return
      }
    } else {
      // Navigate to error screen
    //   navigate('/error');
    }
  };

  return (
    <div>
      <Navbar />

      <div className='splach_screenmaindiv'>
        <form>
        <h1>تغيير كلمة المرور</h1>

        <p>كلمة المرور الحالية</p>
        <input
          type="text"
          placeholder='بالرجاء ادخال كلمة المرور الحالية'
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          className={submitAttempt && !currentPassword ? 'input-error' : ''}
        />

        <p>كلمة المرور الجديدة</p>
        <input
          type="text"
          placeholder='بالرجاء ادخال كلمة المرور الجديدة'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className={submitAttempt && !newPassword ? 'input-error' : ''}
        />

        <p>إعادة كلمة المرور الجديدة</p>
        <input
          type="text"
          placeholder='بالرجاء ادخال كلمة المرور الجديدة'
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          className={submitAttempt && !confirmNewPassword ? 'input-error' : ''}
        />

        <button onClick={handleSave}>حفظ</button>
        </form>
      </div>

      <Footer />
    </div>
  );
}
