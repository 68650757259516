import React, { useContext, useState } from 'react'
import samplePic from './../../../../../../Asserts/Images/test.png'
import { ManagersContext } from '../../../ManagersManagementContext/ManagersContext'
import { useNavigate } from 'react-router-dom'
import novideo from '../../../../../../Asserts/Images/novideos.png'
import Added from './VideosComponents/Added'
import Edited from './VideosComponents/Edited'
import Deleted from './VideosComponents/Deleted'
import DeletedComments from './VideosComponents/DeletedComments'

function ManagerVideos({activityVideos} ) {
  const navigate = useNavigate()
  const [subactivity,setSubactivity] = useState('added')
  // console.log("activity",activities)
  const {activityitem} = useContext(ManagersContext)
  // const [activityVideos,setActivitVideos] = useState(activityVideos.uploadedVideos)

  const getEmbedUrl = (url) => {
    if (!url) return ""; // Early return if URL is null or undefined

    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
    );

    if (videoIdMatch && videoIdMatch[1].length === 11) {
      // Return the URL for the default YouTube video thumbnail
      return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`; // High-resolution thumbnail
    } else {
      return ""; // Return empty string if the URL is not a YouTube video
    }
  };


  return (
    <div className='managerdetails-video-container'>

<div className='managerdetails-body-buttons-container1'>
<div className={subactivity === 'added' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('added')}>الفيديوهات المضافة</button></div>
            <div className={subactivity === 'edited' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('edited')}>الفيديوهات المعدلة</button></div>
            <div className={subactivity === 'deleted' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('deleted')}>الفيديوهات المحذوفة</button></div>
            <div className={subactivity === 'deleted-comments' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('deleted-comments')}>التعليقات المحذوفة</button></div>
            </div>


      {
        subactivity==='added' && <> <Added activityVideos={activityVideos} /> </>
      }
      {
        subactivity==='edited' && <> <Edited activityVideos={activityVideos} /> </>
      }
      {
        subactivity==='deleted' && <> <Deleted activityVideos={activityVideos} /> </>
      }
      {
        subactivity==='deleted-comments' && <> <DeletedComments activityVideos={activityVideos} /> </>
      }
      {/* {
        activityVideos&& activityVideos?.length>0? activityVideos?.map((item)=>{
          return (
            <div onClick={()=>navigate(`/videos/${item._id}`)} className='managerdetails-video-box'>
        <div className='managerdetails-video-box-left'>
          <img src={getEmbedUrl(item.videoUrl)} alt="No Thumbnail" />
        </div>
        <div className='managerdetails-video-box-right'>
           <div className="managerdetails-right-titletime">
              <div className="managerdetails-right-heading">{item.title}</div>
              <div className="managerdetails-right-time">{item.duration}</div>
            </div>
          <div className='managerdetails-right-description'>{item.description}</div>
        </div>
      </div>
          )
        })
     : <div className="courcesmaindiv">
     <img src={novideo} alt="" />
   </div> } */}
    </div>
  )
}

export default ManagerVideos
