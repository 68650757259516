import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Postponed({activityMeetings}) {
    const navigate = useNavigate()
    const [videoLoading, setVideoLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [activePage,setActivePage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);

    const paginationIncrement = () => {
        if (pagination < totalPages) {
          if((pagination)%2===0){
            setActivePage(activePage+2)
          }
          setPagination((prevPagination) => prevPagination + 1);

        }
      };
      const paginationDecrement = () => {
        if (pagination > 1) {
          if((pagination-1)%2===0){
            setActivePage(activePage-2)
          }
          setPagination((prevPagination) => prevPagination - 1);
        }
      };
  return (
    <div className='whole-added'>
    <div className='added-video-elements-container'>

        {/* map here */}

{activityMeetings.postponedInterviews.length > 0 ? (
  activityMeetings.postponedInterviews.map((meeting, index) => (
    <div className='added-video-elements' key={index}>
      <div className='added-details'>
        <div className='a-d-details2'>
          <div style={{ direction: 'rtl' }}>
            {/* {'التعليقات'} - {'الفيديو'} - {'الوصف'} :تم تعديل */}
          </div>
          <div>{meeting.updated_at || 'Unknown Date'} :تاريخ التعديل</div>
        </div>
        <div className='a-d-video1'>
          <div className="notificationcardsmain">
            <div className="notificationcardleft addmeetingclass">
              <div></div>
              <div>{meeting.Meeting_Date || 'Unknown Meeting Date'}</div>
              <div>{meeting.Meeting_Hour || 'Unknown Meeting Hour'}</div>
            </div>
            <div className="notificatoncardright">
              <div className="noticardtopdiv">
                <div className="notiardtopleft">{meeting.Meeting_Req_User.phone_number || 'Unknown Phone Number'}</div>
                <div className="noticardtopright">
                  <div>{meeting.Meeting_Req_User.first_name || 'Unknown Meeting'}</div>
                  <div className="givepurplecolor">{meeting.Meeting_Day || 'Unknown Meeting Day'} {meeting.Meeting_Date || 'Unknown Date'}</div>
                </div>
              </div>
              <div className="noticardbottomdiv">
                {meeting.Meeting_description || 'No Description'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
) : (
  <div className='no-data'
  style={{padding:'70px',fontSize:'20px',backgroundColor:'white',borderRadius:'14px'}}
  >لا يوجد بيانات</div>
)}
  </div>
  <div className="pagination-container">
              {
                 totalPages===1 || <div
                className={
                  pagination === 1
                    ? "pagination-disable"
                    : "pagination-enable"
                }
                onClick={paginationDecrement}
              >
                {"<"}
              </div> }
              <div>
                <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"} onClick={paginationDecrement}>{activePage}</span>
              </div>
              {
                totalPages===1 || <div>
                <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"} onClick={paginationIncrement}>{activePage + 1}</span>
              </div>}
              {
                totalPages===1 || <div
                className={
                  pagination === totalPages
                    ? "pagination-disable"

                    : "pagination-enable"
                }
                onClick={paginationIncrement}
              >
                {">"}
              </div>}
            </div>
</div>
  )
}

export default Postponed
