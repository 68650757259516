
import React, { useContext, useEffect, useState } from 'react';
import './ThankYouPage.css';
import Navbar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';

export default function ThankYouPage() {
    useEffect(() => {
        // Initialize confetti animation
        const confetti = () => {
            const count = 200;
            const defaults = {
                origin: { y: 0.7 }
            };

            function fire(particleRatio, opts) {
                const confetti = window.confetti;
                confetti && confetti(Object.assign({}, defaults, opts, {
                    particleCount: Math.floor(count * particleRatio)
                }));
            }

            fire(0.25, {
                spread: 26,
                startVelocity: 55
            });
            fire(0.2, {
                spread: 60
            });
            fire(0.35, {
                spread: 100,
                decay: 0.91,
                scalar: 0.8
            });
            fire(0.1, {
                spread: 120,
                startVelocity: 25,
                decay: 0.92,
                scalar: 1.2
            });
            fire(0.1, {
                spread: 120,
                startVelocity: 45
            });
        };

        confetti(); // Trigger the confetti effect once the component is loaded
    }, []);
//     const navigate = useNavigate()
//     const {user} = useContext(UserContext)
//     const [noUser,setNoUser] = useState(false)
//   useEffect(()=>{
//     if(!user){
//       setNoUser(true)
//     }
//   },[])
//   function toDashBoard () {
//     navigate('/')
//   }

    return (
        <>
         <Navbar/>
         {/* {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      } */}
            <div className="thank-you-container">
            <div className="thank-you-message">
                <h1>Thank You for Purchasing The Ticket!</h1>
                <p>
                    Your payment has been successfully processed, and you’re now registered for the Event.
                    We can’t wait to see you there!
                </p>
               
                <button className="back-home-btn" onClick={() => window.location.href = '/'}>
                    Go to Homepage
                </button>
            </div>
        </div>
        </>

    );
}
