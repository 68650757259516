import React, { useState, useEffect, useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import novideo from "./../../../Asserts/Images/novideos.png";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import { useSessionErrorHandler } from "../../User/Managesession";

function VideosinVidUp({ changetab, activeButton, setChangetab, deptTitle }) {
  const handleSessionError = useSessionErrorHandler();
  const { user, token } = useContext(UserContext);
  const [courses, setCourses] = useState([]);
  const [deletepopup, setDeletepopup] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [popupIndex, setPopupIndex] = useState(null);
  const [pagination,setPagination] = useState(1)
  const [paginationcontrol,setPaginationcontrol] = useState(1)
  const [videoLoading, setVideoLoading] = useState(false);
  const navigate = useNavigate();
  const [deleteReason, setDeleteReason] = useState('');


  const [totalPages, setTotalPages] = useState(0);
  const [activePage,setActivePage] = useState(1)

  const paginationIncrement = () => {
    if (pagination < totalPages) {
      if((pagination)%2===0){
        setActivePage(activePage+2)
      }
      setPagination((prevPagination) => prevPagination + 1);
      
    }
    };

    const paginationDecrement = () => {
      if (pagination > 1) {
        if((pagination-1)%2===0){
          setActivePage(activePage-2)
        }
        setPagination((prevPagination) => prevPagination - 1);
      }
    };
    useEffect(() => {
      fetchVideos(deptTitle);
  }, [pagination])

  const showdelete = (e, id) => {
    e.stopPropagation();
    setDeletepopup(!deletepopup);
    setDeletedId(id);
  };

  const showpopup = (e, index) => {
    e.stopPropagation();
    setPopupIndex(popupIndex === index ? null : index);
  };

  const navigatesuccess = async (e, courseID) => {
    e.stopPropagation();
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/video/updateToHidden/${courseID}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      //   Set the tab and fetch videos after the status has been changed
      setChangetab("عام");
      fetchVideos(deptTitle);
      setPopupIndex(null);
    } catch (err) {
      handleSessionError(err);
      
      setPopupIndex(null);
    }
  };
  const fetchHiddenVIdeos = async (section) => {
    setVideoLoading(true);
    setChangetab("مخفي");
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_SERVER_URL
        }/video/getAllHiddenVideos?section=${"الفيديوهات"}&subSection=${section}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      setCourses(response.data.response);
      // console.log(response.data.response);
      setVideoLoading(false);
    } catch (err) {
      // console.error(err);
      handleSessionError(err);
      setVideoLoading(false);
    }
  };
  const gotouploadvideo = (e, id) => {
    e.stopPropagation();
    navigate(`/uploadvideo/${id}`);
  };
  useEffect(() => {
    if (changetab === "مخفي") {
      fetchHiddenVIdeos(deptTitle);
    } else {
      fetchVideos(deptTitle);
    }
  }, [activeButton, changetab]);
  const fetchVideos = async (subSection) => {
    setVideoLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/video/getVideosBySectionAndSubSections`, {
        params: {
          section: 'الفيديوهات',
          subSection,
          page: pagination, // Send the current page number
          limit: 10, // Set the limit per page
        },
        headers: { "authorization": `Bearer ${token}` },
      });
      setTotalPages(response.data.totalPages);
      setCourses(response.data.response);
      setVideoLoading(false);
      setPaginationcontrol(response.data.currentPage); // Update current page
      // console.log("this is teh vidoes", response.data);
    } catch (err) {
      setVideoLoading(false);
      // console.error(err);
    }
  };

  const performdeleteaction = async () => {
    try {
      setDeletepopup(false);
      setVideoLoading(true);

        await axios.patch(
          `${process.env.REACT_APP_SERVER_URL}/video/videoDisable/${deletedId}?disableBy=${user?._id}`,{disableReason:deleteReason},
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );


      fetchVideos(deptTitle);
      setVideoLoading(false);
    } catch (err) {
      setVideoLoading(false);
      handleSessionError(err);
      // console.log(err);
    }
    setPopupIndex(null)
  };

  const unhidevideo = async (e, id) => {
    e.stopPropagation();
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/video/updateFromHiddenToShow/${id}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      fetchVideos(deptTitle);
      setChangetab("عام");
      setPopupIndex(null);
    } catch (err) {
      handleSessionError(err);
      // console.log(err);
      setPopupIndex(null);
    }
  };

  const getEmbedUrl = (url) => {
    if (!url) return ""; // Early return if URL is null or undefined

    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
    );

    if (videoIdMatch && videoIdMatch[1].length === 11) {
      // Return the URL for the default YouTube video thumbnail
      return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`; // High-resolution thumbnail
    } else {
      return ""; // Return empty string if the URL is not a YouTube video
    }
  };

  return (
    <>
      {videoLoading ? (
        <div className='LoadingInFetchingBooksContainer'>
        <div className='LoadingInFetchingBooks'>
     <Icon icon="eos-icons:bubble-loading" width="1.2em" height="1.2em" />
        </div>
      </div>
      ) : (
        <>
          {deletepopup && (
            <div className="deletemaindiv">
              <div className="deletecontainer">
              <div className="deletetitle">
                <div>سبب الحذف</div>
                <div className="CloseHadafPopup" onClick={()=>setDeletepopup(false)}>x</div>
              </div>
              <textarea placeholder="مختلف عن موضوع القسم" onChange={e => setDeleteReason(e.target.value)}></textarea>                <button onClick={performdeleteaction}>
                  <Icon
                    icon="ic:baseline-delete"
                    width="1.2em"
                    height="1.2em"
                  />{" "}
                  حذف
                </button>
              </div>
            </div>
          )}

          <div className="videosmaincontierflex">
            {courses.length === 0 ? (
              <div className="courcesmaindiv">
                <img src={novideo} alt="" />
              </div>
            ) : (
              <>{
              courses.map((course, index) => (
                <div
                  key={index}
                  className="videointhedivmain"
                  onClick={() => navigate(`/videos/${course._id}`)}
                >
                  <div className="videoimageleft">
                    <div className="vidoedivshow">
                      {course.youtubeVideoUrl ? (
                        <img
                          src={getEmbedUrl(course.youtubeVideoUrl)}
                          alt="YouTube Thumbnail"
                          width="100%"
                          height="auto"
                        />
                      ) : course.videoUrl ? (
                        <video
                          src={course.videoUrl}
                          width="100%"
                          height="auto"
                        ></video>
                      ) : null}
                    </div>

                    {/* <video src={course.videoUrl}></video> */}
                  </div>
                  <div className="videonamedesright">
                    <div className="videonameandicon">
                      {user && (((user.role === "admin")&&(user.ableToUploadBannerAndVideos))||(user.role === "super-admin")) && (
                        <>
                          <div>
                            <Icon
                              onClick={(e) => showpopup(e, index)}
                              icon="bi:three-dots-vertical"
                              width="1.2em"
                              height="1.2em"
                            />
                          </div>
                          {popupIndex === index && (
                            <div className="popupmain">
                              <div
                                onClick={(e) => gotouploadvideo(e, course.id)}
                              >
                                تعديل
                              </div>
                              {course.hidden ? (
                                <div
                                  onClick={(e) => unhidevideo(e, course._id)}
                                >
                                  إظهار
                                </div>
                              ) : (
                                <div
                                  onClick={(e) =>
                                    navigatesuccess(e, course._id)
                                  }
                                >
                                  إخفاء
                                </div>
                              )}
                              <div onClick={(e) => showdelete(e, course._id)}>
                                حذف
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div>{course.title}</div>
                    </div>
                    <div className="timeofvideoup">{course.timeString}</div>
                    <div className="descrationofvideo">
                      {course.description
                        .split(" ")
                        .sort()
                        .slice(0, 50)
                        .join(" ")}
                    </div>
                  </div>
                </div>
              ))
            }
                         {changetab!=="مخفي"&&<div className="pagination-container">
                  {
                     totalPages===1 || <div
                    className={
                      pagination === 1
                        ? "pagination-disable"
                        : "pagination-enable"
                    }
                    onClick={paginationDecrement}
                  >
                    {"<"}
                  </div> }
                  <div>
                    <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"} onClick={paginationDecrement}>{activePage}</span>
                  </div>
                  {
                    totalPages===1 || <div>
                    <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"} onClick={paginationIncrement}>{activePage + 1}</span>
                  </div>}
                  {
                    totalPages===1 || <div
                    className={
                      pagination === totalPages
                        ? "pagination-disable"
                        
                        : "pagination-enable"
                    }
                    onClick={paginationIncrement}
                  >
                    {">"}
                  </div>}
                </div>}
            </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default VideosinVidUp;
