import React, { useContext, useEffect, useState } from "react";
import "./Signup.css";
import SimpleNavbar from "../SimpleNavbar/SimpleNavbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import heic2any from "heic2any"; // Import the library for HEIC conversion
import { UserContext } from "../../../context/UserContext";
import { useSessionErrorHandler } from "../Managesession";
import { loadIcons } from "@iconify/react/dist/iconify.js";
import Loading from "../Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react/dist/iconify.js";


export default function Signupform() {

  const handleSessionError = useSessionErrorHandler();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender,setGender] = useState('')
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading,setLoading] = useState(false)
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null); // New state for image file
  const [error, setError] = useState("");
  const [submitAttempt,setSubmitAttempt]  = useState()
  const{user,setUser,setToken,token,verification} = useContext(UserContext)
const axiosInstace = axios.create({
  withCredentials: true,
});
const [number, setNumber] = useState('')
const handleGenderChange = (e) => {
  setGender(e.target.value);
  // console.log('gender',e.target.value)
};

const [showPassword,setShowPassword] = useState(true)

const navigate = useNavigate();

useEffect(()=>{
  // console.log('verv',verification)
  if(user)
  {
    navigate('/')
  }
},[])

  const [date, setDate] = useState('');

  const handleDateChange = (e) => {
    const selected_date = e.target.value;
    setDate(selected_date)
    if (selected_date) {
      const dateObj = new Date(selected_date);

      setDay(dateObj.getDate());
      setMonth(dateObj.getMonth() + 1); // Months are 0-indexed in JavaScript
      setYear(dateObj.getFullYear());
    } else {
      setDay('');
      setMonth('');
      setYear('');
    }
  }


  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        let processedFile = file;

        // Convert HEIC to JPG if the file is in HEIC format
        if (file.type === "image/heic" || file.type === "image/heif") {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
          });
          processedFile = new File([convertedBlob], `${file.name}.jpg`, {
            type: "image/jpeg",
          });
        }

        setImageFile(processedFile);

        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(processedFile);
      } catch (error) {
        // console.error("Error converting HEIC image:", error);
        setError("Failed to process image.");
        setTimeout(() => setError(""), 30000);
      }
    }
  };


  const handleButtonClick = () => {
    document.getElementById("image-upload").click();
  };

  const gotologin = () => {
    navigate("/login");
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  
  // Function to validate names (no numeric values allowed)
  function isValidName(name) {
    const nameRegex = /^[a-zA-Z]+$/;
    return nameRegex.test(name);
  }

  const handleSubmit = async () => {
    setSubmitAttempt(true);
    setLoading(true)
    if(!gender){
      setError('بالرجاء تحديد الجنس')
      setTimeout(() => {
        setError("");
      }, 30000); // 30 seconds
      setLoading(false)
      return;
    }
    if (
      !firstName ||
      !lastName ||
      !date ||
      !email ||
      !password
    ) {
      setError(" بيانات غير صحيحة ");
      setTimeout(() => {
        setError("");
      }, 30000); // 30 seconds
      setLoading(false)
      return;
    }
    const phone_number = localStorage.getItem("phone_number");

    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("Date_Of_Birth", date);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("role", "user");
    // phone_number for production
    formData.append("phone_number", phone_number);
    formData.append('gender',gender)


    if (imageFile) {
      // console.log('imagefile',imageFile)
      formData.append("photo", imageFile);
    }
    // if(!isValidEmail(email) || !isValidName(firstName) || !isValidName(lastName)){
    //   setError(" بيانات غية ");
    //   // console.log('error in valid email');
    //   setLoading(false)
    //   return ;
    // }
    try {
      // console.log('form hood',formData);
      await axiosInstace
        .post(`${process.env.REACT_APP_SERVER_URL}/user/signup`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
             'authorization': `Bearer ${token}`

          },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            localStorage.removeItem("phone_number")
            setUser(res.data.data);
            localStorage.setItem('user', JSON.stringify(res.data.data));
            localStorage.setItem('token', res.data.token);
            setToken(res.data.token)
            navigate("/login");
            setLoading(false)
          }
          setLoading(false)
        })
        .catch((err) => {
          handleSessionError(err)
          setLoading(false)
        });
        setLoading(false)
    } catch (error) {
      console.error("Error:", error);
      handleSessionError(error);
      setLoading(false)
    }
    setLoading(false)
  };

  return (
    loading ? <Loading/> :
    <div>
      <SimpleNavbar />
      <div className="maindivofprofile">
        {error && (
          <>
            <div className="classnamediv1">{error}</div>
          </>
        )}
        <div className="profiledatadiv">
          <h1>إنشاء حساب</h1>

          <div className="mainflexdivphotoandcontent">
            <div className="inputprofilemaindiv1">
              <div>
                <label>الاسم الأول</label>
              </div>
              <div className="signup-input">
              <input
                type="text"
                placeholder="بالرجاء ادخال الاسم الأول"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={submitAttempt && !firstName ? 'input-error' : ''}
              />
              </div>

              <div>
                <label>الاسم الأخير</label>
              </div>
              <div className="signup-input">
              <input
                type="text"
                placeholder="بالرجاء ادخال الاسم الأخير"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={submitAttempt && !lastName ? 'input-error' : ''}
              />
              </div>

              <div>
                <label>تاريخ الميلاد</label>
              </div>
              <div className="datemaindiv signup-input">
                <input
                  type="date"
                  value={date}
                  onChange={handleDateChange}
                  min="1900-01-01"
                  max="2100-12-31"
                  placeholder="اختر التاريخ"
                  className={submitAttempt && !date ? 'input-error' : ''}
                />
              </div>

              <div>
                <label>الجنس</label>
              </div>
              <div className="radio-gender">
                <div className="radio-inputs">
                  <div className="radio-field">
                  انثى
                  <input
                  type="radio"
                  value="female"
              checked={gender === 'female'}
                  onChange={handleGenderChange}
                />
                  </div>
                  <div className="radio-field">
                  ذكر
                  <input
                  type="radio"
                  value="male"
              checked={gender === 'male'}
                  onChange={handleGenderChange}
                />
                  </div>
                </div>
              </div>

              <div>
                <label>البريد الالكتروني</label>
              </div>
              <div className="signup-input">
              <input
                type="email"
                placeholder="بالرجاء ادخال البريد الالكتروني"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={submitAttempt && !email ? 'input-error' : ''}
              />
              </div>

              <div>
                <label>كلمة المرور</label>
              </div>
              <div className="signup-input">
              {/* <input
                type="password"
                placeholder="بالرجاء ادخال كلمة المرور"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
                className={submitAttempt && !password ? 'input-error' : ''}
              /> */}
              <div className="password-container">
                                    <input
                                    value={password}
                                        type={showPassword ? "text" : "password"}
                                        placeholder='الرجاء كتابة كلمة المرور'
                                        onChange={(e) => setPassword(e.target.value)}
                                        required={true}
                                        className={submitAttempt && !password ? 'input-error' : ''}
                                    />
                                    <button className="toggle-password" onClick={()=>{setShowPassword(!showPassword)}}>
                                        {/* <FontAwesomeIcon icon={showPassword ? "fa-light fa-eye" : 'faEyeSlash'} /> */}
                                        <Icon icon={showPassword ? "mdi:eye-off-outline" : "ph:eye" } width="16" height="16" />

                                    </button>
                                </div>
              </div>

              <div className="profilebtndiv">
                <button onClick={handleSubmit}>انشاء حساب</button>
                <h3>
                  لديك حساب على الأكاديمية ؟{" "}
                  <span onClick={gotologin}>تسجيل دخول</span>
                </h3>
              </div>
            </div>

            <div
              className="rightprofile"
              style={{ textAlign: "center", marginTop: "50px" }}
            >
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="image-upload"
                onChange={handleImageUpload}
              />
              <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
                {image ? (
                  <img
                    src={image}
                    alt="Uploaded"
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "2px solid #ccc",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      background: "#29292C",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px solid #ccc",
                    }}
                  >
                    <span className="iconuser">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.2em"
                        height="1.2em"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx={12}
                          cy={6}
                          r={4}
                          fill="currentColor"
                        ></circle>
                        <path
                          fill="currentColor"
                          d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5"
                        ></path>
                      </svg>
                    </span>
                  </div>
                )}
              </label>
              <div className="imageclass" onClick={handleButtonClick}>
                أضف صورة
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="profilefootermaindiv">
        <Footer />
      </div>
    </div>
  );
}
