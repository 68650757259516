import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import BranchLoading from '../../branchLoading/BranchLoading';
import nobook from '../../../../Asserts/Images/nobook.png'

function DashboardEvents() {
    const [pagination, setPagination] = useState(1);
    const {user} = useContext(UserContext)
    const [activePage, setActivePage] = useState(1);
    const navigate = useNavigate()
  const [webinars, setWebinars] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading,setLoading] = useState(false)

  const fetchWebinars = async () => {
    setLoading(true)
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinar/getall`);
        setWebinars(response.data.data);
    } catch (error) {
        console.error('Error fetching webinars:', error);
    }
    setLoading(false)
};
useEffect(()=>{
    fetchWebinars();
},[])
    const paginationIncrement = () => {
        if (pagination < totalPages) {
          if (pagination % 2 === 0) {
            setActivePage(activePage + 2);
          }
          setPagination((prevPagination) => prevPagination + 1);
        }
      };

      const paginationDecrement = () => {
        if (pagination > 1) {
          if ((pagination - 1) % 2 === 0) {
            setActivePage(activePage - 2);
          }
          setPagination((prevPagination) => prevPagination - 1);
        }
      };

      function toEventDetail (id) {
        if(!user){
          alert('يجب تسجيل الدخول أولا')
          navigate('/login')
          return
        }
        if(user?.role === 'admin'|| user?.role === 'super-admin'){
        navigate(`/webinarattendants/${id}`)
        }
        else{
          navigate(`/webinarform/${id}`)
        }
      }

  return (
    loading ? <BranchLoading/> :
    <div className="webinars-outer" style={{backgroundColor:'white'}}>
    <div className="webinars-inner">
      {/* map here */}
      {
              webinars.length === 0 &&
              <div className="nobooksmaindiv">
            <img src={nobook} alt="" />
            آسف لا توجد أحداث حتى الآن
          </div>
            }
      {webinars.map((webinar) => {
        return (
          <div className="w-i-box" key={webinar.id} onClick={() => toEventDetail(webinar._id)}>
            <div className="w-i-b-img">
              <img src={webinar.picture} alt="no Picture" />
            </div>
            <div className="w-i-title-things">
              <div className="w-i-tt-title">{webinar.title}</div>
              <div className="w-i-tt-time">{webinar.date}</div>
            </div>
            <div className="w-i-price">سجل الآن</div>
            <div className="w-i-price">{webinar.presenter}</div>
          </div>
        );
      })}
    </div>
    <div className="pagination-container">
      {totalPages === 1 || (
        <div
          className={
            pagination === 1 ? "pagination-disable" : "pagination-enable"
          }
          onClick={paginationDecrement}
        >
          {"<"}
        </div>
      )}
      <div>
        <span
          className={
            pagination === activePage
              ? "pagination-active"
              : "pagination-notactive"
          }
          onClick={paginationDecrement}>
          {activePage}
        </span>
      </div>
      {totalPages === 1 || (
        <div>
          <span
            className={
              pagination === activePage + 1
                ? "pagination-active"
                : "pagination-notactive"
            }
            onClick={paginationIncrement}>
            {activePage + 1}
          </span>
        </div>
      )}
      {totalPages === 1 || (
        <div
          className={
            pagination === totalPages
              ? "pagination-disable"
              : "pagination-enable"
          }
          onClick={paginationIncrement}
        >
          {">"}
        </div>
      )}
    </div>
  </div>
  )
}

export default DashboardEvents
