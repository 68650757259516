import React, { useState, useEffect, useContext } from 'react';
import './totaluser.css';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import dummyProfile from './../../../Asserts/Images/DummyProfile.png';
import Pagination from './../Pagination/Pagination';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { useSessionErrorHandler } from '../../User/Managesession';
import BranchLoading from '../../User/branchLoading/BranchLoading';

function TotalUser() {
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const {token} = useContext(UserContext)
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage,setCurrentPage] = useState(1)
    const [totalUsers,setTotalUsers] = useState(0)
   const [loading,setLoading] = useState(false)
   const handleSessionError = useSessionErrorHandler()
   const navigate = useNavigate()
    const limit = 10; // Items per page


    useEffect(() => {
        if (!search) {
            fetchUsers(); // Fetch users if no search query
        }
    }, [activePage]);

    const fetchUsers = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/usermanagement/getallusers`, {
                params: {
                    page: activePage,
                    limit: limit
                }
            ,
                headers: {
                    authorization: `Bearer ${token}`
                }

            });
            // console.log(response)
            setUsers(response.data.response);
            setTotalPages(response.data.totalPages);
            setLoading(false)
            setTotalUsers(response.data.totalUsers)
        } catch (error) {
            setLoading(false)
            handleSessionError(error)

            // console.error('Error fetching users:', error);
        }
    };

    const fetchSearchResults = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/usermanagement/searchusers`, {
                params: {
                    query: search,
                    page: activePage,  // Include page for pagination
                    limit: limit       // Include limit for pagination
                },
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            // console.log(response)
            setUsers(response.data.response); // Set search results
            setTotalPages(response.data.totalPages); // Update total pages
            setLoading(false)
        } catch (error) {
            setLoading(false)
            handleSessionError(error)
            // console.error('Error searching users:', error);
        }
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    };
    useEffect(() => {
        if (search.trim()) {
            setActivePage(1); // Reset to first page when searching
            fetchSearchResults(); // Fetch search results
        } else {
            fetchUsers(); // Fetch all users if search is cleared
        }
    }, [search]);
    const handleSearch = () => {
        if (search.trim()) {
            setActivePage(1); // Reset to first page when searching
            fetchSearchResults(); // Fetch search results
        } else {
            fetchUsers(); // Fetch all users if search is cleared
        }
    };

    const increment = () => {
        if (activePage < totalPages) {
            if(activePage%2===0)
            {
                setCurrentPage(currentPage+2)
            }
            setActivePage(activePage + 1);
        }
    };

    const decrement = () => {
        if (activePage > 1) {
            if((activePage-1)%2===0){
                setCurrentPage(currentPage-2)
            }
            setActivePage(activePage - 1);
        }
    };

    return (
        <div className='allusers-container'>
            <div><Navbar /></div>
            <div className='au-main'>
                <div className='au-heading'>{totalUsers} عدد المستخدمين</div>
                <div className='au-search-container'>
                    <div className='au-search-cont2'>
                        <div className='au-s-input'>
                            <input
                                type='text'
                                placeholder='البحث بالاسم أو برقم الجوال'
                                value={search}
                                onChange={onSearch}
                            />
                        </div>
                        <div onClick={handleSearch}>
                            <Icon icon="ri:search-line" />
                        </div>
                    </div>
                </div>
                <div className='au-users-container'>
                    {loading? <BranchLoading/> : <div className='au-u-cont2'>
                        {users.length>0? users.map((item) => {

                        return(
                            <div className='au-u-box' onClick={()=>navigate(`/userInfo/${item._id}`)}  key={item._id}>
                                <div className='au-u-data'>
                                    <div className='au-u-b-name'>{item.first_name+" "+ item.last_name}</div>
                                    <div>{item.phone_number}</div>
                                </div>
                                {<> {item.photo? <div className='au-u-b-img'>
                                    <img src={item.photo} alt="Picture" />
                                </div> : <div className='au-u-b-img'>
                                    <img src={dummyProfile} alt="Picture" />
                                </div> }</>}
                            </div>
                        )
                            
}):"No user Found"}
                    </div>}
                    <div>
                        <Pagination
                            increment={increment}
                            decrement={decrement}
                            activePage={activePage}
                            totalPages={totalPages}
                            currentPage={currentPage}
                        />
                    </div>
                </div>
            </div>
            <div><Footer /></div>
        </div>
    );
}

export default TotalUser;
