import React, { useState, useEffect, useContext } from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import './Resetpas.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import SimpleNavbar from '../SimpleNavbar/SimpleNavbar';
import axios from 'axios'; // Import Axios for making HTTP requests
import { UserContext } from '../../../context/UserContext';
import Loading from '../Loading/Loading';
import { useNavigate } from 'react-router-dom';

function Resetpas() {
  const {user,setLoading,loading} = useContext(UserContext)
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate  = useNavigate()
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [countryCode, setCountryCode] = useState('sa'); // State to store user's country code

  useEffect(() => {
    // Function to fetch country code based on user location
    const fetchCountryCode = async (latitude, longitude) => {
      try {
        // Use a geolocation API to get the country from latitude and longitude
        const response = await axios.get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        const countryCode = response.data.countryCode.toLowerCase();
        setCountryCode(countryCode);
      } catch (error) {
        // console.error('Error fetching country code', error);
      }
    };

    // Get user location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchCountryCode(latitude, longitude);
        },
        (error) => {
          // console.error('Error getting location', error)
        }
      );
    }
  }, []);

  const handleSubmit = async () => {

    if (!phoneNumber) {
      alert('برجاء ادخال رقم الهاتف');
      return;
    }

    try {
      setLoading(true)
       await axios.post(`${process.env.REACT_APP_SERVER_URL}/otp/resetpassotp`, {
        phone_number: phoneNumber
      }).then((res)=>{

        // console.log(res.data)
        if(res.data.status==="success"){
          localStorage.setItem('phone_number',phoneNumber)

          navigate('/passverification')
        setLoading(false)

        return
        }
      }).catch((err)=>{
        setLoading(false)
        // console.log(err)
        return

      })

      // Optionally, you can redirect or show a success message here
    } catch (error) {

      // console.error('Error submitting reset password request:', error);
      setLoading(false)
      return
      // Handle error scenarios (e.g., show error message to the user)
    }
  };

  return (
    loading ? <Loading/>:
    <>
      <div className="resetpaswardmain">
        <div className="resetpaswardnavbar">
          {/* <Navbar /> */}
          <SimpleNavbar/>
        </div>

        <div className="resetpaswardcontainer">
          <div className="respaswardmain">
            <div>بالرجاء ادخال رقم الجوال الذي قمت بالتسجيل به</div>
            <div>رقم الجوال</div>
            <div className='inputphonedivlogin'>
              <PhoneInput
                country={countryCode || 'sa'} // Default to Saudi Arabia if country code is not fetched
                placeholder='بالرجاء ادخال رقم الجوال '
                value={phoneNumber}
                onChange={setPhoneNumber}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                containerClass={submitAttempt && !phoneNumber ? 'input-error' : ''}
              />
            </div>
            <div onClick={handleSubmit} >طلب تغيير كلمة المرور</div>
          </div>
        </div>

        <div className="resetpaswardfooter">
          <Footer />
        </div>
      </div>

    </>
  );
}

export default Resetpas;
