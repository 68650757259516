import React, { Children, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../context/UserContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSessionErrorHandler } from '../../../User/Managesession'

function NoLabworks({children}) {
    const {user,setUser,token} = useContext(UserContext)
    const navigate = useNavigate()
   
    function goToDashboard () {
        navigate('/')
    }
    if(user?.role==='admin' && (!user?.ableToAttendLabworks))
    {
        return (
            <div className="m-d-c-p-container">
              <div
                className="m-d-bg-black-shadow"
                onClick={goToDashboard}
              ></div>
              <div className="m-d-cancel-popup">
                <div className="m-d-c-p-ttext">
                لا يسمح لك بالأعمال المعملية
                </div>
                <div className="m-d-c-p-btn">
                  <button onClick={goToDashboard}>نعم</button>
                </div>
              </div>
            </div>
          )
    }
    else{
        return children
    }
  
}

export default NoLabworks
