import React, { useContext, useState } from 'react'
import samplePic from './../../../../../../Asserts/Images/test.png'
import { ManagersContext } from '../../../ManagersManagementContext/ManagersContext'
import { useNavigate } from 'react-router-dom'

function ManagerLatestModifications(activityitem) {
  // console.log("activity modification",activityitem)
  const navigate = useNavigate()
  const [activityLatestMods, setActivityLatestMods] = useState(activityitem?.activityVideos)

  const getEmbedUrl = (url) => {
    if (!url) return ""; // Early return if URL is null or undefined

    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
    );

    if (videoIdMatch && videoIdMatch[1].length === 11) {
      // Return the URL for the default YouTube video thumbnail
      return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`; // High-resolution thumbnail
    } else {
      return ""; // Return empty string if the URL is not a YouTube video
    }
  };
  return (
    <div className='managerdetails-modifications-container'>
      {
        activityLatestMods?.length>0? activityLatestMods.map((item)=>{
          return(
            <div onClick={()=>navigate(`/videos/${item._id}`)} className='managerdetails-modification-box'>
        <div className='managerdetails-modifications-box-upper'>
          <div className='managerdetails-modifications-upper-tareekh'>{item.modificationDate} : تاريخ التعديل</div>
          {/* <div className='managerdetails-modifications-upper-changes'>{item.mods} : تم تعديل</div> */}
        </div>
        <div className='managerdetails-modifications-box-lower'>
          <div className='managerdetails-video-box'>
        <div className='managerdetails-video-box-left'>
          <img src={getEmbedUrl(item.videoUrl)} alt="No Thumbnail" />
        </div>
        <div className='managerdetails-video-box-right'>
        <div className="managerdetails-right-titletime">
              <div className="managerdetails-right-heading">{item.title}</div>
              {/* <div className="managerdetails-right-time">{item.duration}</div> */}
            </div>
          <div className='managerdetails-right-description'>{item.description}</div>
        </div>
      </div></div>
      </div>
          )
        })
      :"No Activities yet..."}

    </div>
  )
}

export default ManagerLatestModifications
