import React, { useContext, useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import WADropdownData from "../registeredForWebinar/WADropdownData";
import './webinarform.css';
import WebinarDetails from "../Webinar/WebinarDetails";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import Loading from "../Loading/Loading";

function WebinarForm() {
  const { id } = useParams();
  const { user, token } = useContext(UserContext);
  const [webinar, setWebinar] = useState({});
  const [dropdown, setDropdown] = useState(true);
  const [loading,setLoading] = useState(false)
  const [formData, setFormData] = useState({

    gender: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    state: '',
    city: '',
    age: 0,
    profession: '',
    questions: '',
    webinarId: id,
    submitted_by: user?._id,
    service_details: 'Pay for Webinar',
  });
  const [register, setRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  // const [fadeOut, setFadeOut] = useState(false); // Fade-out state

  const getWebinarDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinar/${id}?userId=${user?._id}`);
      console.log(response);
      setWebinar(response.data.data);
      setRegister(response.data.isAvailable);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching webinar:', error);
    }
  };

  const [noUser,setNoUser] = useState(false)
  const navigate = useNavigate()

  function toDashBoard () {
    navigate('/login')
  }
  useEffect(()=>{
    if(!user){
      setNoUser(true)
      return
    }
  },[])

  const submitWebinarForm = async () => {

    if(formData.age < 10 || formData.age > 100){
      alert('Age is not  valid');
      return;
    }
    // setIsLoading(true); // Set loading to true
    // setFadeOut(false); // Reset fade-out state

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/webinar/registerWebinar`, formData, {
        headers: {
          authorization: `Bearer ${token}`,
        }
      });
      console.log(response);
      if (response.status === 200) {
        // setFadeOut(true); // Start fade-out
        getWebinarDetails()
        // setTimeout(() => {
        //   window.location.href = response.data.redirect_url; // Redirect after fade-out
        // }, 500); // Delay for fade-out animation (adjust time as needed)
      }
    } catch (error) {
      if (error.response?.status) {
        alert('Please fill all the fields correctly');
      }
      console.error('Error fetching webinar:', error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  useEffect(() => {
    // getWebinarDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value // Update the field that triggered the change
    });
  };

  return (
    loading?<Loading/>:
    <div className={`webinar-form-container ${false ? 'fade-out' : ''}`}> {/* Add fade-out class conditionally */}
    {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
      {register ? <WebinarDetails /> : (
        <>
          <Navbar />
          <div>
            <div className="webinar-attendant-heading">
              <div className="w-a-h-icon" onClick={() => { }}>
                <Icon icon="weui:back-filled" />
              </div>
              <div className="w-a-heading-text">
                <div className="w-a-h-t-about-webinar">
                  <div className="w-a-h-t-a-w-downup" onClick={() => setDropdown(!dropdown)}>
                    {dropdown ? <Icon icon="icon-park:up" /> : <Icon icon="icon-park:down" />}
                  </div>
                  <div>{webinar.title}</div>
                </div>
                <div className="w-a-h-t-downer">
                  {webinar?.date} - {webinar.time}
                </div>
              </div>
              {dropdown && <WADropdownData webinar={webinar} />}
            </div>
            <div className="w-f-form-container">
              <form className="w-f-form">
                <div className="w-f-upper">
                  <div className="w-f-lab-in">
                    <label>اسم العائلة</label>
                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                  </div>
                  <div className="w-f-lab-in">
                    <label>الاسم الأول</label>
                    <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                  </div>
                </div>
                <div className="w-f-lab-in">
                  <label>رقم الجوال</label>
                  <input type="text" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} />
                </div>
                <div className="w-f-lab-in">
                  <label>البريد الإلكتروني</label>
                  <input type="email" name="email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="w-f-lab-in">
                  <label>الدولة</label>
                  <input type="text" name="state" value={formData.state} onChange={handleChange} />
                </div>
                <div className="w-f-lab-in">
                  <label>المدينة</label>
                  <input type="text" name="city" value={formData.city} onChange={handleChange} />
                </div>
                <div className="w-f-lab-in">
                  <label>الجنس</label>
                  <div className="w-b-c-b-p-option">
                    <div className="w-b-c-b-p-o-further">
                      <label>انثى</label>
                      <input name="gender" onChange={handleChange} checked={formData.gender === "female"} value="female" type="radio" />
                    </div>
                    <div className="w-b-c-b-p-o-further">
                      <label>ذكر</label>
                      <input name="gender" checked={formData.gender === "male"} onChange={handleChange} value="male" type="radio" />
                    </div>
                  </div>
                </div>
                <div className="w-f-lab-in">
                  <label>العمر</label>
                  <input type="number" name="age" value={formData.age} onChange={handleChange} />
                </div>
                <div className="w-f-lab-in">
                  <label>المهنة</label>
                  <input type="text" name="profession" value={formData.profession} onChange={handleChange} />
                </div>
                <div className="w-f-lab-in">
                  <label>الأسئلة</label>
                  <textarea rows={6} name="questions" value={formData.questions} onChange={handleChange}></textarea>
                </div>
              </form>
            </div>
            {/* <div className="w-f-ending-text">{'SAR'} {webinar.price}</div> */}
            <div className="w-f-ending-text">
              <button onClick={submitWebinarForm} disabled={isLoading}>
                {isLoading ? 'جاري المعالجة...' : 'تسليم النموذج'}
              </button>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default WebinarForm;
