import React, { useState, useRef, useContext, useEffect } from "react";
import "./Collowabies.css";
import Navbar from "../../User/Navbar/Navbar";
import Footer from "../../User/Footer/Footer";
import images from "../../../Asserts/Images/test.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";

import Chatbox from "../../User/Bloodimageanalysis/Chatbox";
import Loading from "../../User/Loading/Loading";
import heic2any from "heic2any";
import { useSessionErrorHandler } from "../../User/Managesession";
import { Pagination } from "swiper/modules";

export default function  Collywobbles() {
  const { requestId } = useParams();

  const [isFullScreenPatient,setIsFullScreenPatient] = useState(null)
  function patientPics (index) {
    if(isFullScreenPatient === index)
      {
        setIsFullScreenPatient(null)
      }
      else{
      setIsFullScreenPatient(index);
      }
  }

  const [isFullScreen, setIsFullScreen] = useState(null);
  const toggleFullScreen = (index) => {
    if(isFullScreen === index)
    {
      setIsFullScreen(null)
    }
    else{
    setIsFullScreen(index);
    }
  };
function None () {
  setIsFullScreen(null)
  setIsFullScreenPatient(null)
}
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    initialSlide: isFullScreen
  };

  const handleSessionError = useSessionErrorHandler();

  const [errorMessage, setErrorMessage] = useState("");

  const { activeButtontest, setActiveButtontest ,token} = useContext(UserContext);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [delpopup, setDelpopup] = useState(false);
  const fileInputRef = useRef(null);
  const [patientRequest, setPatientRequest] = useState({});
  const { user, loading, setLoading } = useContext(UserContext);
  const [status, setStatus] = useState("");


  const downloadFileFromS3 = async (fileKey, index) => {
    try {
      // Fetch the signed URL from the backend
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/s3/downloadpdf?fileKey=${encodeURIComponent(fileKey)}`, {
        headers: {
          authorization: `Bearer ${token}`, // Replace with your auth token logic
        },
      });

      if (!response.ok) throw new Error('Failed to fetch signed URL');

      const { downloadUrl } = await response.json();

      // Fetch the file from the download URL as a blob
      const fileResponse = await fetch(downloadUrl);
      if (!fileResponse.ok) throw new Error('Failed to download the file');

      const blob = await fileResponse.blob();
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `document_${index + 1}`); // Customize the filename
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up the DOM

      // Revoke the object URL to release memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // console.error('Error downloading file:', error);
    }
  };




  const downloadFiles = async () => {
    if (patientRequest.request_documents && patientRequest.request_documents.length > 0) {
      // console.log('sdf',patientRequest)
      for (const [index, fileUrl] of patientRequest.request_documents.entries()) {
        // Extract the S3 file key from the file URL
        const fileKey = fileUrl.split('.com/')[1]; // Assuming the URL is in the format https://bucket-name.s3.region.amazonaws.com/folderName/filename

        // Call the function to handle each download
        await downloadFileFromS3(fileKey, index);
      }
    } else {
      alert('No files to download.');
    }
  };


  const getRequestbYid = async (id) => {
    try {
      setLoading(true);
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/patientrequests/${id}`,{headers : {authorization : `Bearer ${token}`}})
        .then((res) => {
          // console.log("request", res.data.patientRequest);
          setPatientRequest(res.data.patientRequest);
          setStatus(res.data.patientRequest.request_status);

          setLoading(false);
        });
    } catch (err) {
      // console.log(err);
      handleSessionError(err)
      setLoading(false);
    }
  };
  useEffect(() => {
    getRequestbYid(requestId);
  }, []);

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 10) {
      setErrorMessage("You can only upload up to 10 files.");
    } else {
      setErrorMessage("");
      const convertedFiles = [];
      for (const file of files) {
        if (file.type === "image/heic" || file.type === "image/HEIC") {
          try {
            const convertedBlob = await heic2any({
              blob: file,
              toType: "image/jpeg",
            });
            const convertedFile = new File([convertedBlob], file.name.replace(/\.heic|\.HEIC/i, ".jpg"), {
              type: "image/jpeg",
            });
            convertedFiles.push(convertedFile);
          } catch (err) {
            // console.error("Error converting HEIC to JPG:", err);
          }
        } else {
          convertedFiles.push(file);
        }
      }
      setSelectedFiles(convertedFiles);
      // console.log("Selected files:", convertedFiles);
    }
  };
  const uploadAdminReplayedPictures = async () => {
    const newFormData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      newFormData.append("files", selectedFiles[i]);
    }
    // console.log("selectedFiles", selectedFiles);

    try {
      await axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/patientrequests/updatereplayedDocumenst/${requestId}`,
          newFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              'authorization' : `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          // console.log(response);
          setSelectedFiles([])
          getRequestbYid(requestId)
        })
        .catch((err) => {
          // console.log(err);
          handleSessionError(err);
        });
    } catch (err) {
      // console.log(err);
    }
  };
  const navigate = useNavigate();

  const goback = () => {
    navigate(-1);
  };

  const buttonStyles = {
    مفتوحة: { backgroundColor: "#2F7BD5", color: "white" },
    مغلقة: { backgroundColor: "#993333", color: "white" },
    "قيد المعالجة": { backgroundColor: "#F1DA37", color: "white" },
  };

  const showpopupclose = () => {
    setDelpopup(!delpopup);
  };

  const closechatfun = () => {
    alert("meeting close");
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Navbar />

      {delpopup && (
        <>
          <div className="delpopupmain">
            <div className="delpopupcontainer">
              <div className="titldivup">
                هل أنت متأكد انك تريد إغلاق التحليل
              </div>
              <div className="buttonsdivtwo">
                <button onClick={showpopupclose}>يلغي</button>
                <button onClick={closechatfun}>نعم</button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="maindivofcollywobbles">
        <div className="collywobblesleftsideandheading">
          <div className="leftarrow" onClick={goback}>
            <Icon className="leftarrowicon" icon="mdi:keyboard-arrow-left" />
          </div>
          <div className="collywobblestextdiv">
            <h1>{patientRequest.request_title}</h1>
          </div>
        </div>

        <div className="anlysisbtndiv">
          <button> {patientRequest?.request_status}:تحليل</button>
        </div>
        <div className="anlysisbtndiv">
          <button> {patientRequest?.request_type}:يكتب
</button>
        </div>

        <div className="personalinfodiv">
          <h1>المعلومات الشخصية</h1>

          <p>
            {" "}
            <span>
              {" "}
              {patientRequest.requested_by &&
                patientRequest.requested_by.first_name +
                  " " +
                  patientRequest.requested_by.last_name}
            </span>{" "}
            : الاسم
          </p>
          {
          isFullScreenPatient !== null ? <div className="full-screen-image" onClick={()=>None()} ><Icon icon="ic:outline-close" /></div>  : <></>
        }
          <p>
            قم الهاتف : <span> {patientRequest.patient_phone} </span>{" "}
          </p>

          <h1> الملفات المرفقة</h1>


          <div className="imagesshowdivcolly">
            {patientRequest.request_documents &&
              patientRequest.request_documents.map((file, index) => (
                <div>
                <img
                  key={index}
                  src={file}
                  alt={`Document ${index + 1}`}
                  onClick={()=>patientPics(index)}
                /></div>
              ))}
          </div>
        </div>

        <div className="collybtndiv" onClick={downloadFiles}>
          <button>تحميل المفات</button>
        </div>
        {
          isFullScreen !== null ? <div className="full-screen-image" onClick={()=>None()} ><Icon icon="ic:outline-close" /></div>  : <></>
        }

        <div className="collyinputdiv">
          <h6>الوصف</h6>

          <div className="placholdertextshow">
            {patientRequest.request_title}
          </div>


        </div>
        <div className="collyinputdiv"><h6></h6>
        </div>
        <div className="collyinputdiv"><h6>التقارير والنظام الغذائي</h6>
        </div>
        <div className="imagePreviewContainer">
          {selectedFiles.map((file, index) => (
            <div key={index} className="imagePreview">
              <img src={URL.createObjectURL(file)} alt={`preview-${index}`} />
            </div>
          ))}
        </div>


        <div className={isFullScreen !== null ? 'imagePreviewContainer' : 'imagePreviewContainer' }>
          {patientRequest.replayed_documents &&
          patientRequest.replayed_documents.length >= 0
            ? patientRequest.replayed_documents.map((file, index) => (
                <div key={index} className="imagePreview">
                  <img
                    src={file}
                    className={ isFullScreen === index ? 'full-screen-image1' : 'regular-image'}
                    onClick={()=>toggleFullScreen(index)}
                    alt={`preview-${index}`}
                  />
                </div>
              ))
            : ""}
        </div>
        {
          isFullScreenPatient !== null ?
          <>
          <div className="images-slider">
      <Slider {...settings} className="images-slider1" >
        {patientRequest.request_documents.map((image, index) => (
          <div className="image-container">
            <img src={image} alt='ImageLoading'
             />

          </div>
        ))}
        <img src={images} alt="nothing" className="extra-img"  />
      </Slider>

    </div>

     </> : <></>
        }
{
          isFullScreen !== null ?
          <>
          <div className="images-slider">
      <Slider {...settings} className="images-slider1" >
        {patientRequest.replayed_documents.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt='ImageLoading'
             />

          </div>
        ))}

      </Slider>

    </div>

     </> : <></>
        }
        <div className="buttondivrequestpage">
          <div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              multiple
              onChange={handleFileChange}
              accept="image/*"
            />


            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </div>
          <div className="buttondivrequestpage">
          {activeButtontest === "مغلقة" && <></>}
          {activeButtontest === "مفتوحة" && (
            <>
              <button onClick={uploadAdminReplayedPictures}>رد</button>
            </>
          )}
          {/* {activeButtontest === "قيد المعالجة" && (
            <>
              <button className="inprogressredbut" onClick={showpopupclose}>
                {" "}
                إغلاق
              </button>
              <button> رد</button>

            </>
          )} */}
          <button onClick={() => fileInputRef.current.click()}>
              رفع الملفات
            </button>
        </div>
        </div>
        <div className="collyinputdiv">
          <h6>الردود</h6>
        </div>

        <Chatbox
          setPatientRequest={setPatientRequest}
          setStatus={setStatus}
          status={status}
          patientRequest={patientRequest}
        />
      


      </div>

      <Footer />
    </div>
  );
}
