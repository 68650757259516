
import React, { useState, useEffect } from 'react';
import './Login.css';
import SimpleNavbar from '../SimpleNavbar/SimpleNavbar';
import Footer from '../Footer/Footer';
import Loading from '../Loading/Loading';

import axios from 'axios';
import env from 'react-dotenv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { initializeSocket } from '../Bloodimageanalysis/globalsocket';

export default function Login() {
    const {user,setToken,setUser,loading,setLoading,togglelogin,setTogglelogin } = useContext(UserContext);
    const navigate = useNavigate()
    const [value, setValue] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [phone_number, setPhone_Number] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');


    // const [togglelogin, setTogglelogin] = useState('login');

    const [countryCode, setCountryCode] = useState('sa'); // Default country code to US
    const [submitAttempt, setSubmitAttempt] = useState(false);

    const axiosInstance = axios.create({

        withCredentials: true,
    });

const logintoggle = ()=>{
    setTogglelogin("login");
    setPhone_Number("")

}

const registoggle = ()=>{
    setTogglelogin("register");

    setPhone_Number("")
}
useEffect(()=>{
    if(user){
        navigate('/')
    }
},[])
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    const submitphone = async () => {
        setLoading(true);

        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_SERVER_URL}/otp/sendotp`, { phone_number });
            // console.log('this is response', response.data);

            if (response.data) {
                localStorage.setItem('phone_number', phone_number);
                setLoading(false);
                navigate('/verification');
            }
        } catch (err) {
            // console.log('in error');
            setLoading(false);

            // Check if response and status exist
            if (err.response) {
                const { status, data } = err.response;

                // Handle different error statuses
                switch (status) {
                    case 400:
                        if (data.message === "User Already Exists") {
                            setEmailError('رقم الهاتف هذا موجود بالفعل'); // "This phone number already exists"
                        } else {
                            setEmailError(data.message || 'رقم الهاتف غير صالح'); // Use specific error message or default
                        }
                        break;
                    case 429:
                        setEmailError('لقد وصلت إلى الحد الأقصى لطلبات OTP. يرجى المحاولة مرة أخرى لاحقًا.'); // "You have reached the maximum number of OTP requests. Please try again later."
                        break;
                    case 403:
                        setEmailError('هذا الرقم قد تم إلغاء الاشتراك في استقبال الرسائل النصية.'); // "This number has opted out of receiving SMS."
                        break;
                         case 407:
                        setEmailError('تم وضع علامة على رقم الهاتف هذا بسبب نشاط احتيالي. يرجى الاتصال بالدعم.'); // "This number has opted out of receiving SMS."
                        break;
                    case 500:
                        setEmailError('فشل إرسال OTP. يرجى المحاولة لاحقًا.'); // "Failed to send OTP. Please try again later."
                        break;
                        case 409:
                        setEmailError('هذه المنطقة غير مدعومة لإرسال الرسائل القصيرة'); // "Failed to send OTP. Please try again later."
                        break;
                    default:
                        setEmailError('حدث خطأ غير متوقع. يرجى المحاولة لاحقًا.'); // "An unexpected error occurred. Please try again later."
                }
            } else {
                // Handle network or other errors that do not return a response
                setEmailError('حدث خطأ في الشبكة. يرجى التحقق من اتصال الإنترنت الخاص بك.'); // "A network error occurred. Please check your internet connection."
            }
        }
        // console.log(phone_number);
    };


    const submitLogin = async () => {

        // console.log(phone_number)
        setSubmitAttempt(true);
        // console.log(phone_number);
        if (phone_number === '' || password === '') {
            setEmailError('كلمة المرور غير صحيحة او رقم الجوال غير صحيح');
        } else if (password.length < 4) {
            setEmailError('الرقم السري يجب الا يقل عن 6 احرف على الاقل');
        } else {
            setEmailError(''); // Reset the error message if no error

            setLoading(true)
            await axiosInstance.post(`${process.env.REACT_APP_SERVER_URL}/user/login`, {
                phone_number: phone_number,
                password: password
            }).then((response) => {
                // console.log('this is response',response.data)


                 if(response.data.status==="success"){
                    if(!response.data.data.blocked){
                    setLoading(false)
                    localStorage.setItem('user',JSON.stringify(response.data.data))
                    localStorage.setItem('token',response.data.token)
                    setToken(response.data.token)
                    setUser(response.data.data)
                    initializeSocket();
                    navigate('/')
                    }else{
                        setLoading(false)
                        window.alert('حسابك محظور')
                        navigate('/login')


                    }





                 }

            }).catch((err) => {
                setLoading(false)
                // console.log(err)
                if(err.response!==undefined){
                if (err.response.status === 400) {
                    setEmailError('كلمة المرور او رقم الجوال غير صحيح')
                    return
                }
                else if (err.response.status === 404) {
                    setEmailError('كلمة المرور او رقم الجوال غير صحيح')
                    return
                }
                else if (err.response.status === 500) {
                    setEmailError('حدث خطأ ما')
                    return
                }
                else if (err.response.status === 401) {
                    setEmailError('كلمة المرور او رقم الجوال غير صحيح')
                    return
                }
            }

            });
        }
    };
    // const [countryCode, setCountryCode] = useState('us'); // default country code

  useEffect(() => {
    // Function to fetch country code based on user location
    const fetchCountryCode = async (latitude, longitude) => {
      try {
        // Use a geolocation API to get the country from latitude and longitude
        const response = await axios.get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        const countryCode = response.data.countryCode.toLowerCase();
        setCountryCode(countryCode);
      } catch (error) {
        // console.error('Error fetching country code', error);
      }
    };

    // Get user location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchCountryCode(latitude, longitude);
        //   console.log("Country code",countryCode)

        },
        // (error) => console.error('Error getting location', error)
      );
    }
  }, []);

    return (
        loading ? <Loading /> :
        <div className='loginmaincolordiv'>
            <SimpleNavbar />
            <div className="heightmaindivlogin">
                {
                    togglelogin === 'login' ? <>
                    <div className="loginmaindiv">
                    {emailError && <div className="error-message">{emailError}</div>}

                    <div className="alllogindatadiv">
                        <div className='loginandsignupbutton'>
                                <button id={togglelogin === "register" && 'loginbtn'} onClick={registoggle}>إنشاء حساب</button>
                            <button id={togglelogin === "login" && 'loginbtn'} onClick={logintoggle}>تسجيل دخول</button>
                        </div>
                        <div className="logininputs">
                            <p> رقم الهاتف</p>
                            <div className='inputphonedivlogin'>
                                <PhoneInput
                                    id={'phone'}
                                    country={countryCode}
                                    value={phone_number}
                                    onChange={(phone) => setPhone_Number(phone)}
                                    enableSearch={true}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            submitLogin();
                                        }}}
                                    placeholder='بالرجاء ادخال رقم الجوال '
                                    containerClass={submitAttempt && phone_number === '' ? 'input-error' : ''}
                                />
                            </div>

                                <p>كلمة المرور</p>
                                <div className="password-container">
                                    <input
                                        id='showpasswordmaindiv'
                                        type={showPassword ? "text" : "password"}
                                        placeholder='الرجاء كتابة كلمة المرور'
                                        onChange={(e) => setPassword(e.target.value)}
                                        required={true}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                submitLogin();
                                            }}}
                                        className={submitAttempt && password === '' ? 'input-error' : ''}
                                    />
                                    <button className="toggle-password" onClick={togglePasswordVisibility}>
                                        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                    </button>
                                </div>


                                <div className='loginbutton'>
                                <button onClick={submitLogin}>تسجيل الدخول</button>
                            </div>




                            <div onClick={()=>navigate('/resetpassword')} className='loginp'>
                                <p>هل نسيت كلمة المرور؟</p>
                            </div>

                        </div>
                    </div>
                </div>
                    </> : <>
                    <div className="loginmaindiv">
                    {emailError && <div className="error-message">{emailError}</div>}

                    <div className="alllogindatadiv">
                        <div className='loginandsignupbutton'>
                                <button id={togglelogin === "register" && 'loginbtn'} onClick={registoggle}>إنشاء حساب</button>
                            <button id={togglelogin === "login" && 'loginbtn'} onClick={logintoggle}>تسجيل دخول</button>
                        </div>
                        <div className="logininputs">
                            <p> رقم الهاتف</p>
                            <div className='inputphonedivlogin'>
                                <PhoneInput
                                    id={'phone'}
                                    country={countryCode}
                                    value={phone_number}
                                    onChange={(phone) => setPhone_Number(phone)}
                                    enableSearch={true}
                                    placeholder='بالرجاء ادخال رقم الجوال '
                                    containerClass={submitAttempt && phone_number === '' ? 'input-error' : ''}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          submitphone();
                                        }}}
                                />
                            </div>



                                <div className='loginbutton'>
                                    <button onClick={submitphone}> التالي </button>
                                    <div className='loginbottomtext'>
                                        لديك حساب على الأكاديمية ؟{" "}
                                        <span onClick={logintoggle}>تسجيل دخول</span>
                                    </div>
                                </div>



                        </div>
                    </div>
                </div>
                    </>
                }

            </div>
            <div className="loginfooter">
                <Footer />
            </div>
        </div>
    );
}