import React, { useState } from "react";
import Footer from "../../../Footer/Footer";
import Navbar from "../../../Navbar/Navbar";
import { Icon } from "@iconify/react/dist/iconify.js";
import book from "../../../../../Asserts/Images/Analytics.png";
import axios from "axios";
import { useSessionErrorHandler } from "../../../Managesession";
function AnalyticsDiscount({
  pay,
  setAnalyticsDiscount,
  token,
  code,
  setCode,
  handleSubmit,
  title

}) {
  const [showCode, setShowCode] = useState(false);
  const [discountRate, setDiscountRate] = useState(0);
  const [discoutPrice, setDiscoutPrice] = useState(0);
  const handleSessionError = useSessionErrorHandler();
  function back() {
    setAnalyticsDiscount(false);
  }
  const getDiscountByDiscountCode = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/discounts/getDiscountByDiscountCode/${code}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("discount", res.data);
        setDiscountRate(res.data.data.percentage);
        setShowCode(true);
        setDiscoutPrice(
          Math.floor(
            Number(pay) - (Number(pay) * res.data.data.percentage) / 100
          )
        );
      })
      .catch((err) => {
        setCode("");
        console.log(err);
        if(err.response.status === 404){
          alert("الكود غير صحيح")
        }
        if(err.response.status === 400){

          alert("code is expired or not active")
        }
        handleSessionError(err);
      });
  };

  function Submit() {

      handleSubmit();

  }

  return (
    <div>
      {/* Navbar  */}
      <div>
        <Navbar />
      </div>

      {/* Body  */}
      <div className="book-discount-container">
        <div className="book-discount-inner">
          <div className="book-discount-header">
            <div className="book-discount-header-icon" onClick={back}>
              <Icon icon="weui:back-filled" />
            </div>
            <div className="book-discount-header-text">إتمام عملية الشراء</div>
          </div>
          <div className="book-discount-lower">
            <div className="book-discount-lower-book">
              <div className="book-discount-lower-book-details">
                <div className="book-discount-lower-book-details-img">
                  <img src={book} alt="Book" />
                </div>
                <div className="book-discount-lower-book-details-text">
                  <div className="book-discount-lower-book-details-text-title">
                    {title}
                  </div>
                </div>
              </div>
              <div className="book-discount-lower-book-line"></div>
              <div className="book-discount-lower-book-price">
                <div>{pay}</div>
                <div>:المبلغ كامل</div>
              </div>
            </div>
            <div className="book-discount-lower-code">
              <div className="book-discount-lower-input-container">
                <div className="book-discount-lower-input">
                  <input
                    type="text"
                    value={code}
                    placeholder="اكتب كود الخصم"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="book-discount-lower-button">
                  <button onClick={() => getDiscountByDiscountCode()}>
                    تفعيل كود الخصم
                  </button>
                </div>
              </div>
              {showCode && (
                <div className="book-discount-lower-discount">
                  <div className="book-discount-lower-discount-text">
                    <div> {discountRate}</div>
                    <div>:خصم بمناسبة اليوم الوطني</div>
                  </div>
                  <div className="book-discount-lower-discount-text">
                    <div> {discoutPrice}</div>
                    <div>:المبلغ بعد الخصم</div>
                  </div>
                </div>
              )}
            </div>
            <div className="book-discount-lower-next">
              <button onClick={Submit}>التالي</button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer  */}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default AnalyticsDiscount;
