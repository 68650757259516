import React, { useContext, useEffect, useState } from 'react'
import book from '../../../../../Asserts/Images/book.svg'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useSessionErrorHandler } from '../../../../User/Managesession'
import { UserContext } from '../../../../../context/UserContext'
import BranchLoading from '../../../../User/branchLoading/BranchLoading'
import Empty from '../../../../../Asserts/Images/norequest.png'

function UserPurchases() {
  const navigate = useNavigate()
  const [books, setBooks] = useState([])
  const [loading,setLoading] = useState(false)
  const {userId} = useParams()
  const {token} = useContext(UserContext)
  const handleSessionUser = useSessionErrorHandler()
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/usermanagement/getAllUserPurchasedBooks/${userId}`,{
      headers: {
        'authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      // console.log('user purchases', response);
      setBooks(response.data.response)
    }).catch((err)=>{
      // console.log(err);
      handleSessionUser(err)
    })
  },[])
  return (
    loading ? <BranchLoading/> :
    <div className='ui-interviews-container'> 
        {/* map here */}
        {
          books.length === 0 && <div className="norequestmaindiv">
          <img src={Empty} alt="" />
          لا توجد تحليلات
          </div>
        }
      {books.map((book,i) => (
      <div key={i} onClick={() => navigate(`/buybook/${book._id}`)} className="mainbookdiv">
              <img src={book.bookCoverPhoto} alt="" className='book-img' />
              <div className="paddingleftbotm">
                <h2>{book.bookTitle}</h2>
                <p>من تأليف <span>{book.author}</span></p>
                <p>{book.book_description}</p>
                <h5>{book.bookPrice} SR</h5>
              </div>
            </div>
      ))}
    </div>
  )
}

export default UserPurchases
