import React from "react";
import samplePic from "./../../../../../Asserts/Images/test.png";
import sampleProfile from './../../../../../Asserts/Images/DummyProfile.png'
import { useNavigate } from "react-router-dom";

function VideosEdited({videosEdited}) {
  // console.log("props", videosEdited);
  const navigate = useNavigate()
  const getEmbedUrl = (url) => {
    if (!url) return ""; // Early return if URL is null or undefined

    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
    );

    if (videoIdMatch && videoIdMatch[1].length === 11) {
      // Return the URL for the default YouTube video thumbnail
      return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`; // High-resolution thumbnail
    } else {
      return ""; // Return empty string if the URL is not a YouTube video
    }
  };

  // Ensure videosEdited is an array
  return (
    <div className="videoedited-profile-wholedata">
      {Array.isArray(videosEdited) && videosEdited.length > 0 ? (
        videosEdited.map((item, index) => (
          <div key={index} className="videoedited-profile-container">
            <div className="videoedited-profile-box">
              <div className="videoedited-profile-box-editings">
                <div>{item.totalModifications} : تم تعديل</div>
                <div>{item.date} : تاريخ التعديل</div>
              </div>
              <div className="videoedited-profile-box-line"></div>
              <div className="videoedited-profile-box-profile">
                <div>{item?.userId?.first_name}</div>
                <div className="videoedited-profile-profile-img">
                  <img src={item?.userId?.photo || sampleProfile} alt="Profile Picture" />
                </div>
              </div>
            </div>
            {item.updatedVideos?.length>0? item?.updatedVideos?.map((video, idx) => (

            <div onClick={()=>navigate(`/videos/${video?._id}`)} className="managerdetails-video-box">
              <div className="managerdetails-video-box-left">
                {
                  video.youtubeVideoUrl ?
                  <img src={getEmbedUrl(video.youtubeVideoUrl)} alt="" /> :
                  (video.videoUrl && <video src={video.videoUrl}></video>)
                }
              </div>
                <div key={idx} className="managerdetails-video-box-right">
                  <div className="managerdetails-right-titletime">
                    <div className="managerdetails-right-heading">{video.title}</div>
                  </div>
                  <div className="managerdetails-right-description">{video.description}</div>
                </div>

            </div>
            )):'No activities on this day...'}
          </div>
        ))
      ) : (
        <div>No videos edited yet.</div>
      )}
    </div>
  );
}


export default VideosEdited;
