import React, { useContext, useEffect, useState } from 'react'
import pdf from '../../../MazenFontendDocumentation.pdf'
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { useParams } from 'react-router-dom'
import Loading from '../Loading/Loading';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

function PdfShow() {
  const {id} = useParams()
  const [book,setBook] = useState({bookPDF:pdf});
  const {user} = useContext(UserContext)
  const [loading,setLoading] = useState();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const getBookById = async (book) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/book/view/${book}?userID=${user?._id}`,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log("onebok", res.data);
        setBook(res.data.book);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(()=>{
    getBookById(id)
  },[])
  return (
    loading ? <Loading/> :
    <div>
      <div>
        <Navbar/>
      </div>
      <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <ZoomOutButton />
                    <ZoomPopover />
                    <ZoomInButton />
                </div>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
      <div style={{ height: '750px' }}>
        <Viewer fileUrl={book.bookPDF} plugins={[zoomPluginInstance]} />
      </div>
    </Worker>
    </div>
    <div>
      <Footer/>
    </div>
    </div>
  )
}

export default PdfShow
