// context/UserContext.js
import React, { createContext, useState ,useEffect } from 'react';


const UserContext = createContext();

const UserProvider = ({ children }) => {

  const [activeButton, setActiveButton] = useState(() => {
    const storedActiveButton = localStorage.getItem('activeButton');
    return storedActiveButton ? storedActiveButton : 'الفيديوهات';
  });

  useEffect(() => {
    localStorage.setItem('activeButton', activeButton);
  }, [activeButton]);


  const [activeTab, setActiveTab] = useState('لقاءات')

  // useState(()=>{
  // const storedTab = localStorage.getItem('tahlalat');
  //   return storedTab? storedTab : 'لقاءات';
  // });


  const [selectedTab, setSelectedTab] = useState('اللقاءات المحجوزة')
  const [subTab, setSubTab] = useState('اليوم');

  // useState(()=>{
  //   const storemeeting = localStorage.getItem('admainmeetingsbut');
  //     return storemeeting? storemeeting : 'اللقاءات المحجوزة';
  //   });


    const [activeButtontest, setActiveButtontest] = useState('مفتوحة')


    // useState(() => {
    //   const storedValue = localStorage.getItem('activeButtontest');
    //   return storedValue ? storedValue : 'مفتوحة';
    // });


    const [usermeetings, setUsermeetings] = useState('اللقاءات المحجوزة')

    // useState(()=>{
    //   const storedUsermeetingsrou = localStorage.getItem('usermeetingsr');
    //   return storedUsermeetingsrou? storedUsermeetingsrou : 'قيد المعالجة';
    // });




    // useState(()=>{
    //   const getstoresubtabs = localStorage.getItem('setsubtabsadmin');
    //   return getstoresubtabs? getstoresubtabs : 'لقاءات جديدة';
    // });


    const [activeButtonmet, setActiveButtonmet] = useState("قراءة تحليل");
    const [userSideBar,setUserSideBar] = useState(false)


const [verification,setVerification] = useState(()=>{
  const savedState = localStorage.getItem('ver');
  return savedState ? JSON.parse(savedState) : { count: 0 };
})

useEffect(() => {
  localStorage.setItem('ver', JSON.stringify(verification));
}, [verification]);

const clearVerification = () => {
  localStorage.removeItem('ver');
  setVerification(false);  // Reset to false
};



  const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
  const [token,setToken]  = useState(localStorage.getItem('token') ?localStorage.getItem('token'): null);
  const [pay, setPay] = useState('00.00');

  const [islogin,setIslogin] = useState(false);
  const [success, setSuccess] = useState('success');
  const [successAction, setSuccessAction] = useState('/');
  const [paymentSuccessAction,setPaymentSuccessAction] = useState("/paymenthistory");
  const [error, setError] = useState('error');
  const [subdepartments, setSubdepartments] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('الفيديوهات');
  const [isverify, setIsverify] = useState(false);
const [isapprovemet , setIsapprovemet] = useState(false);

  const [paytitle, setPaytitle] = useState(" ");
const [ loading, setLoading] = useState(false);
const [showsidebar, setShowsidebar] = useState(false);
const [notification, setNotification] = useState(false);
const [formData, setFormData] = useState(new FormData());
const [ispay , setIspay] = useState(true);
const [usersession, setUsersession] = useState(false);
const [togglelogin, setTogglelogin] = useState('login');

  return (
    <UserContext.Provider value={{clearVerification,userSideBar,setUserSideBar,verification,setVerification,togglelogin,setTogglelogin,setUsersession,usersession,token,setToken,ispay,setIspay,usermeetings,setUsermeetings,activeTab,setActiveTab,subTab,setSubTab,paymentSuccessAction,selectedTab,setSelectedTab,activeButtontest,setPaymentSuccessAction,setActiveButtontest,isapprovemet,setIsapprovemet,activeButtonmet,setActiveButtonmet,activeButton,setUser,setActiveButton,formData,setFormData,islogin,setIslogin,setNotification,notification,setShowsidebar,showsidebar,loading,setLoading,paytitle,setPaytitle,isverify,setIsverify, successAction,pay,setSuccessAction,setPay,user, setUser,success,setSuccess,error,setError,subdepartments,setSubdepartments,selectedCategory,setSelectedCategory }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };





