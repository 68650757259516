import React, { useState } from 'react'
import ClosedAnalysis from './AnalysisComponents/ClosedAnalysis'
import OngoingAnalysis from './AnalysisComponents/OngoingAnalysis'

function ManagerAnalysis({activityAnalysis}) {
    const [subactivity,setSubactivity] = useState('ongoing')
  return (
    <div>
      <div className='managerdetails-body-buttons-container1'>
      <div className={subactivity === 'ongoing' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('ongoing')}>التحاليل قيد المعالجة</button></div>
      <div className={subactivity === 'closed' ? 'managerdetails-body-button-mod-active' : 'managerdetails-body-button-mod' }><button onClick={()=>setSubactivity('closed')}>التحاليل المغلقة</button></div>
            </div>
            <div>
                {
                    subactivity === 'closed' && <ClosedAnalysis activityAnalysis={activityAnalysis} />
                }
                {
                    subactivity === 'ongoing' && <OngoingAnalysis activityAnalysis={activityAnalysis} />
                }
            </div>
    </div>
  )
}

export default ManagerAnalysis
