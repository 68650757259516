import React, { useContext, useEffect, useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ManagersContext } from '../../ManagersManagementContext/ManagersContext'
import dummyProfile from './../../../../../Asserts/Images/DummyProfile.png'
import Navbar from '../../../../User/Navbar/Navbar'
import Footer from '../../../../User/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../../../context/UserContext'
import { useSessionErrorHandler } from '../../../../User/Managesession'
import axios from 'axios'
import Loading from '../../../../User/Loading/Loading'
function AllManagers() {

  const navigate = useNavigate()
const {allmanagerpopup,setAllmanagerpopup,setManagerSlide,setManager} = useContext(ManagersContext)
  const [managersData, setManagersData] = React.useState([]);
  const [adminLoading, setAdminLoading] = React.useState(false);
  const handleSessionError = useSessionErrorHandler()
  const {token,user} = useContext(UserContext)
  const handleUnsuspendUser = async(e,id)=>{
    e.stopPropagation()
    try{
      setAdminLoading(true)
      const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/UnsuspendAdmin?adminId=${id}`,{},{headers:{
        authorization:`Bearer ${token}`
      }})
      // console.log("unsuspend",response)
      getAllManagers()
      setAdminLoading(false)
    }catch(err){
      // console.log(err)
      setAdminLoading(false)
      handleSessionError(err)
    }
  }
const handleSuspendUser = async(e,id)=>{
  e.stopPropagation()
  try{
    setAdminLoading(true)
    const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/suspendAdmin?adminId=${id}`,{},{headers:{
      authorization:`Bearer ${token}`
    }})
    // console.log("suspend",response)
    getAllManagers()
    setAdminLoading(false)
  }catch(err){
    // console.log(err)
    setAdminLoading(false)
    handleSessionError(err)
  }
}
const DeleteAdmin = async(e,id)=>{
  e.stopPropagation()
  try{
    setAdminLoading(true)
    const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/admin/deleteAdmin?adminId=${id}`,{headers:{
      authorization:`Bearer ${token}`
    }})
    // console.log("delete",response)
    getAllManagers()
    setAdminLoading(false)
  }catch(err){
    // console.log(err)
    setAdminLoading(false)
    handleSessionError(err)
  }
}



function showPopup (e,index) {
      e.stopPropagation()


    if(allmanagerpopup===index){setAllmanagerpopup(false)}
    else{setAllmanagerpopup(index)}
}
function moveForward (item) {
    navigate(`/manageractivity/${item}`)
}
const getAllManagers = async () => {
  try {
    setAdminLoading(true)
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/admin/alladmins`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("allmanagers", response.data);
    setManagersData(response.data.response);
    setAdminLoading(false)
  } catch (err) {
    // console.log(err);
    setAdminLoading(false)
    setManagersData([]);
    handleSessionError(err)
  }
};
const [search, setSearch] = useState('');
const onSearch = (e) => {
  setSearch(e.target.value);
};
function handleSearch() {}
useEffect(() => {
  if(user?.role==='super-admin'){
  getAllManagers();
  }
}, []);

  return (
    adminLoading?<Loading/>:
    <>
    <div> <Navbar/> </div>

    <div className="allmanager-main">
      <div className="allmanager-head">المديرون</div>
      <div className="allmanager-head">{managersData.length} عدد المديرون</div>
      <div>
                    <div className='au-search-cont2'>
                        <div className='au-s-input'>
                            <input
                                type='text'
                                placeholder='البحث بالاسم أو برقم الجوال'
                                value={search}
                                onChange={onSearch}
                            />
                        </div>
                        <div onClick={handleSearch}>
                            <Icon icon="ri:search-line" />
                        </div>
                    </div>
                </div>
      <div className="allmanager-container">
        {managersData&& managersData?.length>0&&managersData.map((item, index) => {
          return (
            // <div onClick={()=>moveForward(item._id)} className="allmanager-container-box">
              
            //   <div
            //     className="allmanager-profileicon"

            //   >
            //     {" "}
            //     <img src={item.photo} alt="" />{" "}
            //   </div>
            //   <div > {item.first_name} </div>
            // </div>
            <div className='au-u-box' onClick={()=>moveForward(item._id)}  key={item._id}>
                                <div className='au-u-data'>
                                    <div className='au-u-b-name'>{item.first_name+" "+ item.last_name}</div>
                                    <div>{item.phone_number}</div>
                                </div>
                                {<> {item.photo? <div className='au-u-b-img'>
                                    <img src={item.photo} alt="Picture" />
                                </div> : <div className='au-u-b-img'>
                                    <img src={dummyProfile} alt="Picture" />
                                </div> }</>}
                            </div>
          );
        })}
      </div>
    </div>
    <div><Footer/></div>
    </>
  )
}

export default AllManagers;
