import React, { useContext, useEffect, useState } from "react";
import "./codevisit.css";
import Footer from "../../../Footer/Footer";
import Navbar from "../../../Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import { UserContext } from "../../../../../context/UserContext";

function CodeVisit() {

    const [code,setCode] = useState('Code123')
    const [copyInform,setCopyInform] = useState(false)
    const {token} = useContext(UserContext)
    const {id} = useParams()
    const [discounts,setDiscounts] = useState({})

  const navigate = useNavigate();

  function Back() {
    navigate("/");
  }
 const getDiscountDetails = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/discounts/getDiscountById/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("discount", res.data);
        setDiscounts(res.data.data)})
      .catch((err) => {
        console.log(err);

      });
  };

  useEffect(()=>{
    getDiscountDetails()
  },[])
  function copyCode () {
    navigator.clipboard.writeText(code);
    setCopyInform(true)
    setTimeout(() => {
      setCopyInform(false)
    }, 2000);
  }

  function editCode() {
    navigate(`/codeedit/${discounts[0]._id}`)
  }

  return (
    <div>
      {/* Navbar  */}
      <div>
        <Navbar />
      </div>

      {/* Body */}
      <div className="discount-body">

        {
        copyInform && <div className="copy-inform">
           يتم نسخ الرمز
        </div>
        }

        <div className="discount-body-inner">
          <div className="discount-header">
            {/* Back button  */}
            <div className="discount-header-icon" onClick={Back}>
              <Icon icon="weui:back-filled" />
            </div>
            <div className="discount-header-title">انشاء أكواد الخصم</div>
          </div>
          <div className="discount-form-div">
            <div className="discount-heading">
                <h1>الخصم على جميع الخدمات</h1>
            </div>
            <div className="code-visit-code-container">
              <div className="c-v-c-heading">خصم {discounts?.percentage}% على جميع الخدمات بمناسبة {discounts?.reason}</div>

              {/* Code  */}
              <div className="c-v-c-code">
                {discounts?.code}
              </div>
              <div>
                <div className="discount-button">
                    {/* to generate the code  */}
                  <button onClick={copyCode}>نسخ الكود</button>
                </div>
              </div>
            </div>
            <div className="discount-button">
                {/* To copy the code  */}
              <button onClick={editCode}>انشاء كود خصم جديد</button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer  */}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default CodeVisit;
