import React, { useEffect, useState } from "react";
import "./Buybook.css";

import { Icon, loadIcon } from "@iconify/react/dist/iconify.js";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

import bookimg from "../../../Asserts/Images/book.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Comments from "../Videoshow/Comments";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import Loading from "../Loading/Loading";
import Share from "./Share";
import { useSessionErrorHandler } from "../Managesession";

function Buybook() {
  const handleSessionError = useSessionErrorHandler();
  const location = useLocation()
  const url = location.pathname
  const host = window.location.host
  const [deleteReason, setDeleteReason] = useState('');

  const axiosInstance = axios.create({
    withCredentials: true,

    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const { bookId } = useParams();
  const {
    user,
    setUser,
    loading,
    setLoading,
    setPaymentSuccessAction,
    successAction,
    token,
    setUsersession,
    setActiveButton,
    setSuccess,
    setSuccessAction,
  } = useContext(UserContext);
  // const {setPay, setPaytitle,setActiveButton,setSuccessAction,setSuccess,user} = useContext(UserContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("description");
  const [popupshow, setPopupshow] = useState(false);
  const [book, setBook] = useState({});
  const [payed, setPayed] = useState(false);
  const [totalbuyers, setTotalBuyers] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [deletepopup, setDeletepopup] = useState(false);
  const [like, setLike] = useState(false);
  const [incLike, setIncLike] = useState();
  const [showAll, setShowAll] = useState(false);
  const [commentDisableReason, setCommentDisableReason] = useState("");

  function toBookDiscount (id) {
    navigate(`/bookdiscount/${bookId}`);
  }


  const [showAll2, setShowAll2] = useState(false);

  const truncatedDescription2 = showAll2
    ? book.authorInfo
    : book.authorInfo?.substring(0, 300) || "";
  const [userRating, setUserRating] = useState(null);
  const [averageRating, setAverageRating] = useState(0);

  const submitRating = async (newRating) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/book/rate/${bookId}`,
        {
          userId: user._id,
          rating: newRating,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      setUserRating(newRating);
      setAverageRating(response.data.averageRating);
    } catch (err) {
      // console.error(err);
    }
  };
const updateBookToEnable = async () => {

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/book/updateBookToEnable/${bookId}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      // console.log("Book Ratings", response.data);

    } catch (err) {
      // console.error(err);
    }
  };
  const updateBookToDisable = async () => {

    try {
      setLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/book/updateBookToDisable/${bookId}?disableBy=${user._id}`,
        {deleteReason:deleteReason},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      setLoading(false);
      navigate("/success");
      setSuccessAction("/");
      // console.log("Book Ratings", response.data);

    } catch (err) {
      setLoading(false);
      // console.error(err);
    }
  };


  const fetchBookRatings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/book/rating/${bookId}/${user?._id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      // console.log("Book Ratings", response.data);

      setUserRating(response.data.userRating);
      setAverageRating(response.data.averageRating);
    } catch (err) {
      // console.error(err);
    }
  };

  useEffect(() => {
    fetchBookRatings();
  }, []);

  const showMore2 = () => {
    setShowAll2(!showAll2);
  };

  const truncatedDescription = showAll
    ? book.authorInfo
    : book.authorInfo?.substring(0, 300) || "";

  const showMore = () => {
    setShowAll(!showAll);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab === "comments") {
      getAllComments();
    }
  }, [activeTab]);
  const getAllComments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/BookComments/getAllComments/${bookId}`
      );
      // console.log("All Comments", response.data);
      setComments(response.data.comments);
    } catch (err) {
      // console.log(err);
    }
  };
  const disableComment = async (commentId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/BookComments/disableComment/${bookId}/${commentId}?disableBy=${user._id}`,
        { disableReason: commentDisableReason },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setComments(comments.filter((comment) => comment._id !== commentId));

    } catch (err) {
      console.log(err);
      handleSessionError(err);
    }
  };

  const superAdminDeleteComment = async (commentId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/BookComments/deleteComment/${bookId}/${commentId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setComments(comments.filter((comment) => comment._id !== commentId));
    } catch (err) {
      // console.log(err);
      handleSessionError(err);
    }
  };
  const deleteComment = async (commentId) => {
   if(user.role === 'super-admin'){
    superAdminDeleteComment(commentId);
    return;
    }
    else if(user.role === 'admin'){
      disableComment(commentId);
      return;
    }


  };
  const unlikeComment = async (commentId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/BookComments/unlikeComment/${bookId}/${commentId}`,
        { unlikedBy: user._id },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("unlike comment", response.data);

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment._id === commentId ? response.data.response : comment
        )
      );
    } catch (err) {
      // console.log(err);
      handleSessionError(err);
    }
  };
  const addComment = async (newComment) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/BookComments/addComment/${bookId}`,
        {
          comment: newComment,
          commentedBy: user._id,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      getAllComments();
    } catch (err) {
      handleSessionError(err);
    }
  };

  const likeComment = async (commentId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/BookComments/likeComment/${bookId}/${commentId}`,
        { likedBy: user._id },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("like comment", response.data);

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment._id === commentId ? response.data.data : comment
        )
      );
    } catch (err) {
      handleSessionError(err);
      // console.log(err);
    }
  };
  const handleBookLike = async () => {
    if (!user) {
      alert("الرجاء تسجيل الدخول أولا");
      return;
    }
    if (like) {
      // console.log("unlike");
      await axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/booklikes/unlikebook/${bookId}?likedBy=${user._id}`,
          {},
          {
            headers: { authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // console.log(response.data.response);
          setIncLike(response.data.likes);
          setLike(false);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      // console.log("like");
      await axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/booklikes/likebook/${bookId}?likedBy=${user._id}`,
          {},
          {
            headers: { authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // console.log(response);
          setIncLike(response.data.likes);
          setLike(true);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };
  const getVideoLikes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/booklikes/booklikedbyuser/${bookId}?userId=${user._id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      // console.log("video likes", response.data.response);
      setLike(response.data.response);
      setIncLike(response.data.likes);
    } catch (err) {
      // console.log(err);
      handleSessionError(err);
    }
  };

  useEffect(() => {
    getVideoLikes();
  }, []);

  const [downloadPercentage,setDownloadPercentage] = useState(0)
  const [downloadLoader,setDownloadLoader] = useState(false)

  const gotoDownload = async () => {
    navigate(`/pdfiframe/${bookId}`)

  };
  const getBookById = async (book) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/book/view/${book}?userID=${user?._id}`,
        { withCredentials: true }
      )
      .then((res) => {
        console.log("onebok", res.data);
        setBook(res.data.book);
        setPayed(res.data.payment);
        setTotalBuyers(res.data.buyers);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getBookById(bookId);
  }, []);

  const gotopayment = async () => {
    // console.log("book", user);

    if (user) {
      let newFormData = new FormData();
      newFormData.append("title", book.bookTitle);
      newFormData.append(
        "service_details",
        "For Buying a book" + book.bookTitle
      );
      newFormData.append("order_description", book.book_description);
      newFormData.append("order_amount", book.bookPrice);
      newFormData.append("payer_email", user.email);
      newFormData.append("payer_id", user._id);
      newFormData.append("payer_first_name", user.first_name);
      newFormData.append("payer_last_name", user.last_name);
      newFormData.append("payer_phone", user.phone_number);
      newFormData.append("bookId", bookId);

      try {
        setLoading(true);

        const response = await axiosInstance.post(
          `${process.env.REACT_APP_SERVER_URL}/edfapay/getbookpaymentdetails`,
          newFormData,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("edfa", response);
        if(response.data.status === 500){
          alert("An error occurred while initiating the payment. Please try again later.");
          setFadeOut(false);
          return;
        }
        if (response.status == 200) {
          // console.log("insdie if");
          //   setSuccess("address success");
          newFormData = null;
          // Trigger fade-out effect

          setFadeOut(true);
          setTimeout(() => {
            setLoading(false);

            window.location.href = response.data.redirect_url;
          }, 500); // Adjust delay to match CSS transition
        }
      } catch (error) {
        if(error.response.status===400){
          alert(error.response.data.message);
          setFadeOut(false);
          setLoading(false);
          return
        }
        if(error.response.status === 500){
          alert(error.response.data.message);
          setFadeOut(false);
          setLoading(false);
          return
        }
        handleSessionError(error);
        // console.log(error);
        newFormData = null;
        setLoading(false);

        // navigate("/error");
      }
    } else {
      navigate("/login");
    }
  };

  const [comments, setComments] = useState([]);

  const [rating, setRating] = useState(0); // State to track the selected rating

  const [showsharepop, setShowsharepop] = useState(false);

  const tooglesharepopup = () => {
    setShowsharepop(!showsharepop);
  };

  const handleStarClick = (starValue) => {
    setRating(starValue);
    submitRating(starValue); // Submit the rating to the server
    // console.log(`User rated the book ${starValue} stars`);
  };
  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/userDetails/${user._id}`, {
          headers: {
            authorization: `Bearer ${token}`
          }
        });
        // console.log("User data:", response.data);
        const userData = response.data.data;
        setUser(userData)
      } catch (error) {
        handleSessionError(error);
        // console.error("Error fetching user data:", error);
      }
    };
    getUserData();
  }, []);

  const gotodashboard = () => {
    navigate("/");
    setActiveButton("books");
  };

  const showpopup = (e) => {
    e.stopPropagation();
    setPopupshow(!popupshow);
  };

  const showdelete = (e) => {
    e.stopPropagation();
    setDeletepopup(!deletepopup);
  };

  const deleteBook = async () => {
    setDeletepopup(false);
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/book/delete/${bookId}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log("books", res.data.response);
        setLoading(false);
        navigate("/success");
        setSuccessAction("/");
      })
      .catch((err) => {
        // console.log("book error", err);
        setLoading(false);
        navigate("/error");
      });
  }
    // delete the course from the database or any other required actions
  const performdeletaction = async () => {
    // delete the course from the database or any other required actions
    if(user.role === 'super-admin'){
      deleteBook();
    }
    else{
      updateBookToDisable();
    }

  };


  const navigatesucess = (e) => {
    e.stopPropagation();
    navigate("/success");
    setSuccessAction("/");
    setSuccess("success message");
  };

  const gotouplaodvideo = (e) => {
    e.stopPropagation();
    navigate(`/uploadbook/${bookId}`);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      {showsharepop && (
        <>
          <div onClick={tooglesharepopup}>
            <Share uRl={`${host}${url}`} />
          </div>
        </>
      )}
      {/* <button onClick={gotopayment} >ByBook</button> */}

      <div className={`readanaklysitopdiv ${fadeOut ? "fade-out" : ""}`}>
        <div className="buyabookmaindiv">

        {deletepopup && (
        <div className="deletemaindiv">
          <div className="deletecontainer">
            <div className="deletetitle">
              <div>سبب الحذف</div>
              <div
                className="CloseHadafPopup"
                onClick={() => setDeletepopup(false)}
              >
                x
              </div>
            </div>

            <textarea
              name=""
              id=""
              placeholder="مختلف عن موضوع القسم"
              value={deleteReason}
              onChange={(e) => setDeleteReason(e.target.value)}
            ></textarea>
            <button onClick={performdeletaction}>
              <Icon
                icon="ic:baseline-delete"
                width="1.2em"
                height="1.2em"
              />
              حذف{" "}
            </button>
          </div>
        </div>
      )}

          <div className="buyabooknavbar">
            <Navbar />
          </div>

          <div className="buyabookcontainer">
            <div className="linktonavigate" onClick={gotodashboard}>
              الصفحة الرئيسية {">"} <span>{book.bookTitle}</span>
            </div>
            <div className="booksectionmian">

              <div className="bookimgdiv">
                <img src={book.bookCoverPhoto} alt="" srcset="" />
              </div>

              <div className="bookinfo">
                <div className="likesharediv bookaddedclass">
                  <div className="share">
                    <Icon
                      onClick={tooglesharepopup}
                      icon="ph:share-fat-fill"
                      width="1.2em"
                      height="1.2em"
                    />
                  </div>

                  <div
                    className={like ? "activelike" : "like"}
                    onClick={handleBookLike}
                  >
                    <Icon icon="ph:heart-fill" width="1.2em" height="1.2em" />{" "}
                    {incLike > 0 ? incLike : ""}
                  </div>
                </div>

<div className="book-title-adjustment">{book.bookTitle}</div>

                {user && (((user.role === "admin")&&(user.ableToUploadBannerAndVideos))||(user.role === "super-admin")) && (
                  <>
                    <div>
                      <Icon
                        onClick={showpopup}
                        icon="bi:three-dots-vertical"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                    {popupshow && (
                      <>
                        <div className="popupmain bookpopupdiv">
                          <div onClick={gotouplaodvideo}>تعديل </div>
                          <div onClick={navigatesucess}>إخفاء </div>
                          <div onClick={showdelete}>حذف </div>
                        {user.role === 'super-admin' && book.disable ? (
                          <div onClick={updateBookToEnable}> يتعافى </div>
                        ) : null}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="writtenby"><span className="author">{book.author}</span> : من تأليف</div>

              <div className="buyers">{totalbuyers} مشتري</div>

              <div className="fivestartsdiv">
                {/* Render stars based on current rating */}
                {[...Array(5)].map((_, index) => (
                  <Icon
                    key={index}
                    icon="ic:baseline-star"
                    width="1.2em"
                    height="1.2em"
                    style={{
                      color: index < userRating ? "gold" : "gray",
                      cursor: "pointer",
                    }}
                    onClick={() => handleStarClick(index + 1)}
                  />
                ))}
                <span>{averageRating}</span>
              </div>

              {payed ? (
                ""
              ) : (
                <div className="bookpricediv" onClick={gotopayment}>
                  {book.bookPrice}ريال سعودي
                </div>
              )}
            </div>

            <div className="routesnavdiv booknavdivs">
              <div
                className={activeTab === "description" ? "active" : ""}
                onClick={() => handleTabClick("description")}
              >
                الوصف
              </div>
              <div
                className={activeTab === "aboutauther" ? "active" : ""}
                onClick={() => handleTabClick("aboutauther")}
              >
                معلومات عن المؤلف
              </div>

              <div
                className={activeTab === "comments" ? "active" : ""}
                onClick={() => handleTabClick("comments")}
              >
                التعليقات
              </div>
            </div>

            <div className="showroutescontent">
              {activeTab === "description" && (
                <div className="desxraptionmaindiv">
                  <div> {book.formattedDate} :تم نشره </div>
                  <div style={{ lineHeight: 1.6 }}>{truncatedDescription}</div>
                  {truncatedDescription.length < 300 ? (
                    <></>
                  ) : (
                    <>
                      {truncatedDescription !== book.book_description && (
                        <div onClick={showMore}>...المزيد</div>
                      )}
                      {truncatedDescription === book.book_description && (
                        <div onClick={showMore}>تظهر أقل</div>
                      )}
                    </>
                  )}
                </div>
              )}
              {activeTab === "aboutauther" && (
                <>
                  <div className="desxraptionmaindiv">
                    <div>{truncatedDescription2}</div>

                    {truncatedDescription2.length < 300 ? (
                      <></>
                    ) : (
                      <>
                        {truncatedDescription2 !== book.authorInfo && (
                          <div onClick={showMore2}>...المزيد</div>
                        )}
                        {truncatedDescription2 === book.authorInfo && (
                          <div onClick={showMore2}>تظهر أقل</div>
                        )}
                      </>
                    )}

                    {/* <div className='showmoreauther' onClick={authershowmore}>...المزيد</div> */}
                  </div>
                </>
              )}

              {activeTab === "comments" && (
                <>
                  <Comments
                    commentDisableReason={commentDisableReason}
                    setCommentDisableReason={setCommentDisableReason}
                    addComment={addComment}
                    comments={comments}
                    user={user}
                    likeComment={likeComment}
                    deleteComment={deleteComment}
                    videoID={bookId}
                    unlikeComment={unlikeComment}
                  />
                </>
              )}
            </div>

            <div className="buttondivbuybook">
              {payed ? (



                  <button onClick={gotoDownload}>تمتع بالقراءة</button>

              ) : (
                <button onClick={()=>toBookDiscount()}>اشتري الآن</button>
              )}
            </div>
          </div>

          <div className="buyabookfooter">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Buybook;
