import React, { useState, useEffect } from 'react';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import './interviewmanagement.css';
import axios from 'axios';

function InterviewManagement() {
    const [days, setDays] = useState([]);
    const [daysCheck, setDaysCheck] = useState([]);
    const [newTime, setNewTime] = useState({}); // Object to hold new time inputs

    useEffect(() => {
        const fetchMeetingTimings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/meetingtiming/gettimings`);
                if (response.data.data.timings.length > 0) {
                    const fetchedDays = response.data.data.timings;
                    setDays(fetchedDays);
                    setDaysCheck(fetchedDays.map(day => ({ name: day.day, check: day.isAvailable })));
                } else {
                    console.warn('No timings found');
                    setDays([]);
                }
            } catch (error) {
                // console.error('Error fetching meeting timings:', error);
            }
        };

        fetchMeetingTimings();
    }, []);

    function daySelection(e) {
        const { name, checked } = e.target;
        setDaysCheck(prev => prev.map(day => (day.name === name ? { ...day, check: checked } : day)));
        axios.patch(`${process.env.REACT_APP_SERVER_URL}/meetingtiming/toggle-availability`, {
            dayName: name,
            isAvailable: checked,
        });
    }

    function convertTo24HourFormat(hour, minute, period) {
        hour = parseInt(hour, 10);
        minute = parseInt(minute, 10);
        if (period === 'PM' && hour < 12) hour += 12;
        if (period === 'AM' && hour === 12) hour = 0;
        if (period === 'AM' && hour === 0) hour = 12; // Adjust for 12 AM
        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    }

    function addTime(dayName) {

        // console.log(Object.entries(newTime))
        // const sampArray = Object.entries(newTime)


        const timeIn24HourFormat = convertTo24HourFormat(newTime[dayName]?.hour || '00', newTime[dayName]?.minute || '00', newTime[dayName]?.period || 'AM');
        axios.post(`${process.env.REACT_APP_SERVER_URL}/meetingtiming/add-timing`, {
            dayName: dayName,
            newTime: timeIn24HourFormat,
        })
        .then(response => {
            const updatedDay = response.data.data.updatedSchedule;
            setDays(prev => prev.map(day => (day.day === dayName ? updatedDay : day)));
            setNewTime(prev => ({ ...prev, [dayName]: { hour: '', minute: '', period: 'AM' } })); // Reset time for that specific day
        })
        .catch(error => {
            // console.error('Error adding time:', error);
        });
    }

    function removeTime(dayName, index) {
        axios.patch(`${process.env.REACT_APP_SERVER_URL}/meetingtiming/remove-timing`, { dayName, index })
            .then(response => {
                if (response.data.status === 'success') {
                    setDays(prev => prev.map(day => (day.day === dayName ? { ...day, timings: day.timings.filter((_, ind) => ind !== index) } : day)));
                } else {
                    // console.error('Error removing timing:', response.data.message);
                }
            })
            .catch(error => {
                // console.error('API call error:', error);
            });
    }

    function timeSelection(e, dayName, index) {
        const { value } = e.target;
        axios.patch(`${process.env.REACT_APP_SERVER_URL}/meetingtiming/update-timing`, {
            dayName: dayName,
            newTime: value,
            index: index,
        })
        .then(response => {
            const updatedDay = response.data.data.updatedSchedule;
            setDays(prev => prev.map(day => (day.day === dayName ? updatedDay : day)));
        })
        .catch(error => {
            // console.error('Error updating time:', error);
        });
    }

    // Function to handle time change for specific day
    function handleNewTimeChange(e, dayName, field) {
        const value = e.target.value;
        setNewTime(prev => ({
            ...prev,
            [dayName]: {
                ...prev[dayName],
                [field]: value,
            }
        }));
    }

    const renderDay = (day, index) => (
        <>
        <div className='i-m-d-c-day' key={day.day}>
            <div className='i-m-d-c-d-date'>{day.date}</div>
            <div className='i-m-d-c-d-line'></div>
            <div className='i-m-d-c-d-check'>
                <div className='i-m-d-c-d-c-day'>{day.day}</div>
                <div className='i-m-d-c-d-c-check'>
                    <input
                        type="checkbox"
                        name={day.day}
                        checked={daysCheck[index]?.check || false}
                        onChange={daySelection}
                    />
                </div>
            </div>
        </div>
        {daysCheck[index]?.check && (
            <>
                <div className='i-m-time-container'>
                    {day.timings.map((item, timeIndex) => (
                        <div className='i-m-t-main' key={timeIndex}>
                            <div className='i-m-t-m-time'>
                                <input
                                    type="time"
                                    onChange={(e) => timeSelection(e, day.day, timeIndex)}
                                    value={item.time}
                                    name={day.day}
                                />
                            </div>
                            {timeIndex !== -1 && (
                                <div
                                    style={{ backgroundColor: 'red', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    onClick={() => removeTime(day.day, timeIndex)}
                                >
                                    <Icon icon="ic:baseline-close" />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className='i-m-t-end-btn'>
                    <div className='i-m-t-e-b-times'>
                    <select value={newTime[day.day]?.hour || ''} onChange={(e) => handleNewTimeChange(e, day.day, 'hour')}>
                        <option value="00">00</option>
                        {Array.from({ length: 12 }, (_, i) => (
                            <option key={i} value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                    <select value={newTime[day.day]?.minute || ''} onChange={(e) => handleNewTimeChange(e, day.day, 'minute')}>
                        <option value="00">00</option>
                        {Array.from({ length: 60 }, (_, i) => (
                            <option key={i} value={i < 10 ? `0${i}` : i}>{i < 10 ? `0${i}` : i}</option>
                        ))}
                    </select>
                    <select value={newTime[day.day]?.period || 'AM'} onChange={(e) => handleNewTimeChange(e, day.day, 'period')}>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                    </div>
                    <div>
                    <button onClick={() => addTime(day.day)}>إضافة ميعاد</button>
                    </div>
                </div>
            </>
        )}</>
    );

    return (
        <div className='interview-management-main'>
            <div className='i-m-navbar'><Navbar /></div>
            <div className='i-m-body'>
                <div className='i-m-upper'>
                    <div><Icon icon="weui:back-filled" /></div>
                    <div className='i-m-u-text'>إعدادات المقابلات</div>
                </div>
                <div className='i-m-main'>
                    <div className='i-m-d-text'>الأيام و المواعيد</div>
                    <div className='i-m-d-components'>
                        {days.map((day, index) => renderDay(day, index))}
                    </div>
                </div>
            </div>
            <div style={{width:'100%'}}><Footer /></div>
            
        </div>
    );
}

export default InterviewManagement;
