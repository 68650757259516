import React, { useState, useContext, useEffect } from "react";
import "./Progressadr.css";
import { UserContext } from "../../../context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import Loading from "../Loading/Loading";
import moment from "moment";
import { useSessionErrorHandler } from "../Managesession";
import MeetingCheck from "../Discounts/CodeCheckPages/MeetingCheck/MeetingCheck";
function  Bookmeeting() {
  const handleSessionError = useSessionErrorHandler();
  const navigate = useNavigate();
  const {
    setActiveButtonmet,
    setError,
    setSuccessAction,
    token,
    activeButtonmet,
  } = useContext(UserContext);
  const { user, setLoading, loading ,setPay } = useContext(UserContext);
  const [value, setValue] = useState(new Date());
  const [showPopState, setShowPopState] = useState(false);
  const [activeItem, setActiveItem] = useState(""); // Track selected item (initially null)
  const [descraption, setDescraption] = useState("");
  const [code,setCode] = useState('');
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [day,setDay] = useState('')
  const [date,setDate] = useState('')
  const [year,setYear] = useState('')

  const handleClick = (time) => {
    setActiveItem(time);
  };

  const [scheduleData,setScheduleData] = useState([

  ]);
  const [allowedDates,setAllowedDates] = useState ([

  ])
  const [times,setTimes] = useState([])
  useEffect(() => {
    const fetchMeetingTimings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/meetingtiming/gettimings`);
        if (response.data.data.timings.length > 0) {
          const fetchedDays = response.data.data.timings;

          // Map the days and include the timings in the allowedDates state
          const dayss = fetchedDays.map((it) => {
            const array = it.date.split('-');
            const [year, month, day] = array.map((part) => parseInt(part, 10));
            const date = new Date(Date.UTC(year, month - 1, day));

            // Convert to Saudi timezone
            const saudiDate = new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }));

            // Return date and timings together
            return { date: saudiDate, timings: it.timings };
          });
          // console.log(dayss)
          setAllowedDates(dayss);
        } else {
          console.warn('No timings found');
        }
      } catch (error) {
        // console.error('Error fetching meeting timings:', error);
      }
    };
    fetchMeetingTimings();
}, []);
const handleDateChange = (date) => {
  setActiveItem('');
  setValue(date);
  const selectedDate = moment(date).format("YYYY-MM-DD"); // Format selected date to match fetched data

  // Find the entry that matches the selected date
  const selectedDayData = allowedDates.find(
    (day) => moment(day.date).format("YYYY-MM-DD") === selectedDate
  );
    // console.log(selectedDayData)
  // If timings exist for the selected date, update scheduleData with timings
  if (selectedDayData && selectedDayData.timings) {
    setScheduleData(selectedDayData.timings);
  } else {
    setScheduleData([]); // Clear if no timings available for the selected date
  }

  setShowPopState(true); // Set pop state true on date selection
};


  const handleButtonClick = (buttonName) => {
    setActiveButtonmet(buttonName);
    navigate("/readanalysis");
  };

  const [fadeOut, setFadeOut] = useState(false);
  const { interviewID } = useParams();
  const gotopay = async () => {
    setLoading(true);
    const [hours, minutes] = activeItem.split(":").map(Number);
    const combinedDateTime = new Date(value);

    // Set the hours and minutes
    combinedDateTime.setHours(hours);
    combinedDateTime.setMinutes(minutes);
    combinedDateTime.setSeconds(0); // Set seconds to 0
    combinedDateTime.setMilliseconds(0); // Set milliseconds to 0

    // Format the meeting date as dd/mm/yyyy
    const meetingDate = moment(value).format("DD/MM/YYYY");

    // Format the meeting hour in am/pm format
    const meetingHour = moment(combinedDateTime).format("h:mm A");
    const meetingDay = moment(value).format("dddd");


    // Keep the original ISO formatted datetime for Meeting_Time
    const formattedDateTime = combinedDateTime.toISOString();

    // Send the meeting data to the server
    try {
      // Set up payment details
      const paymentData = {
        title: "Meeting Payment",
        code: code,
        service_details: "Payment for meeting",
        order_description: "Payment for attending the meeting",
        order_amount: 2000, // Adjust as necessary
        payer_email: user.email,
        payer_id: user._id,
        zoomReqID: interviewID,
        payer_first_name: user.first_name,
        payer_last_name: user.last_name,
        payer_phone: user.phone_number,
        Meeting_Req_User: user._id,
        Meeting_Time: formattedDateTime, // Keep the original meeting time
        Meeting_Date: meetingDate, // Add formatted meeting date
        Meeting_Hour: meetingHour, // Add formatted meeting hour
        Meeting_description: descraption,
        Meeting_Day: meetingDay,
        code:code

      };

      // Create an axios instance
      const axiosInstance = axios.create({
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" ,
        authorization:`Bearer ${token}`}
      });
      // Send request to EDFAPay
      const formData = new FormData();
      Object.keys(paymentData).forEach(key => formData.append(key, paymentData[key]));
      setFadeOut(true); // Trigger fade-out effect
      const response = await axiosInstance.post(`${process.env.REACT_APP_SERVER_URL}/edfapay/getzoomMeetingTransDetails`, formData);
      // console.log(response);
      if(response.data.status === 500){
        alert("An error occurred while initiating the payment. Please try again later.");
        setFadeOut(false);
        return;
      }
      if (response.status === 200) {


        setTimeout(() => {
          window.location.href = response.data.redirect_url;
        }, 500); // Delay to match CSS transition
      } else {
        // console.error("Payment initiation failed");
        setFadeOut(false); // Reset fade-out state on error
      }
    } catch (error) {
      if(error.response.status === 500){
        alert(error.response.data.message);
        setFadeOut(false);
        setLoading(false);
        return
      }
      // console.error("Error during payment initiation:", error);
      handleSessionError(error);
      setFadeOut(false); // Reset fade-out state on error
    }
    setLoading(false);
  };

  const [noUser,setNoUser] = useState(false)
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    navigate('/logic')
  }




  const isDateSelectable = (date) => {
    // Log the date to check its value
    // console.log('allow',allowedDates)
    // Now we loop through allowedDates and check if the date matches
    return allowedDates.some(
      (allowedDateObj) =>
        allowedDateObj.date.getFullYear() === date.getFullYear() &&
        allowedDateObj.date.getMonth() === date.getMonth() &&
        allowedDateObj.date.getDate() === date.getDate()
    );
  };
  const disableDates = ({ date, view }) => {
    if (view === 'month') {
      return !isDateSelectable(date);
    }
  };

  const [meetingCheck,setMeetingCheck] = useState(false)
  function toMeetingDiscount (id) {
    const [hours, minutes] = activeItem.split(":").map(Number);
    const combinedDateTime = new Date(value);

    // Set the hours and minutes
    combinedDateTime.setHours(hours);
    combinedDateTime.setMinutes(minutes);
    combinedDateTime.setSeconds(0); // Set seconds to 0
    combinedDateTime.setMilliseconds(0); // Set milliseconds to 0

    // Format the meeting date as dd/mm/yyyy
    const meetingDate = moment(value).format("DD/MM/YYYY");
    const meetingDay = moment(value).format("dddd");
    setDate(meetingDate)
    setDay(meetingDay)
    setMeetingCheck(true)
  }


  return loading ? (
    <Loading />
  ) : (
    meetingCheck ? <MeetingCheck setMeetingCheck={setMeetingCheck} code={code} setCode={setCode} day={day} date={date}  gotopay={gotopay} loading={loading} /> :
    <>
      <div className="bookameetingmain">
        <div className="bookmeetingnavbar">
          <Navbar />
        </div>
        {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
        <div className="bookmeetingcontainer">
          <div className="readanalysisbuttonmaindiv">
            <button
              className="simplebuttomn"
              onClick={() => handleButtonClick("قراءة تحليل")}
            >
              قراءة تحليل
            </button>
            <button className="active2">لقاء مع الدكتور أونلاين</button>
          </div>

          <div className="calendermaindiv">
            <Calendar
              onChange={handleDateChange}
              locale="ar"
              value={value}
              minDate={new Date()} // Disable past dates
              tileDisabled={disableDates}
            />
            {showPopState && (
              <>
                <div className="showlinediv">المواعيد المتاحة</div>
                <div className="datesselectmain">
                  {scheduleData.map((schedule, index) => (
                    <button
                      key={index}
                      className={
                        (submitAttempt && !activeItem) ||
                        activeItem === schedule.time
                          ? activeItem === schedule.time
                            ? "activatetim"
                            : "input-error"
                          : ""
                      }
                      onClick={() => handleClick(schedule.time)}
                    >
                      {schedule.time}
                    </button>
                  ))}
                </div>
                <div className="textdescraption">
                  <label htmlFor="">الوصف</label>
                  <textarea
                    name="descraption"
                    value={descraption}
                    onChange={(e) => setDescraption(e.target.value)}
                    className={
                      submitAttempt && !descraption ? "input-error" : ""
                    }
                  ></textarea>
                </div>
                <div className={`bookameetingmain ${fadeOut ? 'fadeOut' : ''}`}>
               {
                activeItem && (
                  <div className="Analysisrequestbtn">
                  <button onClick={()=>toMeetingDiscount('id')}>التالي</button>
                </div>
                )
               }
                <div className="book-a-meeting-down-text">
          مدة اللقاء نصف ساعة وسعره 2000 ريال سعودي
          </div>
          </div>
              </>
            )}
          </div>
        </div>

        <div className="bookmeetingfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Bookmeeting;
