import React, { useContext, useEffect, useState } from 'react'
import './useranalytics.css'
import { UserContext } from '../../../../../context/UserContext'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import BranchLoading from '../../../../User/branchLoading/BranchLoading'
import Empty from '../../../../../Asserts/Images/norequest.png'


function UserAnalytics() {
  const {token,user} = useContext(UserContext)
  const {userId} = useParams()
  const [loading,setLoading] = useState(false)
  const [userLabWorks,setUserLabWorks] = useState([])
const navigate = useNavigate()
  const getUserLabWorks = async() =>{
    setLoading(true)
    try{
      // Fetch user lab works
      await axios.get(`${process.env.REACT_APP_SERVER_URL}/usermanagement/getAllLabWorks?userID=${userId}`, {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }
      ).then((response) => {
        // console.log("All Lab Works",response)
        setUserLabWorks(response.data.data)
      })
      .catch((error) => {
        setUserLabWorks({})
        // console.error('Error fetching user profile data:', error)
      })
    } catch (error) {
      // console.error('Error fetching user profile data:', error)
    }
    setLoading(false)
  }
  const [noUser,setNoUser] = useState(false)
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    navigate('/')
  }

  useEffect(()=>{
    getUserLabWorks()


  },[])
  return (
    loading ? <BranchLoading/> :
    <div className='ui-interviews-container'>
      
        {/* for map */}
      {userLabWorks?.length > 0 ? (
        userLabWorks.map((labWork, index) => (
          <div key={index} className='ui-interviews' onClick={()=>navigate(`/collobies/${labWork?._id}`)} >
            <div className='ui-i-info'>
              <div className='ui-i-i-date'>{labWork.requestDay +" "+labWork.requestDate+" "+labWork.requestTime}</div>
              <div className='ui-i-i-title'>{labWork.request_title}</div>
              <div className='ui-a-description'>{labWork.request_description}</div>
            </div>
            <div className='ui-a-down'>
              <span className='ui-i-i-bold'>{labWork.request_status}</span>  <span className='ui-a-reply'></span>
            </div>
          </div>
        ))
      ) : (
        <div className="norequestmaindiv">
          <img src={Empty} alt="" />
          لا توجد تحليلات
          </div>
      )}
    </div>
  )
}

export default UserAnalytics
