import React from 'react'

function WADropdownData({webinar}) {
  return (
    <div className='w-a-dd-container'>
      <div className='w-a-dd-detail'>
        <div className='w-a-dd-d-80' style={{fontWeight:'500'}}>{webinar.description}</div>
        <div className='w-a-dd-d-20'> :الوصف</div>
      </div>
      <div className='w-a-dd-detail'>
        <div className='w-a-dd-d-80'> <span style={{fontWeight:'500'}}> {webinar.presenter} </span> </div>
        <div className='w-a-dd-d-20'>:المتحدث</div>
      </div>
      {/* <div className='w-a-dd-detail'>
        <div className='w-a-dd-d-80'> {webinar.certificate_descriptions} </div>
        <div className='w-a-dd-d-20'>:الشهادة</div>
      </div> */}
      <div className='w-a-dd-detail'>
        <div className='w-a-dd-d-80'> {webinar.instructions} </div>
        <div className='w-a-dd-d-20'>:ملاحظة</div>
      </div>
      <div className='w-a-dd-detail'>
        <div className='w-a-dd-d-80'> {webinar.price} </div>
        <div className='w-a-dd-d-20'>:السعر</div>
      </div>
    </div>
  )
}

export default WADropdownData
