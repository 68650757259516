const moment = require('moment-timezone'); // To handle timezone

// Helper function to get today's date in 'DD/MM/YYYY' format

// Helper function to get today's date in 'DD/MM/YYYY' format using Saudi Arabia timezone
const getCurrentDate = () => moment.tz('Asia/Riyadh').format('DD/MM/YYYY');

// Helper function to get the current time in Saudi Arabia timezone (e.g., '9 AM', '5 PM')
const getCurrentTime = () => moment.tz('Asia/Riyadh').format('h:mm A');
const getTimeInMilliseconds = () => moment.tz('Asia/Riyadh').valueOf();
const getCurrentTimeISO = () => moment.tz('Asia/Riyadh').format();
const getCurrentDay = () => moment.tz('Asia/Riyadh').format('dddd');


module.exports = {
    getCurrentDate,
    getCurrentTime,
    getTimeInMilliseconds,
    getCurrentTimeISO,
    getCurrentDay
}