import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dummy from '../../.../../../../../../../Asserts/Images/Video.png'


function Deleted({activityVideos}) {
    const navigate = useNavigate()
    const [videoLoading, setVideoLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [activePage,setActivePage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);

    const paginationIncrement = () => {
        if (pagination < totalPages) {
          if((pagination)%2===0){
            setActivePage(activePage+2)
          }
          setPagination((prevPagination) => prevPagination + 1);

        }
      };

      const paginationDecrement = () => {
        if (pagination > 1) {
          if((pagination-1)%2===0){
            setActivePage(activePage-2)
          }
          setPagination((prevPagination) => prevPagination - 1);
        }
      };


      const getEmbedUrl = (url) => {
        if (!url) return ""; // Early return if URL is null or undefined

        const videoIdMatch = url.match(
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
        );

        if (videoIdMatch && videoIdMatch[1].length === 11) {
          // Return the URL for the default YouTube video thumbnail
          return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`; // High-resolution thumbnail
        } else {
          return ""; // Return empty string if the URL is not a YouTube video
        }
      };

  return (
    <div>
     <div className='whole-added'>
        <div className='added-video-elements-container'>

            {/* map here */}
{
  activityVideos.deletedVideos.length > 0 ? (
    activityVideos.deletedVideos.map((deletedVideo, index) => (
      <div className='added-video-elements' key={index}>
        <div className='added-details'>
          <div className='a-d-details1'>
            <div style={{ direction: 'rtl' }}>
              {deletedVideo.disableReason || 'Unknown Reason'} :سبب الحذف
            </div>
            <div>{deletedVideo.disableDate || 'Unknown Date'} :تاريخ التعديل</div>
          </div>
          <div className='a-d-video'>
            <div className='a-d-v-img'>
              <img src={deletedVideo.thumbnail || dummy} alt="" />
            </div>
            <div className='a-d-v-details'>
              <div className='a-d-v-d-title'>{deletedVideo.title || 'Unknown Title'}</div>
              {/* <div>{deletedVideo.duration || 'Unknown Duration'}</div> */}
              <div className='a-d-v-d-description'>{deletedVideo.description || 'No Description'}</div>
            </div>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div
    style={{padding:'70px',fontSize:'20px',backgroundColor:'white',borderRadius:'14px'}}
    >لم يتم حذف أي فيديو بعد</div>
  )
}
      </div>
      <div className="pagination-container">
                  {
                     totalPages===1 || <div
                    className={
                      pagination === 1
                        ? "pagination-disable"
                        : "pagination-enable"
                    }
                    onClick={paginationDecrement}
                  >
                    {"<"}
                  </div> }
                  <div>
                    <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"} onClick={paginationDecrement}>{activePage}</span>
                  </div>
                  {
                    totalPages===1 || <div>
                    <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"} onClick={paginationIncrement}>{activePage + 1}</span>
                  </div>}
                  {
                    totalPages===1 || <div
                    className={
                      pagination === totalPages
                        ? "pagination-disable"

                        : "pagination-enable"
                    }
                    onClick={paginationIncrement}
                  >
                    {">"}
                  </div>}
                </div>
    </div>
    </div>
  )
}

export default Deleted
