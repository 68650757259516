import React, { useEffect, useState } from "react";
import "./interviewdetails.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./../UserMeetings/meetingdetails.css";

import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { socket } from "../Bloodimageanalysis/globalsocket";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import Payuser from "./Payuser";
import Meetingdetails from "./Meetingdetails";
import Messagemeetiglink from "./Messagemeetiglink";
import Datetime from "./Datetime";
import axios from "axios";
import Loading from "../Loading/Loading";
import { useSessionErrorHandler } from "../Managesession";
import { getCurrentDate, getCurrentTime, getCurrentTimeISO } from "../../../HelperFunctions";
export default function Interviewdetail() {
  const handleSessionError = useSessionErrorHandler();
  const { interviewID } = useParams();
  const [MeetingData, setMeetingData] = useState({});

  const {
    setPay,
    isapprovemet,
    setIsapprovemet,
    usermeetings,
    user,

    ispay,
    setIspay,
    loading,
    setLoading,
    setSelectedTab,
    selectedTab,
    subTab,
    setSuccessAction,
    setSuccess,
    token,
    setUsersession
  } = useContext(UserContext);

  const [popup, setPopup] = useState(false);
  const [oncePostpone, setOncepostpone] = useState(false);
  function onCancel() {
    setPopup(false);
  }
  function toPostpone() {
    navigate(`/postponemeeting/${interviewID}/${MeetingData.Meeting_Zoom_ID}`);
  }
  function cancelMeeting() {
    setPopup(true);
  }

  const [showpopup, setShowpopup] = useState(false);

  const [meetingpopup, setMeetingpopup] = useState(false);
  const getInterViewDetails = async (id) => {
    try {
      setLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/meeting/getMeetingbyID/${id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          setMeetingData(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          handleSessionError(err);
          // console.log(err);
          setMeetingData({});
          setLoading(false);
        });
    } catch (err) {}
  };
  useEffect(() => {
    getInterViewDetails(interviewID);
  }, [interviewID]);

  const navigate = useNavigate();

  const goback = () => {
    navigate(-1);
  };

  const gotopay = () => {
    navigate("/payment");
    setPay("2000.00");
  };

  const handleshowpopup = () => {
    setShowpopup(!showpopup);
  };
  const updateMeetingStatus = async (status) => {
    try {
      setLoading(true);
      await axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/meeting/updateMeeting/${interviewID}?meeting_status=${status}`,
          {},
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
         if(user?.role==='admin'||user?.role==='super-admin'){ const socketData = {
            Link: `/Interviewdetail/${response.data.data._id}`,
            Message: `اجتماعك ${status} دكتور مازن`,
            userID: response.data.data.Meeting_Req_User._id,
            Deliver_To: "user",
            time: getCurrentTime(),
            date: getCurrentDate(),
            created_at: getCurrentTimeISO(),
          };
          socket.emit("notification", socketData);}
          // console.log("updated Response", response);
          setMeetingData(response.data.data);
          setPopup(false);
          setLoading(false);
        })
        .catch((err) => {
          handleSessionError(err);
          // console.log(err);
          setLoading(false);
        });
    } catch (err) {
      // console.log(err);
    }
  };
  const handlepopupdis = () => {
    setShowpopup(false);
    // alert("add its function");
  };
  const [noUser,setNoUser] = useState(false)
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    setUsersession(false)
    navigate('/login')
  }

  const gotosuccess = () => {
    setSuccessAction("/meetingnotify");
    setSuccess("meeting success");
    navigate("/success");
  };

  const handlemeeetingpop = () => {
    setMeetingpopup(!meetingpopup);
  };

  const adddeletefunction = () => {
    setMeetingpopup(false);
    // alert("add delete fucntion");
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Navbar />
      {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white',zIndex:'99999'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
      {meetingpopup && (
        <>
          <div className="meetingpopupmaindiv">
            <div className="meetingpopupcontainer">
              <div>سبب رفض اللقاء </div>
              <textarea name="" id=""></textarea>
              <button onClick={adddeletefunction}>رفض</button>
            </div>
          </div>
        </>
      )}

      <div className="maindivofinterviewdetails">
        <div className="paymentleftsideandheading">
          <div onClick={goback} className="leftarrow">
            <Icon className="leftarrowicon" icon="mdi:keyboard-arrow-left" />
          </div>
          <div className="paymenttextdiv">
            <h1>مقابلة مع الدكتور أونلاين </h1>
          </div>
        </div>

        {user?.role === "admin" &&
          MeetingData.Meeting_category === "اللقاءات المحجوزة" && (
            <>
              <div
                className="topbuttonmaindiv"
                style={{
                  background: selectedTab === "اللقاءات المحجوزة" && "#D1ECFF",
                  color: selectedTab === "اللقاءات المحجوزة" && "#2F7BD5",
                }}
              >
                <div>
                  {MeetingData.Meeting_category === "اللقاءات المحجوزة" && (
                    <> محجوز</>
                  )}
                </div>
                <span>{MeetingData.Meeting_category}: لقاء</span>
              </div>
            </>
          )}

        {user?.role === "admin" && selectedTab === "اللقاءات المرفوضة" && (
          <>
            <div
              className="topbuttonmaindiv"
              style={{
                background:
                  MeetingData.Meeting_category === "اللقاءات المرفوضة" &&
                  "#FEE2E2",
                color: selectedTab === "اللقاءات المرفوضة" && "#993333",
              }}
            >
              <div>
                {MeetingData.Meeting_category === "اللقاءات المرفوضة" && (
                  <> مرفوض</>
                )}
              </div>
              <span>{MeetingData.Meeting_category}: لقاء</span>
            </div>
          </>
        )}

        {user?.role === "admin" &&
          MeetingData.Meeting_category === "اللقاءات المنتهية" && (
            <>
              <div
                className="topbuttonmaindiv"
                style={{
                  background:
                    MeetingData.Meeting_category === "اللقاءات المنتهية" &&
                    "#C9FFD7",
                  color:
                    MeetingData.Meeting_category === "اللقاءات المنتهية" &&
                    "#48B865",
                }}
              >
                <div>
                  {MeetingData.Meeting_category === "اللقاءات المنتهية" && (
                    <> منتهي</>
                  )}
                </div>
                <span>{MeetingData.Meeting_category}: لقاء</span>
              </div>
            </>
          )}

        {user?.role === "admin" &&
          MeetingData.Meeting_category === "اللقاءات المعلقة" && (
            <>
              <div
                className="topbuttonmaindiv"
                style={{
                  background:
                    (subTab === "لقاءات جديدة" && "#FFF9DA") ||
                    (subTab === "لقاءات غير مدفوعة " && "#EFE2FF"),
                  color:
                    (subTab === "لقاءات جديدة" && "#F1DA37") ||
                    (subTab === "لقاءات غير مدفوعة " && "#9747FF"),
                }}
              >
                <div>
                  {subTab === "لقاءات جديدة" && <> جديد</>}
                  {subTab === "لقاءات غير مدفوعة " && <> غير مدفوع</>}
                </div>
                <span>{MeetingData.Meeting_category}: لقاء</span>
              </div>
            </>
          )}

        <Datetime Meeting={MeetingData} />

        {(user?.role === "admin" &&
          MeetingData.Meeting_category === "اللقاءات المعلقة") ||
        MeetingData.Meeting_category === "اللقاءات المحجوزة" ||
        MeetingData.Meeting_category === "اللقاءات المنتهية" ? (
          <>
            {
              <Messagemeetiglink
                setMeetingData={setMeetingData}
                MeetingData={MeetingData}
                setLoading={setLoading}
                loading={loading}
                handlemeeetingpop={handlemeeetingpop}
                gotosuccess={gotosuccess}
              />
            }
          </>
        ) : (
          <></>
        )}
        {!(MeetingData?.Meeting_category === "اللقاءات المحجوزة") ? (
          <></>
        ) : (
          <>
            {user?.role === "user" ? (
              <>
                <div className="admainapprovemain">
                  <div className="approvedmaindiv">
                    <div className="toptitleof">ملاحظات مهمة</div>

                    <div className="descraptiondivofmet">
                      <ul>
                        <li>مدة المقابلة نصف ساعة</li>
                        <li>الحضور في المعاد</li>
                        <li>
                          في حالة تأخرك عن القابلة سيتم انهاء المقابلة بشكل
                          تلقائي بعد 10 دقائق
                        </li>
                        <li>حضر كل التحاليل حتى يكون كل شيء واضح</li>
                        <li>حضر اسئلتك للدكتور حتى تستفيد بكامل الوقت</li>
                      </ul>
                    </div>

                    {(user?.role === "user" ||
                      user?.role === "super-admin" ||
                      user?.role === "admin") &&
                      MeetingData.Meeting_category === "اللقاءات المحجوزة" && (
                        <Meetingdetails Meeting={MeetingData} />
                      )}
                    <Payuser
                      Meeting_category={MeetingData.Meeting_category}
                      interviewID={interviewID}
                      gotopay={gotopay}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}

        {user?.role === "user" &&
          MeetingData.Meeting_category === "اللقاءات المعلقة" && (
            <>
              <div className="maindivadmintab">
                {user?.role === "user" &&
                  MeetingData.Meeting_category === "اللقاءات المعلقة" && (
                    <>
                      <div className="waitingforapprovetext">
                        <p style={{ color: "#F1DA37" }}>
                          بانتظار موافقة الأدمن
                        </p>
                      </div>
                    </>
                  )}
              </div>
            </>
          )}
              <div className="buttonsmaindiv2">
        {MeetingData?.Meeting_category==="لقاءات غير مدفوعة"&& <> <button onClick={() => updateMeetingStatus("اللقاءات المرفوضة")}>
            رفض اللقاء
          </button>
          <button onClick={() => updateMeetingStatus("اللقاءات المحجوزة")}>
            تأكيد اللقاء
          </button> </>}

        </div>

        {MeetingData?.Replayed_Messages?.length > 0 ? (
          <div className="policies-heading">ملاحظات الدكتور</div>
        ) : (
          ""
        )}
        <div className="maindivadmintab1">
          {(user?.role === "admin" ||
            user?.role === "user" ||
            user?.role === "super-admin") &&
            MeetingData.Replayed_Messages?.length > 0 &&
            MeetingData?.Replayed_Messages.map((item, index) => {
              return (
                <>
                  <div key={index}>
                    <div className="policies-in-interview">
                      <ul>
                        <li>{item}</li>
                      </ul>
                    </div>
                  </div>
                </>
              );
            })}
        </div>

        {user?.role === "admin" &&
          MeetingData.Meeting_category === "اللقاءات المرفوضة" && (
            <>
              <div className="maindivadmintab givedisplay">
                <div>الدكتور غير متاح</div>: سبب الرفض
              </div>
            </>
          )}

        {popup && (
          <>
            <div className="m-d-c-p-container">
              <div
                className="m-d-bg-black-shadow"
                onClick={() => setPopup(false)}
              ></div>
              <div className="m-d-cancel-popup">
                <div className="m-d-c-p-ttext">
                  هل أنت متأكد انك تريد الغاء المقابلة
                </div>
                <div className="m-d-c-p-btn">
                  <button onClick={()=>updateMeetingStatus('اللقاءات المرفوضة')}>نعم</button>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="m-d-mid-text"></div>

        {MeetingData?.Meeting_category === "اللقاءات المحجوزة" && (
          <div className="m-d-mid-head-text">
            بالرجاء تحضير تحليل صورة دم كاملة قبل المقابلة
          </div>
        )}

        <div className="m-d-end-btns">
          {MeetingData?.Meeting_category === "اللقاءات المحجوزة" && (
            <div className="m-d-cancel-btn">
              <button onClick={cancelMeeting}>الغاء المقابلة</button>
            </div>
          )}
          {MeetingData?.Meeting_category === "اللقاءات المحجوزة" &&
            (user?.role === "admin" ||
              user?.role === "super-admin" ||
              (MeetingData.updatedByUser === false &&
                user?.role !== "user")) && (
              <>
                <div className="m-d-postpone-btn">
                  <button onClick={toPostpone}>تأجيل المقابلة</button>
                </div>
                <div className="m-d-ending-text-mid">
                  يسمح لك بتأجيل المقابلة لمرة واحدة فقط
                </div>
              </>
            )}
        </div>

        {user?.role === "admin" &&
          MeetingData.Meeting_category === "اللقاءات المعلقة" && (
            <>
              {subTab === "لقاءات غير مدفوعة " && (
                <>
                  <div className="maindivadmintab">قام المريض بتأكيد الحجز</div>

                  <div className="buttondivindiv" onClick={handleshowpopup}>
                    إغلاق
                  </div>
                </>
              )}
            </>
          )}
      </div>

      {showpopup && (
        <>
          <div className="showpopupmain">
            <div className="showpopupcontainerdiv">
              <div className="titledivpopup">
                هل أنت متأكد انك تريد إغلاق اللقاء
              </div>
              <div className="butondivpopup" onClick={handlepopupdis}>
                نعم
              </div>
            </div>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
}
