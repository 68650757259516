import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../../Navbar/Navbar";
import Footer from "../../../Footer/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../../../context/UserContext";

function CodeEdit() {
    const [occasion, setOccasion] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const {id} = useParams();
  const {token} =  useContext(UserContext)
  const navigate = useNavigate();

  useEffect(() => {
    // Data and the code
    async function fetchData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/discounts/getDiscountById/${id}`,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log('response',response)
        setOccasion(response.data.data.reason);
        setDiscountCode(response.data.data.code);
        setDiscountRate(response.data.data.percentage);
        const [day, month, year] = response.data.data.endDate.split("/");
        setEndDate(`${year}-${month}-${day}`);
        const [startDay, startMonth, startYear] = response.data.data.startDate.split("/");
        setStartDate(`${startYear}-${startMonth}-${startDay}`);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  function Back() {
    navigate("/discount");
  }

  async function editCode() {
    const formattedStartDate = startDate.split("-").reverse().join("/");
    const formattedEndDate = endDate.split("-").reverse().join("/");
    try {
      await axios.patch(`${process.env.REACT_APP_SERVER_URL}/discounts/updateDiscount/${id}`,{
        reason: occasion,
        code: discountCode,
        percentage: discountRate,
        startDate: formattedStartDate,
        endDate: formattedEndDate
      },{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      alert("تم تعديل الكود بنجاح");
      navigate("/alldiscounts");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  }

  function Cancel () {
    navigate('/alldiscounts')
}

  return (
    <div>
      {/* Navbar  */}
      <div>
        <Navbar />
      </div>

      {/* Body  */}
      <div className="discount-body">
        <div className="discount-body-inner">
          <div className="discount-header">
            {/* Back button  */}
            <div className="discount-header-icon" onClick={Back}>
              <Icon icon="weui:back-filled" />
            </div>
            <div className="discount-header-title">تعديل رموز الخصم</div>
          </div>
          <div className="discount-form-div">
            <div className="discount-section">
              {/* Heading  */}

              <div className="discount-heading">
                <h1>الخصم على جميع الخدمات</h1>
              </div>
              <div className="discount-container">
                {/* Occassion input  */}
                <div className="discount-input">
                  <label htmlFor="discount-name">المناسبة</label>
                  <input
                    type="text"
                    id="discount-name"
                    value={occasion}
                    onChange={(e) => setOccasion(e.target.value)}
                  />
                </div>
                {/* Discount Code  */}
                <div className="discount-input">
                  <label htmlFor="discount-amount">كود الخصم</label>
                  <input
                    type="text"
                    id="discount-amount"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                </div>

                {/* Discount Rate  */}
                <div className="discount-input">
                  <label htmlFor="discount-expiration">نسبة الخصم</label>
                  <input
                    type="text"
                    id="discount-expiration"
                    value={discountRate}
                    onChange={(e) => setDiscountRate(e.target.value)}
                  />
                </div>

                {/* Date Changes  */}
            <div className="discount-input">
                      <label htmlFor="discount-expiration">تاريخ صلاحية الكود</label>
                      <div className='discount-expiration-date-container'>
                        <div>
                            <div>
                                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                            <div>من</div>
                        </div>
                        <div>
                            <div>
                                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div>الى</div>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
            {/* Submit Button  */}
            <div className="discount-button">
            <button style={{ backgroundColor: "red" }} onClick={Cancel}>الغاء</button>
              <button onClick={editCode}>حفظ</button>
            </div>


          </div>
        </div>
      </div>

      {/* Footer  */}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default CodeEdit;
