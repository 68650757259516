import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import Calendar from 'react-calendar'
import './postponemeeting.css'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { UserContext } from '../../../../context/UserContext'
import { socket } from '../../Bloodimageanalysis/globalsocket'
import { getCurrentDay, getCurrentTime, getCurrentTimeISO } from '../../../../HelperFunctions'
function PostponeMeeting() {
  const {interviewID,zoomMeetingId} = useParams();
  const {token,user} = useContext(UserContext)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
    const [scheduleData,setScheduleData] = useState([]);
      const [allowedDates,setAllowedDates] = useState ([])

      useEffect(() => {
        const fetchMeetingTimings = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/meetingtiming/gettimings`);
            if (response.data.data.timings.length > 0) {
              const fetchedDays = response.data.data.timings;

              // Map the days and include the timings in the allowedDates state
              const dayss = fetchedDays.map((it) => {
                const array = it.date.split('-');
                const [year, month, day] = array.map((part) => parseInt(part, 10));
                const date = new Date(Date.UTC(year, month - 1, day));

                // Convert to Saudi timezone
                const saudiDate = new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }));

                // Return date and timings together
                return { date: saudiDate, timings: it.timings };
              });
              console.log('daysss',dayss)
              setAllowedDates(dayss);
            } else {
              console.warn('No timings found');
            }
          } catch (error) {
            // console.error('Error fetching meeting timings:', error);
          }
        };


        fetchMeetingTimings();
    }, []);

    const isDateSelectable = (date) => {
      // Log the date to check its value

      // console.log('allowdates',allowedDates)
      // Now we loop through allowedDates and check if the date matches
      return allowedDates.some(
        (allowedDateObj) =>
          allowedDateObj.date.getFullYear() === date.getFullYear() &&
          allowedDateObj.date.getMonth() === date.getMonth() &&
          allowedDateObj.date.getDate() === date.getDate()
      );
    };
      const disableDates = ({ date, view }) => {
        // Disable if the date is not in the allowedDates array
        if (view === 'month') {
          return !isDateSelectable(date);
        }
      };
      const [value, setValue] = useState(new Date());
      const [showPopState, setShowPopState] = useState(false);
      const [activeItem, setActiveItem] = useState("");
      const [submitAttempt, setSubmitAttempt] = useState(false);
      const handleClick = (time) => {
        setActiveItem(time);
      };
      const [noUser,setNoUser] = useState(false)
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    navigate('/login')
  }
      const updateMeeting = async () => {
        setSubmitAttempt(true);

        if (value && activeItem) {
            try {
                setLoading(true);

                // Extract time and date
                const [hours, minutes] = activeItem.split(":").map(Number);
                const combinedDateTime = new Date(value);

                combinedDateTime.setHours(hours);
                combinedDateTime.setMinutes(minutes);
                combinedDateTime.setSeconds(0);
                combinedDateTime.setMilliseconds(0);

                // Format the meeting date
                const meetingDate = moment(value).format("DD/MM/YYYY");
                const meetingHour = moment(combinedDateTime).format("h:mm A");
                const meetingDay = moment(value).format("dddd");

                const formattedDateTime = combinedDateTime.toISOString();

                // Send a PATCH request to update the meeting
                const response = await axios.patch(
                    `${process.env.REACT_APP_SERVER_URL}/postponemeeting/updateMeetingTime/${interviewID}?adminId=${user._id}`,

                    {
                        newTime: formattedDateTime,
                        Meeting_Date: meetingDate,
                        Meeting_Hour: meetingHour,
                        Meeting_Day: meetingDay,
                        zoomMeetingId:zoomMeetingId,
                        updatedBy:user.role
                    },
                    {headers: {
                        authorization: `Bearer ${token}`,
                    }}
                );
                if(response.status===200){
                    alert("تم تأجيل الموعد بنجاح")
                    if(user?.role==='admin'||user.role==='super-admin'){ const socketData = {
                      Link: `/Interviewdetail/${response.data.data._id}`,
                      Message: `قام ${user?.first_name} بتحديث وقت الاجتماع`,
                      userID: response.data.data.Meeting_Req_User._id,
                      Deliver_To: "user",
                      time: getCurrentTime(),
                      date: getCurrentDay(),
                      created_at: getCurrentTimeISO(),
                    };
                    socket.emit("notification", socketData);}
                    navigate(`/interviewdetail/${interviewID}`)
                }

                // console.log("Meeting updated:", response.data);
            } catch (error) {
                // console.error("Failed to update the meeting:", error);
            } finally {
                setLoading(false);
            }
        }
    };
    const handleDateChange = (date) => {
      setValue(date);
      const selectedDate = moment(date).format("YYYY-MM-DD"); // Format selected date to match fetched data

      // Find the entry that matches the selected date
      const selectedDayData = allowedDates.find(
        (day) => moment(day.date).format("YYYY-MM-DD") === selectedDate
      );
        // console.log(selectedDayData)
      // If timings exist for the selected date, update scheduleData with timings
      if (selectedDayData && selectedDayData.timings) {
        setScheduleData(selectedDayData.timings);
      } else {
        setScheduleData([]); // Clear if no timings available for the selected date
      }

      setShowPopState(true); // Set pop state true on date selection
    };
  return (
    <div>
      <div><Navbar/></div>
      {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
      <div>
        <div className='p-d-direction-text'>
        اختر موعد اخر للمقابلة
        </div>
        <div className='p-m-calender-container'>
            <Calendar
            onChange={handleDateChange}
            locale="ar"
            tileDisabled={disableDates}
            minDate={new Date()}
            value={value}
            />
        </div>
        {
            showPopState && <>
            <div className='p-m-timing-btns'>
            <div className="showlinediv">المواعيد المتاحة</div>
            <div className='p-m-empty-space'></div>
                <div className="datesselectmain">
                  {scheduleData.map((schedule, index) => (
                    <button
                      key={index}
                      className={
                        (submitAttempt && !activeItem) ||
                        activeItem === schedule.time
                          ? activeItem === schedule.time
                            ? "activatetim"
                            : "input-error"
                          : ""
                      }
                      onClick={() => handleClick(schedule.time)}
                    >
                      {schedule.time}
                    </button>
                  ))}
                </div>
            </div>
            </>
        }

       { (value && activeItem) && <div className='p-m-confirm-btn'>
            <button onClick={updateMeeting} >تأكيد المعاد</button>
        </div>}

      </div>
      <div><Footer/></div>
    </div>
  )
}

export default PostponeMeeting
