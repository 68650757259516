import React from 'react'
import samplePic from './../../../../../Asserts/Images/test.png'
import sampleProfile from './../../../../../Asserts/Images/DummyProfile.png'
import { useNavigate } from 'react-router-dom'

function VideosDeleted({videosDeleted}) {
  // console.log(videosDeleted)
  const getEmbedUrl = (url) => {
    if (!url) return ""; // Early return if URL is null or undefined

    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
    );

    if (videoIdMatch && videoIdMatch[1].length === 11) {
      // Return the URL for the default YouTube video thumbnail
      return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`; // High-resolution thumbnail
    } else {
      return ""; // Return empty string if the URL is not a YouTube video
    }
  };

  const navigate = useNavigate()
  return(
    <div className="videoedited-profile-wholedata">
      {
        Array.isArray(videosDeleted) && videosDeleted.length > 0 && videosDeleted.map((item) => {
          return (
            <div className="videoedited-profile-container">
              <div className="videodeleted-profile-box">
                <div className="videoedited-profile-box-editings">
                  {item.deletedVideos.map((video) => <div>{video.disableReason} : سبب الحذف</div>)}
                  <div>{item.date} : تاريخ التعديل</div>
                </div>
                <div className="videoedited-profile-box-line"></div>
                <div className="videoedited-profile-box-profile">
                  <div>{item?.userId?.name}</div>
                  <div className="videoedited-profile-profile-img">
                    <img src={item?.userId?.photo} alt="Profile Pictur" />
                  </div>
                </div>
              </div>
              {item.deletedVideos.length>0? item.deletedVideos.map((video) => (
              <div onClick={()=>navigate(`/videos/${video?._id}`)} className="managerdetails-video-box">
                <div className="managerdetails-video-box-left">
                {
                  video.youtubeVideoUrl ?
                  <img src={getEmbedUrl(video.youtubeVideoUrl)} alt="" /> :
                  (video.videoUrl && <video src={video.videoUrl}></video>)
                }
                </div>

                  <div className="managerdetails-video-box-right">
                    <div className='managerdetails-right-titletime'>
                      <div className="managerdetails-right-heading">{video.title}</div>
                    </div>
                    <div className="managerdetails-right-description">{video.description}</div>
                  </div>

              </div>
               )):'no video Deleted on this date'}
            </div>
          )
        })
      }
    </div>
  )
}

export default VideosDeleted;