import React, { useRef, useState, useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import axios from "axios";
import "./Slider.css";
import bookimages from "../../../Asserts/Images/banner.png";
import { Pagination, Autoplay } from "swiper/modules";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import heic2any from "heic2any";
import { useSessionErrorHandler } from "../Managesession";

const slides = [
  {
    imageUrl: bookimages,
    title: "محاضرة مجانية في التغذية",
    description: "سجل حضورك واحضر المحاضر المجانية مع الدكتور يوم السبت 10/8",
  },
  {
    imageUrl: bookimages,
    title: "محاضرة في اللياقة البدنية",
    description: "تعلم أساسيات اللياقة البدنية مع أفضل المدربين",
  },
  {
    imageUrl: bookimages,
    title: "دورة في الطهي الصحي",
    description: "شارك معنا في دورة الطهي الصحي وتعلم وصفات جديدة",
  },
  {
    imageUrl: bookimages,
    title: "دورة في الطهي الصحي",
    description: "شارك معنا في دورة الطهي الصحي وتعلم وصفات جديدة",
  },
];

export default function Slider({blockPopup,setBlockPopup}) {

const handleSessionError = useSessionErrorHandler();

  const [slides, setSlides] = useState([]);
  const { user, setUser, loading,token , setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const [deleteIndex, setDeleteIndex] = useState(null);
  

  const [popupshow, setPopupshow] = useState(null);
  const [deletepopup, setDeletepopup] = useState(false);
  const [bannerloading, setBannerLoading] = useState(false);
  const showpopup = (index, e) => {
    
    e.stopPropagation();
    
    setPopupshow(popupshow === index ? null : index); // Toggle popup visibility
  };

  const showdelete = (e) => {
    try {
      e.stopPropagation();
      setDeletepopup(!deletepopup);
    } catch (Err) {
      // console.log(Err);
    }
  };

  const getAllBanners = async () => {
    try {
      setBannerLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/banner/getall`,{
          headers: { authorization: `Bearer ${token}` },
      }
      );
      // console.log(response.data);
      setSlides(response.data.response);
      setBannerLoading(false);
    } catch (err) {
      // console.log(err);
      handleSessionError(err)
      setBannerLoading(false);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/userDetails/${user._id}`, {
          headers: {
            authorization: `Bearer ${token}`
          }
        });
        // console.log("User data:", response.data);
        const userData = response.data.data;
        setUser(userData)
      } catch (error) {
        handleSessionError(error);
        // console.error("Error fetching user data:", error);
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    getAllBanners();
  }, []);

  const performdeletaction = async () => {
    
    setDeletepopup(false);
    setLoading(true);
    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/banner/delete/${deleteIndex}`,{
          headers: { authorization: `Bearer ${token}` },
      }
      )
      .then((response) => {
        // console.log(response.data);
        getAllBanners();
        setLoading(false);
      })
      .catch((err) => {
        handleSessionError(err)
        // console.log(err);
        setLoading(false);
      });
  };

  const gotouplaodvideo = (e, id) => {
    if(user?.role === 'admin'){
      if(!user?.ableToUploadBannerAndVideos)
      {
        setBlockPopup(true)
        return
      }
    }
    e.stopPropagation(); // Prevents the event from bubbling up
    navigate(`/updatedbanner/${id}`); // Interpolating the id into the URL
  };
  const handleImageLoadError = async (event, imageUrl) => {
    if (imageUrl.endsWith(".heic")) {
      try {
        // console.log("Fetching HEIC image from:", imageUrl);
        const response = await axios.get(imageUrl, {
          responseType: "arraybuffer",
        });
        // console.log("HEIC image fetched successfully");

        const blob = new Blob([response.data], { type: "image/heic" });
        // console.log("Blob created:", blob);

        const convertedBlob = await heic2any({
          blob: blob,
          toType: "image/jpeg",
        });
        // console.log("HEIC to JPEG conversion result:", convertedBlob);

        if (convertedBlob) {
          const url = URL.createObjectURL(convertedBlob);
          event.target.src = url;
        } else {
          // console.error("Conversion failed: No result from heic2any");
        }
      } catch (error) {
        // console.error("Error during HEIC image handling:", error);
      }
    }
  };

  return (
    <>
      <div className="widthswiper">
        {bannerloading && (
          <div className="minloadingdiv">
            <Icon
              icon="eos-icons:bubble-loading"
              width="1.2em"
              height="1.2em"
            />
          </div>
        )}



        {deletepopup && (
          <div className="deletemaindiv" style={{ zIndex: "2" }}>
            <div className="deletecontainer">
              <div className="deletetitle">
                <div>سبب الحذف</div>
                <div className="CloseHadafPopup" onClick={()=>setDeletepopup(false)}>x</div>
              </div>
              <textarea
                name=""
                id=""
                placeholder="مختلف عن موضوع القسم"
              ></textarea>
              <button onClick={performdeletaction}>
                <Icon icon="ic:baseline-delete" width="1.2em" height="1.2em" />
                حذف
              </button>
            </div>
          </div>
        )}

        <Swiper modules={[Autoplay]} loop={true}  speed={2000}  autoplay={{
        delay: 3000,
        disableOnInteraction: false, 
        reverseDirection: false ,
      }} className="mySwiper">
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="mainswiperdiv" onClick={() => window.location.href = slide.link}>
                <img
                  src={slide.bannerPic}
                  alt={slide.title}
                  onError={(e) =>
                    handleImageLoadError(
                      e,
                      `${slide.bannerPic}`
                    )
                  }
                />
                <div className="positionabsolutediv">{slide.title}</div>
                <div className="descraptionabso">{slide.description}</div>
                {user && (((user?.role === "admin")&&(user?.ableToUploadBannerAndVideos))||(user.role === "super-admin")) && (
                  <div className="threedots">
                    <Icon
                      onClick={(e) => showpopup(index, e)}
                      icon="pepicons-pop:dots-y"
                      width="1.2em"
                      height="1.2em"
                    />
                    {popupshow === index && (
                      <div
                        className="popupmain givingtopvalue"
                        style={{ textAlign: "center" }}
                      >
                        <div onClick={(e) => gotouplaodvideo(e, slide._id)}>
                          تعديل
                        </div>
                        <div
                          onClick={(e) => {
                            if(user?.role === 'admin'){
                              if(!user?.ableToUploadBannerAndVideos)
                              {
                                setBlockPopup(true)
                                return
                              }
                            }
                            showdelete(e);
                            setDeleteIndex(slide._id);
                          }}
                        >
                          حذف
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
