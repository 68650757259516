import React, { useContext, useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import { Icon } from '@iconify/react/dist/iconify.js'
import './webinarattendant.css'
import { useNavigate, useParams } from 'react-router-dom'
import WADropdownData from './WADropdownData'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'

function WebinarAttendants() {
  const {id} = useParams()
  const [dropdown,setDropdown] = useState(true)
  const [search, setSearch] = useState('');
  const onSearch = (e) => {
    setSearch(e.target.value);
  };
  function handleSearch() {}
  const navigate = useNavigate();
  const {user} = useContext(UserContext)
  const [pagination, setPagination] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [webinar, setWebinar] = useState({});
  const [noUser,setNoUser] = useState(false)
  const [participants, setParticipants] = useState([]);
  useEffect(()=>{
    if(!user){
      setNoUser(true)
    }
  },[])
  function toDashBoard () {
    navigate('/login')
  }
  const fetchWebinarData =async ()=>{
    //fetch data
    try{
      await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinarForm/getWebinarFormsByWebinarId/${id}`).then((response) => {
        console.log('webinar data', response.data.webinarData);
        console.log('webinar data2', response.data.data);
        setWebinar(response.data.webinarData);
        setParticipants(response.data.data);
    }).catch((err) => {
      console.log('webinar data', err);
  })

    }catch{

    }

  }
  function toWebinarForm (id) {
    navigate(`/webinarsubmitted/${id}`)
  }
  useEffect(()=>{
    fetchWebinarData()

  },[]  )
  const paginationIncrement = () => {
    if (pagination < totalPages) {
      if (pagination % 2 === 0) {
        setActivePage(activePage + 2);
      }
      setPagination((prevPagination) => prevPagination + 1);
    }
  };

  const paginationDecrement = () => {
    if (pagination > 1) {
      if ((pagination - 1) % 2 === 0) {
        setActivePage(activePage - 2);
      }
      setPagination((prevPagination) => prevPagination - 1);
    }
  };

  function toEvents () {
    navigate('/events')
  }

  return (
    <div>
      <div>
        <Navbar/>
      </div>
      {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
      <div>
        <div className='webinar-attendant-heading'>
          <div className='w-a-h-icon' onClick={toEvents}>
          <Icon icon="weui:back-filled" />
          </div>
          <div className='w-a-heading-text'>
            <div className='w-a-h-t-about-webinar'>
              <div className='w-a-h-t-a-w-downup' onClick={()=>setDropdown(!dropdown)}> { dropdown ? <Icon icon="icon-park:up" /> : <Icon icon="icon-park:down" /> }</div>
              <div>{webinar.title}</div>
            </div>
            <div className='w-a-h-t-downer'>
            {webinar.time}- {webinar.date}
            </div>
          </div>
          {
            dropdown &&
            <>
            <WADropdownData webinar={webinar} />
            </>
          }
        </div>
        <div className='w-a-bodie'>
          <div className='w-a-search'>
            <div className='w-a-search-head'>{webinar?.participants?.length} عدد المسجلين بالندوة</div>
            <div className='w-a-search-outer'>
            <div className='w-a-search-inner'>
                    <div className='au-search-cont2'>
                        <div className='au-s-input'>
                            <input
                                type='text'
                                placeholder='البحث بالاسم أو برقم الجوال'
                                value={search}
                                onChange={onSearch}
                            />
                        </div>
                        <div onClick={handleSearch}>
                            <Icon icon="ri:search-line" />
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className='w-a-attendants-outer'>
            <div className='w-a-attendants-inner'>
              {/* map here */}
            {participants && participants.length>0? participants.map((participant, index) => (
              <div className='w-a-a-box' key={index} onClick={()=>toWebinarForm(participant._id)} >
                <div className='w-a-a-b-date'>{participants?.uploadingDay}{participant?.uploadingDate}{participant?.uploadingTime}</div>
                <div className='w-a-a-b-main-info'>
                  <div className='w-a-a-b-mi-infodiv'>
                    <div>{participant?.phone || 'Unknown Phone'}</div>
                    <div>{participant?.sex || 'Unknown Gender'}</div>
                  </div>
                  <div className='w-a-a-b-mi-infodiv'>
                    <div>{participant.city || 'Unknown Location'}</div>
                    <div>سنة {participant.age || 'Unknown Age'}</div>
                  </div>
                  <div className='w-a-a-b-mi-infodiv'>
                    <div>{participant?.first_name || 'Unknown Name'}</div>
                    <div>{participant?.profession || 'Unknown Profession'}</div>
                  </div>
                </div>
                <div className='w-a-a-b-questions'>{participant.questions || 'No Questions'}</div>
              </div>
            )): <div>لا يوجد مشتركين</div>}
            </div>
            <div className="pagination-container">
            {totalPages === 1 || (
              <div
                className={
                  pagination === 1 ? "pagination-disable" : "pagination-enable"
                }
                onClick={paginationDecrement}
              >
                {"<"}
              </div>
            )}
            <div>
              <span
                className={
                  pagination === activePage
                    ? "pagination-active"
                    : "pagination-notactive"
                }
                onClick={paginationDecrement}>
                {activePage}
              </span>
            </div>
            {totalPages === 1 || (
              <div>
                <span
                  className={
                    pagination === activePage + 1
                      ? "pagination-active"
                      : "pagination-notactive"
                  }
                  onClick={paginationIncrement}>
                  {activePage + 1}
                </span>
              </div>
            )}
            {totalPages === 1 || (
              <div
                className={
                  pagination === totalPages
                    ? "pagination-disable"
                    : "pagination-enable"
                }
                onClick={paginationIncrement}
              >
                {">"}
              </div>
            )}
          </div>
          </div>
        </div>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}

export default WebinarAttendants
