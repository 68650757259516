import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function ClosedAnalysis({activityAnalysis}) {
    const navigate = useNavigate()
    const [videoLoading, setVideoLoading] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [activePage,setActivePage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);

    const paginationIncrement = () => {
        if (pagination < totalPages) {
          if((pagination)%2===0){
            setActivePage(activePage+2)
          }
          setPagination((prevPagination) => prevPagination + 1);

        }
      };
      const paginationDecrement = () => {
        if (pagination > 1) {
          if((pagination-1)%2===0){
            setActivePage(activePage-2)
          }
          setPagination((prevPagination) => prevPagination - 1);
        }
      };
  return (
    <div className='whole-added'>
    <div className='added-video-elements-container'>

        {/* map here */}

{activityAnalysis.rejectedAnalysis.length > 0 ? (
  activityAnalysis.rejectedAnalysis.map((rejectedAnalysis, index) => (
    <div className='added-video-elements' key={index}>
      <div className='added-details'>
        <div className='a-d-details2'>
          <div style={{ direction: 'rtl' }}>
            {/* {'التعليقات'} - {'الفيديو'} - {'الوصف'} :تم تعديل */}
          </div>
          <div>{rejectedAnalysis.closing_date || 'Unknown Date'} :تاريخ التعديل</div>
        </div>
        <div className='a-d-video1'>
          <div className="notificationcardsmain">
            <div className="notificationcardleft">
              {rejectedAnalysis.request_status || 'Unknown Status'}
            </div>
            <div className="notificatoncardright">
              <div className="noticardtopdiv">
                <div className="notiardtopleft">
                  {rejectedAnalysis?.requested_by.phone_number || 'Unknown Phone'}
                </div>
                <div className="noticardtopright">
                  <div>{rejectedAnalysis.requested_by.first_name || 'Unknown'}</div>
                  <div>{rejectedAnalysis.request_title || 'Unknown Title'}</div>
                  <div className='givepurplecolor'>{rejectedAnalysis.requestDate  || 'Unknown Request Day'}</div>
                </div>
              </div>
              <div className="noticardbottomdiv">
                {rejectedAnalysis.request_description || 'No Description'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
) : (
  <div
  style={{padding:'70px',fontSize:'20px',backgroundColor:'white',borderRadius:'14px'}}
  >لا يوجد تحليل مرفوض</div>
)}
  </div>
  <div className="pagination-container">
              {
                 totalPages===1 || <div
                className={
                  pagination === 1
                    ? "pagination-disable"
                    : "pagination-enable"
                }
                onClick={paginationDecrement}
              >
                {"<"}
              </div> }
              <div>
                <span className={ pagination === (activePage) ? 'pagination-active' : "pagination-notactive"} onClick={paginationDecrement}>{activePage}</span>
              </div>
              {
                totalPages===1 || <div>
                <span className={ pagination === (activePage+1) ? 'pagination-active' : "pagination-notactive"} onClick={paginationIncrement}>{activePage + 1}</span>
              </div>}
              {
                totalPages===1 || <div
                className={
                  pagination === totalPages
                    ? "pagination-disable"

                    : "pagination-enable"
                }
                onClick={paginationIncrement}
              >
                {">"}
              </div>}
            </div>
</div>
  )
}

export default ClosedAnalysis
