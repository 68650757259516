import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateWebinar.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

export default function CreateWebinar() {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        link: '',
        instructions: '',
        time: '',
        status: false,
        eventPicture: null,
        date: '',
        presenter: '',
        price: '',
        certificate_descriptions: '',
        ZoomMeetingPasscode: '',
        ZoomMeeting_id: ''
    });

    const [webinars, setWebinars] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate();

    const fetchWebinars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinar/getall`);
            setWebinars(response.data.data);
        } catch (error) {
            // Handle error
        }
    };

    useEffect(() => {
        fetchWebinars();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files[0]
            });
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        setLoading(true); // Start loading animation

        try {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/webinar/create`, data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            alert('Webinar created successfully!');
            fetchWebinars();
            navigate('/createEvents'); // Change this to your desired path
        } catch (error) {
            // Handle error
        } finally {
            setLoading(false); // Stop loading animation
        }
    };

    const handleEdit = (id) => {
        navigate(`/edit-webinar/${id}`);
    };

    const handleStatusChange = async (id, status) => {
        try {
            await axios.patch(`${process.env.REACT_APP_SERVER_URL}/webinar/editwebinar/${id}`, { status });
            fetchWebinars();
        } catch (error) {
            // Handle error
        }
    };

    return (
        <>
            <Navbar />
            <div className="create-webinar-container">
                <form className="webinar-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="eventPicture">صورة الحدث</label>
                        <input
                            type="file"
                            name="eventPicture"
                            accept="image/*"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="title">عنوان الندوة عبر الويب</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            placeholder="أدخل عنوان الويبينار"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">وصف</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="أدخل الوصف"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="link">رابط الويبينار</label>
                        <input
                            type="text"
                            name="link"
                            value={formData.link}
                            onChange={handleChange}
                            placeholder="أدخل رابط الويبينار"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="instructions">تعليمات</label>
                        <textarea
                            name="instructions"
                            value={formData.instructions}
                            onChange={handleChange}
                            placeholder="أدخل التعليمات"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="time">وقت الحدث</label>
                        <input
                            type="text"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            placeholder="8 مساءً بتوقيت مكة المُكرمة"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="date">تاريخ</label>
                        <input
                            type="text"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            placeholder="30 October 2024"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="presenter">مقدم الحدث</label>
                        <input
                            type="text"
                            name="presenter"
                            value={formData.presenter}
                            onChange={handleChange}
                            placeholder="اسم مقدم الحدث"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="price">السعر</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            placeholder="أدخل السعر"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="certificateDescription">وصف الشهادة</label>
                        <textarea
                            name="certificate_descriptions"
                            value={formData.certificate_descriptions}
                            onChange={handleChange}
                            placeholder="أدخل وصف الشهادة"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zoomPasscode">كلمة مرور اجتماع زوم</label>
                        <input
                            type="text"
                            name="ZoomMeetingPasscode"
                            value={formData.ZoomMeetingPasscode}
                            onChange={handleChange}
                            placeholder="أدخل كلمة مرور زوم"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zoomPasscode">معرف اجتماع زووم</label>
                        <input
                            type="text"
                            name="ZoomMeeting_id"
                            value={formData.ZoomMeeting_id}
                            onChange={handleChange}
                            placeholder="أدخل معرف اجتماع زوم"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="status">حالة</label>
                        <div className="webinar-form-check">
                            <input
                                type="checkbox"
                                name="status"
                                checked={formData.status}
                                onChange={handleChange}
                            />{' '}
                            <div>نشيط</div>
                        </div>
                    </div>
                    <button type="submit" className="submit-btn" disabled={loading}>
                        {loading ? 'جارٍ التحميل...' : 'إنشاء حدث'}
                    </button>
                </form>

                {/* Loading spinner */}
                {loading && (
                    <div className="loading-spinner">
                        <div className="spinner"></div>
                    </div>
                )}

                {/* Display all webinars */}
                <div className="webinar-list">
                    <h2>جميع الأحداث</h2>
                    {webinars.map((webinar) => (
                        <div key={webinar._id} className="webinar-item">
                            <h3>{webinar.title}</h3>
                            <p>{webinar.description}</p>
                            <p>{webinar.time}<strong> :وقت</strong></p>
                            <p>{webinar.status ? 'نشيط' : 'غير نشط'}<strong> :الحالة</strong></p>
                            <div className="webinar-actions">
                                <div className="status-toggle">
                                    <label>
                                        <input
                                            type="radio"
                                            name={`status-${webinar._id}`}
                                            checked={webinar.status === true}
                                            onChange={() => handleStatusChange(webinar._id, true)}
                                        />{' '}
                                        على
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name={`status-${webinar._id}`}
                                            checked={webinar.status === false}
                                            onChange={() => handleStatusChange(webinar._id, false)}
                                        />{' '}
                                        عن
                                    </label>
                                </div>
                            </div>
                            <div className="webinar-end-btn">
                                <button className="edit-btn" onClick={() => handleEdit(webinar._id)}>
                                    يحرر
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}
