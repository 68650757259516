import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './UpdateWebinar.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'; 
import { UserContext } from '../../../context/UserContext';

export default function UpdateWebinar() {
    const { id } = useParams();
    const navigate = useNavigate();
    const {user} = useContext(UserContext)

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        link: '',
        instructions: '',
        time: '',
        status: false,
        presenter: '',
        price: '',
        picture: '',
        certificate_descriptions: '',
        ZoomMeetingPasscode: '',
        ZoomMeeting_id:''

    });

    const [newPicture, setNewPicture] = useState(null); // State to handle new picture file

    useEffect(() => {
        const fetchWebinar = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/webinar/${id}`);
                setFormData(response.data.data);
            } catch (error) {
                // console.error('Error fetching webinar:', error);
            }
        };
        fetchWebinar();
    }, [id]);

    const [noUser,setNoUser] = useState(false)
    useEffect(()=>{
      if(!user){
        setNoUser(true)
      }
    },[])
    function toDashBoard () {
      navigate('/login')
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handlePictureChange = (e) => {
        setNewPicture(e.target.files[0]); // Set the new picture file
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Create a FormData object and append all form fields except 'participants'
            const data = new FormData();
            for (const key in formData) {
                if (formData.hasOwnProperty(key) && key !== 'participants') {
                    data.append(key, formData[key]);
                }
            }
            if (newPicture) {
                // Append the new picture file
                data.append('file', newPicture);
            }

            // Update the webinar with new data, including new picture if updated
            await axios.patch(
                `${process.env.REACT_APP_SERVER_URL}/webinar/editWebinar/${id}`,
                data,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            alert('Webinar updated successfully!');
            navigate('/createEvents');
        } catch (error) {
            // console.error('Error updating webinar:', error);
        }
    };

    return (
        <>
        <Navbar/>
        {
        noUser && <>
        <div className="deletemaindiv" style={{background:'white'}}>
                <div className="login-res-cont">
                  <div className="deletetitle">
                    <div className='del-text'>الرجاء تسجيل الدخول</div>

                  </div>
                  <div className='login-res-line'></div>


                  <button onClick={toDashBoard} className='l-r-l-btn'>

                  التسجيل الان
                  </button>
                </div>
              </div>
        </>
      }
        <div className="update-webinar-container">
            <h2>تحديث الندوة عبر الإنترنت</h2>
            <form className="webinar-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="title">عنوان الندوة عبر الإنترنت</label>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        placeholder="أدخل عنوان الندوة عبر الإنترنت"

                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">وصف</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Enter the webinar description"

                    />
                </div>
                <div className="form-group">
                    <label htmlFor="link">رابط التكبير</label>
                    <input
                        type="text"
                        name="link"
                        value={formData.Meeting_link}
                        onChange={handleChange}
                        placeholder="أدخل وصف الندوة عبر الإنترنت"

                    />
                </div>
                <div className="form-group">
                    <label htmlFor="instructions">تعليمات</label>
                    <textarea
                        name="instructions"
                        value={formData.instructions}
                        onChange={handleChange}
                        placeholder="أدخل أي تعليمات"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="time">موعد الندوة عبر الإنترنت</label>
                    <input
                        type="text"
                        name="time"
                        value={formData.time}
                        onChange={handleChange}
                        placeholder="أدخل الوقت (e.g., 3:00 PM)"

                    />
                </div>
                <div className="form-group">
                        <label htmlFor="date">تاريخ</label>
                        <input
                            type="text"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            placeholder="30 october 2024"

                        />
                </div>
                <div className="form-group">
                    <label htmlFor="presenter">مقدم العرض</label>
                    <input
                        type="text"
                        name="presenter"
                        value={formData.presenter}
                        onChange={handleChange}
                        placeholder="أدخل اسم مقدم العرض"

                    />
                </div>
                <div className="form-group">
                    <label htmlFor="price">سعر</label>
                    <input
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        placeholder="أدخل السعر"

                    />
                </div>
                <div className="form-group">
                        <label htmlFor="certificateDescription">وصف الشهادة</label>
                        <textarea
                            name="certificate_descriptions"
                            value={formData.certificate_descriptions}
                            onChange={handleChange}
                            placeholder="أدخل وصف الشهادة"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zoomPasscode">كلمة مرور اجتماع زوم</label>
                        <input
                            type="text"
                            name="ZoomMeetingPasscode"
                            value={formData.ZoomMeetingPasscode}
                            onChange={handleChange}
                            placeholder="أدخل كلمة مرور زوم"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zoomPasscode">معرف اجتماع زووم</label>
                        <input
                            type="text"
                            name="ZoomMeeting_id"
                            value={formData.ZoomMeeting_id}
                            onChange={handleChange}
                            placeholder="أدخل كلمة مرور زوم"
                        />
                    </div>
                {formData.picture && (
                <div>
                    <img
                        src={formData.picture}
                        alt="Webinar"
                        style={{ width: '200px', height: '200px', marginTop: '10px' }}
                    />
                </div>
            )}
                <div className="form-group">
                    <label htmlFor="newPicture">تحديث الصورة</label>
                    <input
                        type="file"
                        name="newPicture"

                        accept="image/*"
                        onChange={handlePictureChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="status">وضع</label>
                    <div className="active-btn-container">
                    <input
                        type="checkbox"
                        name="status"
                        checked={formData.status}
                        onChange={handleChange}
                    />{' '}
                    <p>نشيط</p>
                    </div>
                </div>
                <button type="submit" className="submit-btn">
                تحديث الندوة عبر الإنترنت
                </button>
            </form>
        </div>
        <Footer/>
        </>
    );
}
