import React from 'react'
import './pagination.css'

function Pagination({increment, decrement, activePage, totalPages, currentPage}) {
  return (
    <div className='sa-pagination-container'>
      {
        totalPages===1 || <div className={activePage === 1 ? 'sa-pagination-left-inactive' : 'sa-pagination-left'}
      onClick={decrement} >
        {'<'}
      </div>}
      <div className={activePage === currentPage ? 'sa-pagination-active' : 'sa-pagination-active1' } onClick={decrement}>
        {currentPage}
        </div>
        {
          totalPages===1 || <div className={activePage === (currentPage+1) ? 'sa-pagination-active' : 'sa-pagination-active1' } onClick={increment}>
        {currentPage +1}
        </div>}
      {
        totalPages===1 || <div className={activePage === totalPages ? 'sa-pagination-left-inactive' : 'sa-pagination-left' }
      onClick={increment}>
        {'>'}
        </div>}
    </div>
  )
}

export default Pagination
